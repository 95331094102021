import { useCallback } from 'react';

import { getHighlights, setHighlights } from './highlighting_storage';

export default function usePersistedHighlights(id) {
  const data = getHighlights(id);
  const save = useCallback(
    updatedState => {
      setHighlights(id, updatedState);
    },
    [id]
  );

  return [data, save];
}
