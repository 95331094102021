import React from 'react';
import { FormattedMessage } from 'react-intl';

import loadingGuru from '../../../../../../assets/images/loading-medi.svg';

const LoadingScreen = () => (
  <div className="t-text-align--center t-padding-large--top l-content">
    <FormattedMessage id="runs.show.loading" tagName="h2" />
    <img
      className="l-image-align l-image-align--center"
      src={loadingGuru}
      alt="Medi walking up the hill"
    />
  </div>
);

LoadingScreen.propTypes = {};

export default LoadingScreen;
