import { defaultMessages } from '../../default';

export default {
  vertical: {
    id: 'vertical_list',
    name: defaultMessages.answerModesVerticalList
  },
  horizontal: {
    id: 'horizontal_list',
    name: defaultMessages.answerModesHorizontalList
  },
  table: { id: 'table', name: defaultMessages.answerModesTable }
};
