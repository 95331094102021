import React from 'react';
import { Image } from '@phosphor-icons/react';
import config from '../../../utils/config.erb';
import EditorButton from './editor_button';
import { camelcase } from '../../../utils/keymap';

const ImageButton = () => {
  const { cloudinary } = config;

  const addImage = editor => {
    const handle = (error, result) => {
      if (error) return console.error(error); // eslint-disable-line no-console

      if (!error && result && result.event === 'success') {
        const image = result.info;
        const info = camelcase(image);

        return editor.setImage({ src: image.url, ...info }).run();
      }

      return true;
    };
    window.cloudinary.openUploadWidget(
      {
        cloudName: cloudinary.cloudName,
        uploadPreset: cloudinary.presetName
      },
      handle
    );
  };

  const buttonProps = {
    label: 'Add image',
    icon: <Image className="o-icon o-icon--large" />,
    fn: addImage,
    type: 'image'
  };

  return <EditorButton {...buttonProps} />;
};

export default ImageButton;
