import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { FormattedMessage } from 'react-intl';
import SVG from 'react-inlinesvg';
import PropTypes from 'prop-types';
import internationalize from '../../lib/internationalize';

import playSVG from '../../../assets/images/play.svg';
import closeIcon from '../../../assets/images/close.svg';

class ModalVideoPlayer extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  render() {
    const { youtubeVideoId } = this.props;

    return (
      <>
        <button
          onClick={this.handleOpenModal}
          type="button"
          className="o-button o-button--icon o-button--icon-ghost qa-landing-page-play"
        >
          <SVG
            src={playSVG}
            alt=""
            className="o-icon o-icon--x-large"
            height="100"
            width="100"
            role="presentation"
          />
          <span className="o-break-long-words o-h2 o-button__label">
            <FormattedMessage id="static_pages.landing_page.what_to_expect" />
            <span className="o-visuallyhidden">
              <FormattedMessage id="static_pages.landing_page.play_video" />
            </span>
          </span>
        </button>
        <ReactModal
          isOpen={this.state.showModal}
          overlayClassName="c-modal"
          className="c-modal__content o-overflow qa-landing-page-modal"
        >
          <button
            onClick={this.handleCloseModal}
            type="button"
            className="o-button o-button--icon o-button--icon-ghost c-modal__close"
          >
            <SVG
              src={closeIcon}
              alt=""
              className="o-icon o-icon--large qa-landing-page-play-close"
              height="21"
              width="21"
              role="presentation"
            />
          </button>
          <div className="o-fixed-ratio">
            <iframe
              title="modal-player"
              type="text/html"
              width="640"
              height="360"
              src={`//www.youtube.com/embed/${youtubeVideoId}?autoplay=1`}
              className="o-fixed-ratio__inner t-no-border qa-landing-page-player"
              allow="fullscreen; autoplay"
              allowFullScreen
              sandbox="allow-scripts allow-same-origin allow-presentation"
            />
          </div>
        </ReactModal>
      </>
    );
  }
}

ModalVideoPlayer.propTypes = {
  youtubeVideoId: PropTypes.string.isRequired
};

export default internationalize(ModalVideoPlayer);
