import React, { useCallback, useState } from 'react';
import { func, shape } from 'prop-types';
import SVG from 'react-inlinesvg';
import classnames from 'classnames';

import pencilSVG from '../../../../../assets/images/pencil.svg';
import undoSVG from '../../../../../assets/images/undo.svg';
import ColorButton from './color_button';
import { brushType, colorType } from './prop_types';
import { COLORS, BRUSHES } from './constants';

const BUTTON_COLORS = COLORS.slice().reverse(); // because the highlighter is right-aligned

const OTHER_BRUSH = { fine: BRUSHES.marker, marker: BRUSHES.fine };

function HighlightControls({ canvas, color, setColor, brush, setBrush }) {
  const [collapsed, setCollapsed] = useState(true);
  const handleSetColor = useCallback(
    event => {
      setColor(event.currentTarget.dataset.color);
    },
    [setColor]
  );
  const handleBrushToggle = useCallback(
    event => {
      const nextBrush = OTHER_BRUSH[event.currentTarget.dataset.brush];
      setBrush(nextBrush);
    },
    [setBrush]
  );
  const handleUndo = useCallback(() => canvas && canvas.undo(), [canvas]);
  const toggleCollapsed = useCallback(() => {
    const newValue = !collapsed;
    setCollapsed(newValue);
    if (newValue /* i.e. collapsing */) setColor('');
    else setColor(COLORS[0].code);
  }, [collapsed, setCollapsed, setColor]);

  const classname = classnames(
    'c-runplayer__control-bar l-flex l-flex--reverse l-flex--fit l-flex--center',
    {
      'is-active': !collapsed
    }
  );

  return (
    <div className="l-flex l-flex--end">
      <div className={classname}>
        <button
          type="button"
          className="o-button o-button--icon"
          aria-expanded={!collapsed}
          aria-controls="expander-highlighting"
          onClick={toggleCollapsed}
        >
          <SVG
            src={pencilSVG}
            alt="Highlighter"
            className="o-icon o-icon--medium-large o-button__icon"
            width="16"
            height="16"
            role="presentation"
          />
        </button>
        {!collapsed && (
          <ul
            id="expander-highlighting"
            className="o-list-reset l-flex l-flex--center l-flex--fit l-flex__primary t-margin-x-small--right t-margin-small--right@medium"
            aria-hidden={collapsed}
          >
            <li className="l-flex__primary c-highlight__colors t-margin-small--right@medium">
              <ul className="o-list-reset l-flex">
                {BUTTON_COLORS.map(({ code, label }) => (
                  <li
                    key={label}
                    className="t-margin-x-small--right t-margin-x-small--bottom"
                  >
                    <ColorButton
                      code={code}
                      label={label}
                      selected={code === color}
                      onClick={handleSetColor}
                    />
                  </li>
                ))}
              </ul>
            </li>
            <li className="t-margin-x-small--right l-flex__fixed">
              <button
                type="button"
                className="o-button o-button--icon"
                onClick={handleUndo}
              >
                <SVG
                  src={undoSVG}
                  alt="Rückgängig"
                  className="o-icon o-icon--medium-large o-button__icon"
                  width="27"
                  height="27"
                  role="presentation"
                />
              </button>
            </li>
            <li className="l-flex__fixed">
              <button
                type="button"
                testid="brushToggle"
                className="o-button o-button--icon"
                onClick={handleBrushToggle}
                data-brush={brush.label}
              >
                <SVG
                  src={brush.icon}
                  alt="Toggle brush"
                  className="o-icon o-icon--medium-large o-button__icon"
                  width="27"
                  height="27"
                  role="presentation"
                />
              </button>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
}

HighlightControls.propTypes = {
  color: colorType.isRequired,
  setColor: func.isRequired,
  brush: brushType.isRequired,
  setBrush: func.isRequired,
  canvas: shape({ undo: func.isRequired })
};

HighlightControls.defaultProps = {
  canvas: null
};

export default HighlightControls;
