import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import TestProgressBar from '../../../../test_progress_bar';
import { trailShape, scoringResultsShape } from '../../../shapes/entities';

const QuestionStatisticsCard = props => {
  const { trail, scoringResults, onClick } = props;
  const { achievableScore, userScore } = scoringResults;

  const userScoreSpan = (
    <span style={{ color: trail.attributes.color }}>{userScore}</span>
  );

  const achievableScoreSpan = (
    <span style={{ color: trail.attributes.color }}>{achievableScore}</span>
  );

  return (
    <article className="l-grid__item c-results-card qa-question-statistics-card">
      <div className="l-flex-grid__intro l-flex-grid__intro-ranking">
        <header>
          <h2 className="o-h2 t-text-weight--bold">
            <FormattedMessage id="runs.results.result_header" />
          </h2>
        </header>
        <div className="t-text-align--center">
          <TestProgressBar total={achievableScore} correct={userScore} />
          <div className="t-padding--top">
            <p className="t-line-height--tight t-no-margin">
              <FormattedMessage
                id="runs.results.check_out_solutions"
                values={{
                  user_score: userScoreSpan,
                  achievable_score: achievableScoreSpan
                }}
              />
            </p>
          </div>
        </div>
      </div>
      <div className="l-flex-grid__end t-text-align--center t-padding--top">
        <button
          type="button"
          className="o-button o-button--inverted l-width--16-of-16@small-only"
          onClick={onClick}
        >
          <FormattedMessage id="runs.show.view_solutions" />
        </button>
      </div>
    </article>
  );
};

QuestionStatisticsCard.propTypes = {
  trail: trailShape.isRequired,
  onClick: PropTypes.func.isRequired,
  scoringResults: scoringResultsShape.isRequired
};

export default QuestionStatisticsCard;
