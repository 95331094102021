import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { answerShape, questionShape } from '../../../../shapes/entities';
import { modeType } from '../../../../shapes/types';

import Item from './item';

function headerCell(key, label) {
  return <th key={key}>{label}</th>;
}

const Cell = ({
  answers,
  mode,
  selected,
  question,
  onToggleAnswer,
  rowIndex,
  columnIndex
}) => {
  const {
    table_column_count: tableColumnCount,
    table_column_labels: tableColumnLabels,
    table_row_labels: tableRowLabels,
    table_show_answer_content: tableShowAnswerContent
  } = question.attributes;

  if (rowIndex === 0 && columnIndex === 0) {
    return headerCell(columnIndex, '');
  }
  if (rowIndex === 0) {
    return headerCell(columnIndex, tableColumnLabels[columnIndex - 1]);
  }
  if (columnIndex === 0) {
    return headerCell(columnIndex, tableRowLabels[rowIndex - 1]);
  }

  const answer = answers[(rowIndex - 1) * tableColumnCount + columnIndex - 1];
  const classname = classnames('c-answer-table__answer', {
    'no-content': !tableShowAnswerContent
  });
  return (
    <td>
      <div className={classname}>
        <Item
          mode={mode}
          answer={answer}
          showContent={tableShowAnswerContent}
          isSelected={selected.indexOf(answer.id) >= 0}
          onToggle={onToggleAnswer}
        />
      </div>
    </td>
  );
};

Cell.propTypes = {
  answers: PropTypes.arrayOf(answerShape).isRequired,
  mode: modeType.isRequired, // eslint-disable-line react/no-typos
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  question: questionShape.isRequired,
  onToggleAnswer: PropTypes.func.isRequired,
  rowIndex: PropTypes.number.isRequired,
  columnIndex: PropTypes.number.isRequired
};

export default Cell;
