import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import classnames from 'classnames';

import caret from '../../../../assets/images/caret.svg';

const DropdownButton = ({ text, onClick, isActive }) => {
  const buttonClassname = classnames(
    'o-nav-link o-nav-link--topbar c-dropdown__button o-hidden@small-and-medium',
    { 'is-active': isActive }
  );
  return (
    <button onClick={onClick} type="button" className={buttonClassname}>
      <span className="o-button__label o-button__label--flex">
        <span className="o-truncate-with-ellipsis t-margin-x-small--right">
          {text}
        </span>
        <SVG
          src={caret}
          alt=""
          className="o-icon o-icon--small o-button__icon"
          width="16"
          height="16"
          role="presentation"
        />
      </span>
    </button>
  );
};

DropdownButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired
};

export default DropdownButton;
