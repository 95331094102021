import React from 'react';
import SVG from 'react-inlinesvg';
import classnames from 'classnames';

import courseShape from '../shapes/course';

const CourseLink = ({ course }) => {
  const iconClassname = `o-icon o-nav-link__icon t-margin-x-small--right theme-course--${course.id} t-color--test`;
  const buttonClassname = classnames(
    'qa-course-link o-nav-link o-nav-link--menu o-nav-link--course t-font-family--body t-no-margin c-dropdown__item c-dropdown__item--sparse',
    {
      'is-active': course.active
    }
  );

  return (
    <a
      href={course.path}
      className={buttonClassname}
      title={course.displayName}
      rel="nofollow"
      data-method="post"
    >
      <SVG
        src={course.iconUrl}
        alt=""
        className={iconClassname}
        width="16"
        height="16"
        role="presentation"
      />
      <span className="o-nav-link__label o-truncate-with-ellipsis">
        {course.displayName}
      </span>
    </a>
  );
};

CourseLink.propTypes = {
  course: courseShape.isRequired
};

export default CourseLink;
