import React, { useEffect, useRef, useState } from 'react';
import { node, string, bool } from 'prop-types';

import usePersistedHighlights from './use_persisted_highlights';
import HighlightCanvas from './canvas';
import HighlightControls from './controls';
import { BRUSHES } from './constants';

function HighlightContainer({ id, children, showControls }) {
  const [color, setColor] = useState('');
  const [brush, setBrush] = useState(BRUSHES.fine);
  const [savedData, save] = usePersistedHighlights(id);
  const canvas = useRef();

  useEffect(() => {
    const canvasData = canvas.current;
    return () => {
      if (canvasData) {
        save(canvasData.getSaveData());
      }
    };
  }, [save, canvas, savedData]);

  if (!showControls) {
    return children;
  }

  return (
    <>
      <div className="t-margin--bottom c-runplayer__control-bar-wrapper">
        <HighlightControls
          color={color}
          setColor={setColor}
          brush={brush}
          setBrush={setBrush}
          canvas={canvas.current}
        />
      </div>
      <div className="t-position--relative c-runplayer__content">
        <HighlightCanvas
          color={color}
          brush={brush}
          ref={canvas}
          immediateLoading
          saveData={savedData}
        />
        {children}
      </div>
    </>
  );
}
HighlightContainer.propTypes = {
  id: string.isRequired,
  children: node.isRequired,
  showControls: bool
};

HighlightContainer.defaultProps = {
  showControls: true
};

export default HighlightContainer;
