export const defaultImageSize = '7-16';

export const imageSizes = [
  'M',
  'S',
  '1:1',
  '1-4',
  '2-4',
  '3-4',
  '4-4',
  defaultImageSize
];

export const wrapperSizeClasses = {
  '1-4': 'l-width--4-of-16@medium',
  '2-4': 'l-width--8-of-16@medium',
  '3-4': 'l-width--12-of-16@medium',
  '4-4': 'l-width--16-of-16@medium',
  '7-16': 'l-width--7-of-16@medium',
  '1:1': 'l-width--16-of-16@medium',
  M: 'l-image-square l-image-square--medium',
  S: 'l-image-square l-image-square--small'
};

export const imageAlignments = ['default', 'left', 'center', 'right'];

export const wrapperAlignmentClasses = {
  default: 'l-image-align',
  left: 'l-image-align l-image-align--left',
  center: 'l-image-align l-image-align--center',
  right: 'l-image-align l-image-align--right'
};

export const imageStyles = {
  '1:1': { maxWidth: '100%' },
  '1-4': { width: '100%' },
  '2-4': { width: '100%' },
  '3-4': { width: '100%' },
  '4-4': { width: '100%' },
  '7-16': { width: '100%' },
  center: { margin: 'auto' },
  left: { marginRight: 'auto' },
  right: { marginLeft: 'auto' }
};

export const toolsAlignmentClasses = {
  center: 'c-admin-imagetools--center',
  left: 'c-admin-imagetools--left',
  right: 'c-admin-imagetools--right'
};
