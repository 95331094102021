import { Node, mergeAttributes } from '@tiptap/core';
import {
  getSrc,
  getVimeoSrc,
  getYoutubeSrc,
  isVimeo,
  isYoutube
} from './video_utils';

const Video = Node.create({
  name: 'video',
  group: 'block',
  selectable: true,
  draggable: true,
  atom: true,

  addOptions() {
    return {
      height: 480,
      width: 640,
      controls: true,
      allowfullscreen: true,
      frameBorder: 0
    };
  },

  addAttributes() {
    return {
      src: {
        default: null
      }
    };
  },

  addCommands() {
    return {
      setVideo: options => ({ commands }) => {
        return commands.insertContent({
          type: this.name,
          attrs: options
        });
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-video] iframe'
      }
    ];
  },

  renderHTML({ HTMLAttributes, node }) {
    const { src } = node.attrs;
    let videoUrl;

    if (isYoutube(src)) {
      videoUrl = getSrc(getYoutubeSrc(src));
    } else if (isVimeo(src)) {
      videoUrl = getSrc(getVimeoSrc(src));
    } else {
      videoUrl = src;
    }

    return [
      'div',
      { class: 'o-fixed-ratio' },
      [
        'iframe',
        mergeAttributes(HTMLAttributes, {
          class: 'o-fixed-ratio__inner',
          width: this.options.width,
          height: this.options.height,
          allowfullscreen: this.options.allowfullscreen,
          frameBorder: this.options.frameBorder,
          'data-testid': 'embedded-video',
          src: videoUrl
        })
      ]
    ];
  }
});

export default Video;
