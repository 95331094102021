import React from 'react';
import find from 'lodash.find';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isRequestLoading } from 'redux-bees';

import LoadingScreen from '../../../components/screens/loading';
import HighscoresScreen from '../../../components/screens/highscores';
import {
  runShape,
  raceShape,
  raceHighscoresShape,
  screenShape
} from '../../../shapes/entities';

import { open } from '../../../actions/player';
import { makeGetHighscoresScreenProps } from '../../../selectors/player';
import * as screenTypes from '../../../screens';

import api from '../../../api';

export class HighscoresScreenWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.load = this.load.bind(this);
    this.exit = this.exit.bind(this);
    this.backToResults = this.backToResults.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  load() {
    const { race, getRaceHighscores } = this.props;

    getRaceHighscores({ id: race.id, limit: 100 });
  }

  exit() {
    const { run } = this.props;
    window.location.assign(`/runs/${run.id}/return`);
  }

  backToResults() {
    const { open: navigate, run, screens } = this.props;
    const resultsScreen = find(screens, {
      attributes: { subtype: screenTypes.results }
    });

    if (resultsScreen) {
      navigate(run, resultsScreen);
    }
  }

  render() {
    const { run, isLoading, raceHighscores } = this.props;

    if (isLoading) return <LoadingScreen />;

    return (
      <HighscoresScreen
        run={run}
        raceHighscores={raceHighscores}
        onBackToResults={this.backToResults}
      />
    );
  }
}

HighscoresScreenWrapper.defaultProps = {
  raceHighscores: null
};

HighscoresScreenWrapper.propTypes = {
  getRaceHighscores: PropTypes.func.isRequired,
  open: PropTypes.func.isRequired,
  run: runShape.isRequired,
  race: raceShape.isRequired,
  screens: PropTypes.arrayOf(screenShape).isRequired,
  isLoading: PropTypes.bool.isRequired,
  raceHighscores: raceHighscoresShape
};

const mapStateToProps = (state, { run, screens }) => {
  const { race, raceHighscores } = makeGetHighscoresScreenProps(run.id)(state);

  const isLoading = isRequestLoading(state, api.getRaceHighscores, {
    id: race.id
  });

  return {
    run,
    race,
    isLoading,
    raceHighscores,
    screens
  };
};

const mapDispatchToProps = {
  getRaceHighscores: api.getRaceHighscores,
  open
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HighscoresScreenWrapper);
