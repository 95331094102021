import React from 'react';
import PropTypes from 'prop-types';
import { getEntity, getRelationship } from 'redux-bees';

import { editing as mode } from '../../application/run_player/modes';
import { questionShape } from '../../application/run_player/shapes/entities';
import stateify from '../../shared/utils/stateify';
import internationalize from '../../lib/internationalize';

import QuestionScreen from '../../application/run_player/components/screens/question';

const noop = () => {};
export const QuestionPreview = ({ resource }) => {
  const state = stateify(resource);
  const { type, id } = resource.data;
  const source = getEntity(state, { type, id });
  const answers = getRelationship(state, source, 'answers');
  return (
    <QuestionScreen
      mode={mode}
      source={source}
      answers={answers}
      selected={[]}
      onToggleAnswer={noop}
      highlightsId={`${type}-${id}`}
    />
  );
};

QuestionPreview.propTypes = {
  resource: PropTypes.shape({
    data: questionShape.isRequired
  }).isRequired
};

export default internationalize(QuestionPreview);
