import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import enhanceWithClickOutside from 'react-click-outside';

import userShape from '../shapes/user';
import pathsShape from '../shapes/paths';

import DropdownButton from './dropdown_button';
import AccountLinks from './account_links';

export class AccountMenu extends Component {
  constructor(props) {
    super(props);

    this.state = { isActive: false };
    this.toggle = this.toggle.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside() {
    this.setState({ isActive: false });
  }

  toggle() {
    this.setState(({ isActive }) => ({ isActive: !isActive }));
  }

  render() {
    const { user, paths, showPerformance } = this.props;
    const { isActive } = this.state;
    const className = classNames('c-dropdown__panel c-nav__section', {
      'c-dropdown--active': isActive
    });

    return (
      <div className="c-dropdown c-dropdown--user qa-user-menu">
        <DropdownButton
          text={user.name}
          onClick={this.toggle}
          isActive={isActive}
        />
        <div className={className}>
          <div className="c-dropdown__inner">
            <h3 className="o-visuallyhidden">{user.name}</h3>
            <AccountLinks paths={paths} showPerformance={showPerformance} />
          </div>
        </div>
      </div>
    );
  }
}

AccountMenu.propTypes = {
  user: userShape.isRequired, // eslint-disable-line react/no-typos
  paths: pathsShape.isRequired, // eslint-disable-line react/no-typos
  showPerformance: PropTypes.bool.isRequired
};

export default enhanceWithClickOutside(AccountMenu);
