/* global ReactOnRails:false */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import get from 'lodash.get';
import find from 'lodash.find';

import {
  runShape,
  screenShape,
  sourceShape,
  chapterShape,
  exerciseShape
} from '../../../shapes/entities';
import { modeType } from '../../../shapes/types';

import ChapterProgressBar from './chapter_progress_bar';
import ConnectedFocusTestScreen from '../focus_test';
import ConnectedOfflineFocusTestScreen from '../offline_focus_test';
import ConnectedInfoPageScreen from '../info_page';
import ConnectedQuestionBlockScreen from '../question_block';
import ConnectedQuestionScreen from '../question';

import Countdown from '../../../components/countdown';
import Controls from '../../../components/controls';
import ControlItem from '../../../components/control_item';
import ConnectedNextButton from '../../../components/next_button';
import UserFeedback from '../../../components/user_feedback';

import { getExerciseScreenProps } from '../../../selectors/player';

import {
  open,
  expireChapter,
  endChapter,
  instantAnswer
} from '../../../actions/player';
import {
  chapterExpiresAt,
  chapterAccessibleAt,
  setChapterExpiryTimeout,
  setChapterAccessTimeout
} from '../../../utils/chapter_timer';

import * as modes from '../../../modes';
import * as screenTypes from '../../../screens';

const map = {
  info_pages: ConnectedInfoPageScreen,
  focus_test_settings: ConnectedFocusTestScreen,
  offline_focus_test_sections: ConnectedOfflineFocusTestScreen,
  question_blocks: ConnectedQuestionBlockScreen,
  questions: ConnectedQuestionScreen
};

const screenChapterId = screen =>
  get(screen, ['relationships', 'chapter', 'data', 'id']);

const nextScreenHasBarrier = ({
  mode,
  chapter,
  nextScreenChapter,
  nextScreenChapterEntryBarrier
}) =>
  nextScreenChapter &&
  mode === modes.playing &&
  chapter.id !== nextScreenChapter.id &&
  chapterAccessibleAt(nextScreenChapter, nextScreenChapterEntryBarrier) >=
    Date.now();

export class ExerciseScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nextEnabled: !nextScreenHasBarrier(props),
      nextBlocked: false,
      onScreenLeaveCallbacks: []
    };

    this.openScreen = this.openScreen.bind(this);
    this.setChapterExpiryTimer = this.setChapterExpiryTimer.bind(this);
    this.setChapterAccessTimer = this.setChapterAccessTimer.bind(this);
    this.timeoutChapter = this.timeoutChapter.bind(this);
    this.openNextScreen = this.openNextScreen.bind(this);
    this.onScreenLeave = this.onScreenLeave.bind(this);
    this.executeOnScreenLeaveCallbacks = this.executeOnScreenLeaveCallbacks.bind(
      this
    );
    this.submitAnswerForInstantSolution = this.submitAnswerForInstantSolution.bind(
      this
    );
    this.openSolutionsGrid = this.openSolutionsGrid.bind(this);
    this.openPreviousScreen = this.openPreviousScreen.bind(this);
    this.openExerciseScreen = this.openExerciseScreen.bind(this);
    this.submitUserFeedback = this.submitUserFeedback.bind(this);
    this.toggleBlockNext = this.toggleBlockNext.bind(this);
  }

  componentDidMount() {
    const { mode } = this.props;
    if (mode !== modes.playing) return;
    this.setChapterExpiryTimer();
    this.setChapterAccessTimer();
  }

  componentWillUnmount() {
    if (this.expireTimer) clearTimeout(this.expireTimer);
    if (this.accessTimer) clearTimeout(this.accessTimer);
  }

  onScreenLeave(callback) {
    const { onScreenLeaveCallbacks } = this.state;
    this.setState({
      onScreenLeaveCallbacks: onScreenLeaveCallbacks.concat([callback])
    });
  }

  setChapterExpiryTimer() {
    const { chapter } = this.props;
    this.expireTimer = setChapterExpiryTimeout(chapter, this.timeoutChapter);
  }

  setChapterAccessTimer() {
    const { nextEnabled } = this.state;
    if (nextEnabled) return;
    const { nextScreenChapter, nextScreenChapterEntryBarrier } = this.props;
    this.accessTimer = setChapterAccessTimeout(
      nextScreenChapter,
      nextScreenChapterEntryBarrier,
      () => this.setState({ nextEnabled: true })
    );
  }

  openScreen(screen) {
    const { run, open: navigate } = this.props;
    if (screen) navigate(run, screen);
  }

  timeoutChapter() {
    const { run, chapter, expireChapter: timeout } = this.props;
    if (chapter) timeout(run, chapter);
  }

  openScreenAt(index) {
    const { screens } = this.props;
    const screen = screens[index];
    this.openScreen(screen);
  }

  toggleBlockNext() {
    const { nextBlocked } = this.state;
    this.setState({ nextBlocked: !nextBlocked });
  }

  executeOnScreenLeaveCallbacks() {
    const { onScreenLeaveCallbacks } = this.state;
    onScreenLeaveCallbacks.forEach(callback => callback());
  }

  openNextScreen() {
    const {
      run,
      index,
      chapter,
      nextScreenChapter,
      endChapter: move
    } = this.props;

    // some exercise screens (e.g. the focus_test) need to get a chance
    // to finish computation (sending events to the backend) _before_
    // we navigate away and schedule our navigation-events.
    this.executeOnScreenLeaveCallbacks();

    if (nextScreenChapter && chapter.id !== nextScreenChapter.id) {
      move(run, chapter);
    }
    this.openScreenAt(index + 1);
  }

  submitAnswerForInstantSolution() {
    const { instantAnswer: submitInstantAnswer, run, exercise } = this.props;
    submitInstantAnswer(run, exercise);
  }

  openPreviousScreen() {
    const { index } = this.props;
    this.openScreenAt(index - 1);
  }

  openSolutionsGrid() {
    const { screens } = this.props;
    const target = find(screens, {
      attributes: { subtype: screenTypes.solutions }
    });
    if (target) this.openScreen(target);
  }

  openExerciseScreen(exercise) {
    const { screens } = this.props;
    const { id, type } = exercise;
    const matcher = { relationships: { exercise: { data: { id, type } } } };
    const target = find(screens, matcher);
    this.openScreen(target);
  }

  submitUserFeedback({ message }) {
    const method = 'POST';
    const url = '/api/v1/user_feedbacks';
    const headers = {
      ...ReactOnRails.authenticityHeaders(),
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };
    const { exercise } = this.props;
    const body = JSON.stringify({
      user_feedback: {
        message,
        exercise_id: exercise.id
      }
    });

    const options = { method, headers, body, credentials: 'same-origin' };

    return fetch(url, options).then(response => {
      if (response.ok) return response;
      const error = new Error('UserFeedback Error');
      error.response = response;
      throw error;
    });
  }

  render() {
    const {
      run,
      mode,
      screens,
      index,
      exercises,
      exercise,
      source,
      chapter
    } = this.props;

    const { instant_solution: instantSolutionActive } = run.attributes;
    const {
      instant_answer_submitted_at: exerciseInstantAnswered
    } = exercise.attributes;

    const { nextEnabled, nextBlocked } = this.state;

    const ExerciseScreenContainer = map[source.type];
    const chapterExpiryDate = chapterExpiresAt(chapter);

    const showNext = index < screens.length - 1;
    const showPrev =
      index > 0 &&
      (run.attributes.type === 'LessonTrail' ||
        mode === modes.review ||
        screenChapterId(screens[index - 1]) ===
          screenChapterId(screens[index]));
    const showSolutions = mode === modes.review;
    const instantAnswerCheckPossible =
      mode === modes.playing &&
      instantSolutionActive &&
      source.type === 'questions' &&
      !exerciseInstantAnswered;

    const nextButton = showNext && (
      <ConnectedNextButton
        onClick={this.openNextScreen}
        disabled={!nextEnabled || nextBlocked}
        run={run}
        nextScreen={screens[index + 1]}
        chapter={chapter}
      />
    );
    const instantSolutionButton = (
      <button
        type="button"
        className="o-button o-button--inverted l-width--16-of-16@small-only t-margin--bottom@small-only"
        onClick={this.submitAnswerForInstantSolution}
        disabled={nextBlocked}
      >
        <FormattedMessage id="runs.show.check_solution" />
      </button>
    );

    return (
      <article>
        <div className="l-flex l-flex--bottom t-margin-small--bottom t-padding--ends o-h4 t-font-family--body">
          <div className="l-flex__primary">
            <ChapterProgressBar
              exercise={exercise}
              exercises={exercises}
              mode={mode}
              jumpTo={this.openExerciseScreen}
              instantSolutionActive={instantSolutionActive}
            />
          </div>

          {mode === modes.playing && chapterExpiryDate && (
            <span className="t-padding-small--left t-padding--left@medium t-line-height--ui t-color--text-secondary qa-chapter-countdown">
              <Countdown date={chapterExpiryDate} />
            </span>
          )}
        </div>

        <ExerciseScreenContainer
          run={run}
          mode={mode}
          screens={screens}
          index={index}
          exercise={exercise}
          source={source}
          toggleBlockNext={this.toggleBlockNext}
          showHighlighter={chapter.attributes.show_highlighter}
          onScreenLeave={this.onScreenLeave}
        />

        {(run.attributes.always_show_feedback || mode === modes.review) && (
          <UserFeedback onSubmit={this.submitUserFeedback} />
        )}

        <Controls>
          <ControlItem order={2}>
            {showPrev && (
              <button
                type="button"
                className="o-button o-button--test-color t-margin--bottom@small-only l-width--16-of-16@small-only "
                onClick={this.openPreviousScreen}
              >
                <FormattedMessage id="runs.show.prev" />
              </button>
            )}
          </ControlItem>

          {showSolutions && (
            <ControlItem order={3}>
              <button
                type="button"
                className="o-button o-button--inverted l-width--16-of-16@small-only t-margin--right t-margin--bottom@small-only"
                onClick={this.openSolutionsGrid}
              >
                <FormattedMessage id="runs.show.open_solutions_grid" />
              </button>
            </ControlItem>
          )}
          <ControlItem order={1}>
            {instantAnswerCheckPossible ? instantSolutionButton : nextButton}
          </ControlItem>
        </Controls>
      </article>
    );
  }
}

ExerciseScreen.propTypes = {
  run: runShape.isRequired,
  mode: modeType.isRequired,
  screens: PropTypes.arrayOf(screenShape).isRequired,
  index: PropTypes.number.isRequired,
  chapter: chapterShape.isRequired,
  exercises: PropTypes.arrayOf(exerciseShape).isRequired,
  exercise: exerciseShape.isRequired,
  source: sourceShape.isRequired,
  nextScreenChapter: chapterShape,
  nextScreenChapterEntryBarrier: chapterShape,
  open: PropTypes.func.isRequired,
  instantAnswer: PropTypes.func.isRequired,
  expireChapter: PropTypes.func.isRequired,
  endChapter: PropTypes.func.isRequired
};

ExerciseScreen.defaultProps = {
  nextScreenChapter: null,
  nextScreenChapterEntryBarrier: null
};

const mapStateToProps = getExerciseScreenProps;

const mapDispatchToProps = {
  open,
  instantAnswer,
  expireChapter,
  endChapter
};

export default connect(mapStateToProps, mapDispatchToProps)(ExerciseScreen);
