import React from 'react';
import PropTypes from 'prop-types';

import Glyph from './glyph';

import { alphabetType } from '../../../../shapes/types';

const Row = ({
  disabled,
  highlighted,
  large,
  index,
  glyphs,
  isSelected,
  alphabet,
  check,
  onClick
}) => {
  /* eslint-disable react/no-array-index-key */
  return (
    <ul className="o-list-reset c-focus-test__row">
      {glyphs.map((glyph, x) => (
        <li key={x} className="l-flex">
          <Glyph
            glyph={glyph}
            alphabet={alphabet}
            disabled={disabled}
            highlighted={highlighted}
            large={large}
            isCorrect={check(index, x)}
            isSelected={isSelected(index, x)}
            onClick={() => onClick(index, x)}
          />
        </li>
      ))}
    </ul>
  );
  /* eslint-enable react/no-array-index-key */
};

Row.propTypes = {
  index: PropTypes.number.isRequired,
  glyphs: PropTypes.arrayOf(PropTypes.object).isRequired,
  alphabet: alphabetType.isRequired,
  isSelected: PropTypes.func.isRequired,
  highlighted: PropTypes.bool.isRequired,
  large: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  check: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
};

Row.defaultProps = {
  large: true
};

export default Row;
