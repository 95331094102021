import React from 'react';
import PropTypes from 'prop-types';

import { exerciseShape } from '../../../shapes/entities';
import { modeType } from '../../../shapes/types';

import ChapterProgressBarItem from './chapter_progress_bar_item';

const ChapterProgressBar = ({
  exercise,
  exercises,
  mode,
  jumpTo,
  instantSolutionActive
}) => (
  <div>
    <ul
      className="o-list-reset c-progress-list t-background-color--subtest"
      aria-labelledby="progress-list-label"
    >
      {exercises.map(e => (
        <li key={e.id} className="c-progress-list__item">
          <ChapterProgressBarItem
            mode={mode}
            onClick={jumpTo}
            exercise={e}
            isActive={e === exercise}
            instantSolutionActive={instantSolutionActive}
          />
        </li>
      ))}
    </ul>
  </div>
);

ChapterProgressBar.propTypes = {
  mode: modeType.isRequired, // eslint-disable-line react/no-typos
  jumpTo: PropTypes.func.isRequired,
  exercise: exerciseShape.isRequired,
  exercises: PropTypes.arrayOf(exerciseShape).isRequired,
  instantSolutionActive: PropTypes.bool.isRequired
};

export default ChapterProgressBar;
