import React, { useContext } from 'react';
import { Video } from '@phosphor-icons/react';
import EditorButton from './editor_button';
import TipTapEditorContext from '../tiptap_editor_context';
import {
  getSrc,
  getVimeoSrc,
  getYoutubeSrc,
  isVimeo,
  isYoutube
} from './video_utils';

const VideoButton = () => {
  const { editor } = useContext(TipTapEditorContext);

  const addVideo = () => {
    const src = prompt('Paste the video URL'); // eslint-disable-line no-alert

    const regex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-_.]+\.[a-zA-Z]{2,}(\/\S*)?$/;
    if (!regex.test(src)) {
      editor.commands.deleteSelection();
      return alert('Invalid URL'); // eslint-disable-line no-alert
    }
    let videoUrl;
    if (isYoutube(src)) {
      videoUrl = getSrc(getYoutubeSrc(src));
    } else if (isVimeo(src)) {
      videoUrl = getSrc(getVimeoSrc(src));
    } else {
      videoUrl = src;
    }
    editor.commands.setVideo({ src: videoUrl });
    return null;
  };

  const buttonProps = {
    label: 'Add video',
    icon: <Video className="o-icon o-icon--large" />,
    fn: addVideo,
    type: 'video'
  };

  return <EditorButton {...buttonProps} />;
};

export default VideoButton;
