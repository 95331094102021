const milliseconds = seconds => seconds * 1000;

export const chapterExpiresAt = chapter => {
  const {
    first_visited_at: firstVisitedAt,
    max_run_time_seconds: maxRunTimeSeconds
  } = chapter.attributes;
  const startDate = firstVisitedAt ? Date.parse(firstVisitedAt) : Date.now();
  if (!maxRunTimeSeconds) {
    return null;
  }
  return startDate + maxRunTimeSeconds * 1000;
};

export const chapterExpired = chapter => {
  const {
    max_run_time_seconds: maxRunTimeSeconds,
    max_run_time_expired: maxRunTimeExpired
  } = chapter.attributes;
  if (!maxRunTimeSeconds || !maxRunTimeExpired) {
    return false;
  }
  return maxRunTimeExpired;
};

export const chapterAccessibleAt = (chapter, entryBarrier) => {
  if (!entryBarrier) {
    return null;
  }
  const { entry_barrier_seconds: entryBarrierSeconds } = chapter.attributes;
  const {
    first_visited_at: firstVisitedEntryBarrierAt
  } = entryBarrier.attributes;
  const startDate = firstVisitedEntryBarrierAt
    ? Date.parse(firstVisitedEntryBarrierAt)
    : Date.now();
  return startDate + milliseconds(entryBarrierSeconds);
};

const setChapterTimeout = (endsAt, callback) => {
  if (!endsAt) {
    return null;
  }
  const timeRemaining = Math.max(0, endsAt - Date.now());
  return setTimeout(callback, timeRemaining);
};

export const setChapterExpiryTimeout = (chapter, callback) => {
  const endsAt = chapterExpiresAt(chapter);
  return setChapterTimeout(endsAt, callback);
};

export const setChapterAccessTimeout = (chapter, chapters, callback) => {
  const endsAt = chapterAccessibleAt(chapter, chapters);
  return setChapterTimeout(endsAt, callback);
};
