const rand = max => Math.floor(max * Math.random());
const times = (count, fn) => new Array(count).fill(true).forEach(fn);
const glyphAt = (glyphs, row, index) => glyphs[row[index]] || null;
const updateRow = (row, rowIndex, value) => {
  // eslint-disable-next-line no-param-reassign
  row[rowIndex] = value;
};

export const getBeforeGlyph = (glyphs, row, index) =>
  index - 1 < 0 ? undefined : glyphAt(glyphs, row, index - 1);

export const getAfterGlyph = (glyphs, row, index) =>
  index + 1 >= row.length ? undefined : glyphAt(glyphs, row, index + 1);

/**
 * Returns an array of (true, false), reflecting correct/incorrect glyphs
 * in a row.
 * @param {*} alphabet An alphabet object
 * @param {*} row An array of null/glyph indexes
 */
export const rowCorrectnessMap = (alphabet, row) => {
  const { check, glyphs } = alphabet;

  return row.map((_, x) => {
    const prev = glyphs[row[x - 1]];
    const curr = glyphs[row[x]];
    const next = glyphs[row[x + 1]];
    return !!check(prev, curr, next);
  });
};

/**
 * Tries to place a correct glyph in a section of `row`.
 * The section goes from the `startIndex` until the left/right edge,
 * depending on the `direction`.
 * @param {*} alphabet An alphabet object
 * @param {*} row An array of null/glyph indexes
 * @param {*} startIndex Integer
 * @param {*} direction Either 1 or -1
 */
export const placeCorrectGlyph = (alphabet, row, startIndex, direction) => {
  const { correct, glyphs } = alphabet;
  let rowIndex = startIndex;

  while (rowIndex >= 0 && rowIndex < row.length) {
    const before = getBeforeGlyph(glyphs, row, rowIndex);
    const curr = glyphAt(glyphs, row, rowIndex);
    const after = getAfterGlyph(glyphs, row, rowIndex);
    const result = correct(before, curr, after);

    if (result) {
      const [newBefore, newCurr, newAfter] = result;
      if (rowIndex - 1 >= 0 && newBefore !== null) {
        updateRow(row, rowIndex - 1, newBefore);
      }
      if (newCurr !== null) {
        updateRow(row, rowIndex, newCurr);
      }
      if (rowIndex + 1 < row.length && newAfter !== null) {
        updateRow(row, rowIndex + 1, newAfter);
      }
      return result;
    }

    rowIndex += direction;
  }
  return null;
};

/**
 * Attempts to fill `row` with N correct glyphs, N being the `targetCount`.
 * @param {*} alphabet An alphabet object
 * @param {*} row An array of nulls
 * @param {*} targetCount A number of correct glyphs to attempt
 */
export const fillWithCorrectGlyphs = (alphabet, row, targetCount) => {
  times(targetCount, () => {
    const startPosition = rand(row.length - 1);
    let direction = Math.random() >= 0.5 ? -1 : 1;
    const placed = placeCorrectGlyph(alphabet, row, startPosition, direction);

    if (!placed) {
      direction *= -1;
      placeCorrectGlyph(alphabet, row, startPosition, direction);
    }
  });
};

/**
 * Tries to find glyphs to fill in the rest of the row's nulls.
 * The new glyph must *not* change the correctness of its neighbours.
 * Default to an arbitrary glyph (glyphOffset) if the above clause is not possible.
 * @param {*} alphabet An alphabet object
 * @param {*} row An array of null/glyph indexes
 * @param {*} rowIndex The row index
 * @param {*} glyphOffset A random glyph index to start with
 */
export const findIncorrectGlyph = (alphabet, row, rowIndex, glyphOffset) => {
  const { glyphs, check } = alphabet;
  const before2 = getBeforeGlyph(glyphs, row, rowIndex - 1);
  const before1 = getBeforeGlyph(glyphs, row, rowIndex);
  const curr = glyphAt(glyphs, row, rowIndex); // In theory this value is null.
  if (curr !== null) return null;

  const after1 = getAfterGlyph(glyphs, row, rowIndex);
  const after2 = getAfterGlyph(glyphs, row, rowIndex + 1);

  const beforeWasCorrect = !!check(before2, before1, curr);
  const afterWasCorrect = !!check(curr, after1, after2);

  for (let attempts = 0; attempts < glyphs.length; attempts += 1) {
    const glyphIndex = (glyphOffset + attempts) % glyphs.length;
    const newCurr = glyphs[glyphIndex];

    const beforeIsCorrect = !!check(before2, before1, newCurr);
    const currIsCorrect = !!check(before1, newCurr, after1);
    const afterIsCorrect = !!check(newCurr, after1, after2);
    if (
      !currIsCorrect &&
      beforeWasCorrect === beforeIsCorrect &&
      afterWasCorrect === afterIsCorrect
    ) {
      return glyphIndex;
    }
  }
  return glyphOffset;
};

/**
 * Fills in the row's nulls with incorrect glyphs.
 * @param {*} alphabet An alphabet object
 * @param {*} row An array of null/glyph indexes
 */
export const fillWithIncorrectGlyphs = (alphabet, row) => {
  const { glyphs } = alphabet;

  for (let rowIndex = 0; rowIndex < row.length; rowIndex += 1) {
    if (!glyphAt(glyphs, row, rowIndex)) {
      // start glyph search from a random index to make generated fields less predictable
      const glyphOffset = rand(glyphs.length - 1);
      const glyphIndex = findIncorrectGlyph(
        alphabet,
        row,
        rowIndex,
        glyphOffset
      );
      updateRow(row, rowIndex, glyphIndex);
    }
  }
};
