import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { exerciseShape, sourceShape } from '../../../shapes/entities';

import QuestionBlockScreen from '../../../components/screens/question_block';

export const QuestionBlockScreenWrapper = ({
  exercise,
  source,
  showHighlighter
}) => (
  <QuestionBlockScreen
    highlightsId={exercise.id}
    source={source}
    showHighlighter={showHighlighter}
  />
);

QuestionBlockScreenWrapper.propTypes = {
  source: sourceShape.isRequired,
  exercise: exerciseShape.isRequired,
  showHighlighter: PropTypes.bool.isRequired
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(QuestionBlockScreenWrapper);
