import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import playerName from '../../utils/player_name';
import { runShape, highscoreEntryShape } from '../../shapes/entities';

const HighscoresTable = ({ run, highscores, limit, withDuration }) => (
  <section className="qa-highscores-table">
    <table className="highscores t-margin-small--bottom">
      <thead>
        <tr>
          <th>
            <FormattedMessage id="runs.results.race_highscores.position" />
          </th>
          <th>
            <FormattedMessage id="runs.results.race_highscores.user" />
          </th>
          <th>
            <FormattedMessage id="runs.results.race_highscores.score" />
          </th>
          {withDuration && (
            <th className="qa-duration-col">
              <FormattedMessage id="runs.results.race_highscores.duration" />
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {highscores.slice(0, limit || highscores.length).map((entry, idx) => (
          <tr
            key={entry.runId}
            className={classnames('qa-highscores-row', {
              'qa-highscores-row--own t-font-weight--bold t-color--highlight':
                run.id === entry.runId
            })}
          >
            <td>{idx + 1}</td>
            <td>{playerName(entry.runId)}</td>
            <td>{entry.userScore}</td>
            {withDuration && <td>{entry.duration.toFixed(1)}s</td>}
          </tr>
        ))}
      </tbody>
    </table>
  </section>
);

HighscoresTable.propTypes = {
  run: runShape.isRequired,
  highscores: PropTypes.arrayOf(highscoreEntryShape).isRequired,
  limit: PropTypes.number,
  withDuration: PropTypes.bool.isRequired
};

HighscoresTable.defaultProps = {
  limit: null
};

export default HighscoresTable;
