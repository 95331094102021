import React from 'react';
import PropTypes from 'prop-types';
import InlineControls from './inline_controls';
import BlockControls from './block_controls';
import { PrintOutButton } from './print_out_button';
import { InlineLink } from './inline_link';
import ImageButton from './image_button';
import TextAlignmentControls from './text_alignment_controls';
import { TableControls } from './table';
import VideoButton from './video_button';

const Toolbar = ({ id }) => {
  return (
    <div className="c-admin-toolbar">
      <TableControls id={id} />
      <div className="c-admin-toolbar__media l-flex t-gap-xs">
        <PrintOutButton />
        <ImageButton />
        <VideoButton />
      </div>
      <div className="c-admin-toolbar__text l-flex t-gap-xs c-admin-toolbar__divider">
        <BlockControls id={id} />
        <InlineControls />
        <TextAlignmentControls />
        <InlineLink />
      </div>
    </div>
  );
};

Toolbar.propTypes = {
  id: PropTypes.string
};

Toolbar.defaultProps = {
  id: null
};

export default Toolbar;
