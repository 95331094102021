import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isRequestLoading } from 'redux-bees';
import find from 'lodash.find';

import LoadingScreen from '../../../components/screens/loading';
import ResultsScreen from '../../../components/screens/results';
import {
  raceHighscoresShape,
  runShape,
  runStatisticShape,
  trailShape,
  trailStatisticShape,
  raceShape,
  subTestShape,
  screenShape,
  spiderGraphShape,
  scoringResultsShape
} from '../../../shapes/entities';

import { open } from '../../../actions/player';
import { makeGetResultsScreenProps } from '../../../selectors/player';
import * as screenTypes from '../../../screens';

import api from '../../../api';

const HIGHSCORES_LIMIT = 5;

export class ResultsScreenWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.load = this.load.bind(this);
    this.exit = this.exit.bind(this);
    this.openSolutions = this.openSolutions.bind(this);
    this.openHighscores = this.openHighscores.bind(this);
  }

  componentDidMount() {
    const { run, statsLoaded, pendingEvents } = this.props;
    const { skip_scoring_screen: skipScoringScreen } = run.attributes;
    if (skipScoringScreen) {
      if (pendingEvents === 0) this.exit();
    } else if (!statsLoaded && pendingEvents === 0) {
      this.load();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { run, statsLoaded, pendingEvents } = this.props;
    const { skip_scoring_screen: skipScoringScreen } = run.attributes;
    if (!skipScoringScreen && statsLoaded) return;

    const justFinishedSendingEvents =
      nextProps.pendingEvents === 0 && pendingEvents > 0;

    if (justFinishedSendingEvents) {
      if (skipScoringScreen) {
        this.exit();
      } else {
        this.load();
      }
    }
  }

  load() {
    const {
      getRunSubTestStatistics,
      getMedicalTestStatistics,
      getRunStatistic,
      getRunStatisticsByTrail,
      getTrailStatistic,
      getRaceHighscores,
      run,
      medicalTestId,
      lastExerciseVisitedAt,
      race
    } = this.props;

    getRunSubTestStatistics({ id: run.id });
    getRunStatistic({ id: run.id });
    getRunStatisticsByTrail({ id: run.relationships.trail.data.id });
    getTrailStatistic({ id: run.relationships.trail.data.id });

    if (race && run.attributes.type === 'RaceTrail') {
      getRaceHighscores({ id: race.id, limit: HIGHSCORES_LIMIT });
    } else {
      getMedicalTestStatistics({
        id: medicalTestId,
        createdBefore: lastExerciseVisitedAt
      });
    }
  }

  exit() {
    const { run } = this.props;
    window.location.assign(`/runs/${run.id}/return`);
  }

  openSolutions() {
    const { open: navigate, run, screens } = this.props;
    const solutionsScreen = find(screens, {
      attributes: { subtype: screenTypes.solutions }
    });
    if (solutionsScreen) navigate(run, solutionsScreen);
  }

  openHighscores() {
    const { open: navigate, run, screens } = this.props;
    const highscoresScreen = find(screens, {
      attributes: { subtype: screenTypes.highscores }
    });
    if (highscoresScreen) navigate(run, highscoresScreen);
  }

  render() {
    const {
      run,
      subTest,
      isLoading,
      runStatistic,
      runStatisticsByTrail,
      trail,
      trailStatistic,
      runSubTestStatistics,
      subTestStatistics,
      scoringResults,
      raceHighscores
    } = this.props;

    const { skip_scoring_screen: skipScoringScreen } = run.attributes;

    if (skipScoringScreen || isLoading) return <LoadingScreen />;

    return (
      <ResultsScreen
        run={run}
        subTest={subTest}
        runStatistic={runStatistic}
        runStatisticsByTrail={runStatisticsByTrail}
        trail={trail}
        trailStatistic={trailStatistic}
        onNavigateSolutions={this.openSolutions}
        onNavigateHighscores={this.openHighscores}
        runSubTestStatistics={runSubTestStatistics}
        subTestStatistics={subTestStatistics}
        scoringResults={scoringResults}
        raceHighscores={raceHighscores}
      />
    );
  }
}

ResultsScreenWrapper.defaultProps = {
  runStatistic: null,
  runStatisticsByTrail: null,
  trail: null,
  trailStatistic: null,
  runSubTestStatistics: null,
  subTestStatistics: null,
  race: null,
  raceHighscores: null,
  subTest: null
};

ResultsScreenWrapper.propTypes = {
  getRunStatistic: PropTypes.func.isRequired,
  getRunStatisticsByTrail: PropTypes.func.isRequired,
  getTrailStatistic: PropTypes.func.isRequired,
  getRunSubTestStatistics: PropTypes.func.isRequired,
  getMedicalTestStatistics: PropTypes.func.isRequired,
  getRaceHighscores: PropTypes.func.isRequired,
  open: PropTypes.func.isRequired,
  run: runShape.isRequired,
  runStatistic: runStatisticShape,
  runStatisticsByTrail: PropTypes.arrayOf(runStatisticShape),
  trail: trailShape,
  trailStatistic: trailStatisticShape,
  race: raceShape,
  subTest: subTestShape,
  screens: PropTypes.arrayOf(screenShape).isRequired,
  statsLoaded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pendingEvents: PropTypes.number.isRequired,
  medicalTestId: PropTypes.string.isRequired,
  runSubTestStatistics: spiderGraphShape,
  subTestStatistics: spiderGraphShape,
  raceHighscores: raceHighscoresShape,
  lastExerciseVisitedAt: PropTypes.string.isRequired,
  scoringResults: scoringResultsShape.isRequired
};

const mapStateToProps = (state, { run, screens }) => {
  const {
    subTest,
    runStatistic,
    runStatisticsByTrail,
    trail,
    trailStatistic,
    runSubTestStatistics,
    subTestStatistics,
    pendingEvents,
    medicalTestId,
    statsLoaded,
    lastExerciseVisitedAt,
    scoringResults,
    race,
    raceHighscores
  } = makeGetResultsScreenProps(run.id)(state);

  const trailId = run.relationships.trail.data.id;

  const isLoading =
    isRequestLoading(state, api.getRunSubTestStatistics, { id: run.id }) ||
    isRequestLoading(state, api.getMedicalTestStatistics, {
      id: medicalTestId,
      createdBefore: lastExerciseVisitedAt
    }) ||
    isRequestLoading(state, api.getRunStatistic, { id: run.id }) ||
    isRequestLoading(state, api.getRunStatisticsByTrail, { id: trailId }) ||
    isRequestLoading(state, api.getTrailStatistic, { id: trailId }) ||
    (!!race &&
      isRequestLoading(state, api.getRaceHighscores, {
        id: race.id,
        limit: HIGHSCORES_LIMIT
      }));

  return {
    run,
    subTest,
    screens,
    runStatistic,
    runStatisticsByTrail,
    trail,
    trailStatistic,
    runSubTestStatistics,
    subTestStatistics,
    statsLoaded,
    pendingEvents,
    medicalTestId,
    isLoading,
    lastExerciseVisitedAt,
    scoringResults,
    race,
    raceHighscores
  };
};

const mapDispatchToProps = {
  getRunSubTestStatistics: api.getRunSubTestStatistics,
  getMedicalTestStatistics: api.getMedicalTestStatistics,
  getRaceHighscores: api.getRaceHighscores,
  getRunStatistic: api.getRunStatistic,
  getRunStatisticsByTrail: api.getRunStatisticsByTrail,
  getTrailStatistic: api.getTrailStatistic,
  open
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResultsScreenWrapper);
