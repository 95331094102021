import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { trailStatisticShape } from '../../../shapes/entities';

const TrailStatisticMessage = ({ color, trailStatistic }) => {
  const style = { color };

  const highestScore = (
    <span style={style}>{trailStatistic.attributes.highest_score}</span>
  );

  const meanScore = (
    <span style={style}>
      <FormattedNumber
        value={trailStatistic.attributes.mean_score}
        maximumFractionDigits={1}
      />
    </span>
  );

  return (
    <FormattedMessage
      id="runs.results.trail_statistic_message"
      values={{ highest_score: highestScore, mean_score: meanScore }}
    />
  );
};

TrailStatisticMessage.propTypes = {
  color: PropTypes.string.isRequired,
  trailStatistic: trailStatisticShape.isRequired
};

export default TrailStatisticMessage;
