import Image from '@tiptap/extension-image';
import { mergeAttributes } from '@tiptap/core';
import {
  wrapperAlignmentClasses,
  wrapperSizeClasses,
  imageStyles
} from '../../../constants/style-constants';
import config from '../../../utils/config.erb';

const ImageExtended = Image.extend({
  draggable: true,
  addAttributes() {
    const parent = this.parent ? this.parent() : {};
    return {
      ...parent,
      size: {
        default: '7-16',
        rendered: false
      },
      align: {
        default: 'l-image-align',
        rendered: false
      },
      publicId: {
        default: '',
        rendered: false
      }
    };
  },

  addCommands() {
    const parent = this.parent ? this.parent() : {};
    return {
      ...parent,
      setImgAttrs: options => ({ commands }) => {
        commands.updateAttributes('image', options);
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div image'
      }
    ];
  },

  renderHTML({ HTMLAttributes, node }) {
    const { alt, align, size, publicId } = node.attrs;
    const { cloudName } = config.cloudinary;
    const className = [
      'l-image-align',
      wrapperAlignmentClasses[align],
      wrapperSizeClasses[size]
    ].join(' ');
    const width = imageStyles[size] || {};
    const margin = imageStyles[align] || {};
    const imageStyle = { ...width, ...margin };
    const src = `https://res.cloudinary.com/${cloudName}/image/upload/f_auto,q_auto:eco/c_limit,w_900/${publicId}`;

    function camelCaseToKebabCase(str) {
      return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
    }

    const styleString = Object.entries(imageStyle)
      .map(([key, value]) => `${camelCaseToKebabCase(key)}: ${value};`)
      .join(' ');

    return [
      'div',
      { class: className },
      ['img', mergeAttributes(HTMLAttributes, { src, alt, style: styleString })]
    ];
  }
});

export default ImageExtended;
