import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';

import {
  exerciseShape,
  focusTestSettingsShape
} from '../../../shapes/entities';

import Description from './description';
import Start from './start';
import Board from './board';
import Score from './score';

import { generate, evaluator, formatEvaluation } from './utils';
import * as focusTestModes from './focus_test_modes';

import * as modes from '../../../modes';
import * as alphabets from '../../../data/alphabets';

const numberOfGlyphsPerLine = 40;

class FocusTestScreen extends Component {
  constructor(props) {
    super(props);

    //
    // The the sequence generated after selecting the offline
    // version of a focus test, is stored in the backend in the
    // offline_focus_test_cache attribute of the exercise. This
    // allows for the answer page of offline tests to be reloadable
    // without loosing the reference to the sequence being used.
    //
    const {
      offline_focus_test_cache: offlineCachedData,
      answered,
      user_score: score
    } = props.exercise.attributes;

    const hasAnswer = !isEmpty(answered);
    const { manualEvaluation, selection, sequence, focusTestMode } = hasAnswer
      ? answered
      : offlineCachedData;

    this.state = {
      sequence,
      selection,
      focusTestMode,
      manualEvaluation,
      hasAnswer,
      score
    };

    this.hasStarted = this.hasStarted.bind(this);
    this.hasEnded = this.hasEnded.bind(this);
    this.start = this.start.bind(this);
    this.startSmallScreen = this.startSmallScreen.bind(this);
    this.startBigScreen = this.startBigScreen.bind(this);
    this.startOffline = this.startOffline.bind(this);
    this.end = this.end.bind(this);
    this.attemptToEndExercise = this.attemptToEndExercise.bind(this);
    this.select = this.select.bind(this);
    this.calculateScore = this.calculateScore.bind(this);
    this.manuallyEvaluate = this.manuallyEvaluate.bind(this);
    this.isSelected = this.isSelected.bind(this);

    props.onScreenLeave(this.attemptToEndExercise);
  }

  componentDidMount() {
    const { mode, toggleBlockNext } = this.props;
    if (mode === modes.playing && !this.hasStarted()) toggleBlockNext();
  }

  attemptToEndExercise() {
    if (this.hasStarted() && !this.hasEnded()) this.end();
  }

  isSelected(y, x) {
    const { selection } = this.state;

    return !!selection[[y, x]];
  }

  hasStarted() {
    return typeof this.state.sequence !== 'undefined';
  }

  hasEnded() {
    if (this.state.focusTestMode === focusTestModes.offline) {
      return this.state.hasAnswer;
    }
    return typeof this.state.score === 'number';
  }

  start(focusTestMode) {
    const { source, onUpdate, toggleBlockNext } = this.props;

    const {
      alphabet: alphabetId,
      number_of_lines: numberOfLines
    } = source.attributes;

    const alphabet = alphabets[alphabetId];
    const sequence = isEmpty(this.state.sequence)
      ? generate(alphabet, numberOfGlyphsPerLine, numberOfLines)
      : this.state.sequence;
    const selection = {};

    this.setState(state => ({
      ...state,
      sequence,
      selection,
      score: null,
      focusTestMode
    }));
    toggleBlockNext();
    onUpdate(sequence, selection, 0, focusTestMode, null);
    window.scrollTo(0, 0);
  }

  startSmallScreen() {
    this.start(focusTestModes.smallScreen);
  }

  startBigScreen() {
    this.start(focusTestModes.bigScreen);
  }

  startOffline() {
    this.start(focusTestModes.offline);
  }

  calculateScore() {
    const { exercise, source } = this.props;
    const { sequence, selection, manualEvaluation } = this.state;

    const { achievable_score: achievableScore } = exercise.attributes;
    const { alphabet: alphabetId } = source.attributes;
    const alphabet = alphabets[alphabetId];

    let evaluation = evaluator(alphabet, sequence)(selection);

    if (manualEvaluation) {
      evaluation = formatEvaluation(
        evaluation.counts.total,
        manualEvaluation.hits,
        manualEvaluation.errors,
        manualEvaluation.misses
      );
    }
    return Math.round(evaluation.result * achievableScore);
  }

  end() {
    const { onUpdate } = this.props;
    const { sequence, selection, focusTestMode } = this.state;
    let { manualEvaluation } = this.state;

    // Set manual evaluation to 0, if the user didn't provide any.
    if (focusTestMode === focusTestModes.offline && isEmpty(manualEvaluation)) {
      manualEvaluation = { hits: 0, errors: 0, misses: 0 };
      this.setState({ manualEvaluation });
    }

    const score = this.calculateScore();
    this.setState({ score });

    onUpdate(sequence, selection, score, focusTestMode, manualEvaluation);
  }

  select(y, x) {
    this.setState(({ selection }) => ({
      selection: { ...selection, [[y, x]]: !selection[[y, x]] }
    }));
  }

  manuallyEvaluate({ hits, errors, misses }) {
    this.setState({ manualEvaluation: { hits, errors, misses } });
  }

  render() {
    const { mode, source, exercise, runId } = this.props;

    const {
      alphabet: alphabetId,
      max_run_time_seconds: runtime
    } = source.attributes;
    const { user_score: userScore } = exercise.attributes;

    const alphabet = alphabets[alphabetId];

    let content = null;

    if (this.hasStarted()) {
      const {
        sequence,
        selection,
        focusTestMode,
        manualEvaluation
      } = this.state;

      if (this.hasEnded()) {
        content = (
          <Score
            alphabet={alphabet}
            sequence={sequence}
            selection={selection}
            userScore={userScore}
            manualEvaluation={manualEvaluation}
            isSelected={this.isSelected}
          />
        );
      } else {
        content = (
          <Board
            runId={runId}
            exerciseId={exercise.id}
            alphabet={alphabet}
            focusTestMode={focusTestMode}
            runtime={runtime}
            sequence={sequence}
            onSelect={this.select}
            onManualEvaluation={this.manuallyEvaluate}
            onEnd={this.end}
            isSelected={this.isSelected}
          />
        );
      }
    } else if (mode === modes.playing) {
      content = (
        <Start
          runtime={runtime}
          onStartSmallScreen={this.startSmallScreen}
          onStartBigScreen={this.startBigScreen}
          onStartOffline={this.startOffline}
        />
      );
    } else {
      content = <FormattedMessage id="runs.focus.not_played" />;
    }

    return (
      <>
        <div className="l-grid">
          <div className="l-grid__item l-width--8-of-12@medium">
            <Description alphabet={alphabet} alphabetId={alphabetId} />
          </div>
        </div>
        {content}
      </>
    );
  }
}

FocusTestScreen.propTypes = {
  runId: PropTypes.string.isRequired,
  exercise: exerciseShape.isRequired,
  source: focusTestSettingsShape.isRequired,
  mode: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  toggleBlockNext: PropTypes.func.isRequired,
  onScreenLeave: PropTypes.func.isRequired
};

export default FocusTestScreen;
