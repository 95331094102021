import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  ListBullets,
  ListNumbers,
  TextAa,
  TextHOne,
  TextHTwo,
  TextHThree,
  TextHFour,
  TextHFive,
  TextHSix
} from '@phosphor-icons/react';
import EditorButton from './editor_button';
import Dropdown from './drop_down';
import TipTapEditorContext from '../tiptap_editor_context';

const headingIcons = [
  <TextAa className="o-icon o-icon--large" />,
  <TextHOne className="o-icon o-icon--large" />,
  <TextHTwo className="o-icon o-icon--large" />,
  <TextHThree className="o-icon o-icon--large" />,
  <TextHFour className="o-icon o-icon--large" />,
  <TextHFive className="o-icon o-icon--large" />,
  <TextHSix className="o-icon o-icon--large" />
];

export const headingTypes = [
  {
    label: 'Body text',
    icon: headingIcons[0],
    fn: editor => editor.clearNodes(),
    type: 'heading',
    attributes: { level: null },
    hideLabel: true
  },
  ...Array.from({ length: 6 }).map((_, i) => ({
    label: `Heading level ${i + 1}`,
    icon: headingIcons[i + 1],
    fn: editor => editor.toggleHeading({ level: i + 1 }),
    type: 'heading',
    attributes: { level: i + 1 },
    hideLabel: true
  }))
];

export const listTypes = [
  {
    label: 'Ordered List',
    icon: <ListNumbers className="o-icon o-icon--large" />,
    fn: editor => editor.toggleOrderedList(),
    type: 'orderedList',
    hideLabel: true
  },
  {
    label: 'Unordered List',
    icon: <ListBullets className="o-icon o-icon--large" />,
    fn: editor => editor.toggleBulletList(),
    type: 'bulletList',
    hideLabel: true
  }
];

const getCurrentHeadingLevel = editor => {
  if (editor && editor.isActive('heading')) {
    const idx = headingTypes.filter(headingType => {
      return editor.isActive('heading', {
        level: headingType.attributes.level
      });
    });
    return headingIcons[idx[0].attributes.level];
  }
  return headingIcons[0];
};

const BlockControls = ({ id }) => {
  const { editor: tiptapEditor } = useContext(TipTapEditorContext);

  return (
    <>
      <Dropdown.Wrapper
        className="l-flex__primary l-flex l-flex--column"
        id={`${id}-heading-level`}
      >
        <Dropdown.Button icon={getCurrentHeadingLevel(tiptapEditor)} />
        <Dropdown.List>
          {headingTypes.map(buttonProps => (
            <EditorButton
              {...buttonProps}
              variant="menu"
              role="menuitem"
              key={buttonProps.label}
            />
          ))}
        </Dropdown.List>
      </Dropdown.Wrapper>
      <ul className="l-flex c-joined-ui">
        {listTypes.map(buttonProps => (
          <li key={buttonProps.label}>
            <EditorButton {...buttonProps} />
          </li>
        ))}
      </ul>
    </>
  );
};

BlockControls.propTypes = {
  id: PropTypes.string
};

BlockControls.defaultProps = {
  id: null
};

export default BlockControls;
