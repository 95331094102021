import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import HighscoresTable from '../../highscores/table';
import Controls from '../../controls';
import ControlItem from '../../control_item';
import { runShape, raceHighscoresShape } from '../../../shapes/entities';

const HighscoresScreen = ({ run, raceHighscores, onBackToResults }) => {
  const { allTimeBest } = raceHighscores;

  return (
    <div className="qa-highscores-screen">
      <h1>
        <FormattedMessage id="runs.show.view_highscores" />
      </h1>

      <HighscoresTable run={run} highscores={allTimeBest} withDuration />

      <Controls>
        <ControlItem>
          <button
            type="button"
            className="o-button o-button--inverted l-width--16-of-16@small-only t-margin--bottom@small-only"
            onClick={onBackToResults}
          >
            <FormattedMessage id="runs.show.highscores.back_to_results" />
          </button>
        </ControlItem>
        <ControlItem>
          <a
            className="o-button o-button--test-color l-width--16-of-16@small-only t-margin--bottom@small-only"
            href={`/runs/${run.id}/return`}
          >
            <FormattedMessage id="runs.show.to_startpage" />
          </a>
        </ControlItem>
      </Controls>
    </div>
  );
};

HighscoresScreen.defaultProps = {
  raceHighscores: null
};

HighscoresScreen.propTypes = {
  onBackToResults: PropTypes.func.isRequired,
  run: runShape.isRequired,
  raceHighscores: raceHighscoresShape
};

export default HighscoresScreen;
