import React from 'react';
import PropTypes from 'prop-types';
import ReactEchartsCore from 'echarts-for-react/lib/core';

import * as echarts from 'echarts/index.blank';

import { BarChart as echartsBarChart } from 'echarts/charts';
import { GridComponent, MarkLineComponent } from 'echarts/components';

echarts.use([GridComponent, echartsBarChart, MarkLineComponent]);

export const onChartClick = ({ data: { runId } }) => {
  window.location.assign(`/runs/${runId}`);
};

const withShadow = {
  shadowColor: 'hsl(51, 99%, 30%)',
  shadowBlur: 4
};

const maxAchievableScore = statistics =>
  Math.max(...statistics.map(s => s.achievableScore));

const generateSeries = (statistics, { yAxis: { max }, color }) => {
  const minValue = max * 0.01;

  const data = statistics.map(({ runId, userScore, highlight }) => {
    const itemStyle = highlight ? withShadow : {};
    const value = Math.max(userScore, minValue);

    return { value, itemStyle, runId };
  });

  return {
    data,
    name: 'scores',
    type: 'bar',
    cursor: 'pointer',
    itemStyle: { color },
    showBackground: true,
    backgroundStyle: { color, opacity: 0.1 },
    animationDelay: idx => idx * 50
  };
};

const defaultOptions = {
  animation: true,
  grid: {
    left: '3%',
    right: '3%',
    bottom: '3%',
    top: '9%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    show: false,
    silent: true
  },
  yAxis: {
    type: 'value',
    minInterval: 1,
    min: 0
  }
};

const BarChart = ({ statistics, config }) => {
  const options = { ...defaultOptions, ...config };
  const {
    yAxis: { max },
    series
  } = options;

  if (!max && statistics) {
    options.yAxis.max = maxAchievableScore(statistics);
  }

  if (!series) {
    options.series = generateSeries(statistics, options);
  }

  return (
    <ReactEchartsCore
      className="qa-barchart"
      echarts={echarts}
      option={options}
      onEvents={{ click: onChartClick }}
    />
  );
};

BarChart.defaultProps = {
  statistics: []
};

BarChart.propTypes = {
  config: PropTypes.shape({ color: PropTypes.string.isRequired }).isRequired,
  statistics: PropTypes.arrayOf(
    PropTypes.shape({
      runId: PropTypes.number.isRequired,
      highlight: PropTypes.bool.isRequired,
      achievableScore: PropTypes.number.isRequired,
      userScore: PropTypes.number.isRequired
    })
  )
};

export default BarChart;
