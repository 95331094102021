import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { answerShape, questionShape } from '../../../shapes/entities';
import { modeType } from '../../../shapes/types';
import answerModes from '../../../../../shared/constants/answer_modes';

import AnswerListItem from './answer_list_item';

const AnswerList = ({ answers, mode, selected, question, onToggleAnswer }) => {
  const { answer_mode: answerMode } = question.attributes;
  const horizontalLayout = answerMode === answerModes.horizontal.id;
  const classname = classnames('o-list-reset c-answer-list', {
    'c-answer-list--horizontal': horizontalLayout
  });

  return (
    <div className="c-answer-list-wrapper">
      <ul className={classname}>
        {answers.map(answer => (
          <li key={answer.id} className="c-answer-list__item">
            <AnswerListItem
              mode={mode}
              answer={answer}
              isSelected={selected.indexOf(answer.id) >= 0}
              onToggle={onToggleAnswer}
              horizontalLayout={horizontalLayout}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

AnswerList.propTypes = {
  answers: PropTypes.arrayOf(answerShape).isRequired,
  mode: modeType.isRequired, // eslint-disable-line react/no-typos
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  question: questionShape.isRequired,
  onToggleAnswer: PropTypes.func.isRequired
};

export default AnswerList;
