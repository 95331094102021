/* eslint-disable import/prefer-default-export */

import PropTypes from 'prop-types';
import values from 'lodash.values';

import * as modes from '../modes';
import * as alphabets from '../data/alphabets';
import * as focusTestModes from '../components/screens/focus_test/focus_test_modes';

export const modeType = PropTypes.oneOf(values(modes));
export const alphabetType = PropTypes.oneOf(values(alphabets));
export const focusTestModeType = PropTypes.oneOf(values(focusTestModes));
