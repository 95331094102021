import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Row from './row';
import { alphabetType } from '../../../../shapes/types';

import { startDelay } from './constants';

class OnlinePlayfield extends Component {
  constructor(props) {
    super(props);

    this.evaluate = this.evaluate.bind(this);
  }

  componentDidMount() {
    this.delay = setTimeout(this.props.onStart, startDelay);
  }

  componentWillUnmount() {
    if (typeof this.delay !== 'undefined') clearTimeout(this.delay);
  }

  // eslint-disable-next-line class-methods-use-this
  evaluate() {
    return false;
  }

  render() {
    const {
      sequence,
      alphabet,
      onClick,
      isSelected,
      smallPlayfield
    } = this.props;
    const wrapperClass = classnames('c-focus-test', {
      'c-focus-test--all-rows': !smallPlayfield,
      'c-focus-test--one-row': smallPlayfield
    });

    const listClass = classnames('o-list-reset qa-focus-test-rows', {
      'c-focus-test__grid': !smallPlayfield,
      'l-flex l-flex--fit': smallPlayfield
    });

    /* eslint-disable react/no-array-index-key */
    return (
      <div className={wrapperClass}>
        <ol className={listClass}>
          {sequence.map((row, columnIndex) => (
            <li key={columnIndex}>
              <Row
                key={columnIndex}
                index={columnIndex}
                glyphs={row.map(i => alphabet.glyphs[i])}
                isSelected={isSelected}
                alphabet={alphabet}
                large={smallPlayfield}
                check={this.evaluate}
                onClick={onClick}
                highlighted={false}
                disabled={false}
              />
            </li>
          ))}
        </ol>
      </div>
    );
    /* eslint-enable react/no-array-index-key */
  }
}

OnlinePlayfield.propTypes = {
  alphabet: alphabetType.isRequired, // eslint-disable-line react/no-typos
  sequence: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
  smallPlayfield: PropTypes.bool.isRequired
};

export default OnlinePlayfield;
