import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { answerShape, questionShape } from '../../../../shapes/entities';
import { modeType } from '../../../../shapes/types';
import { slimAnswerTableLayout } from '../../../../../../shared/constants/answer_table_layouts';

import Row from './row';

function times(length) {
  return Array.from({ length }, (_, i) => i);
}

const AnswerTable = ({ answers, mode, selected, question, onToggleAnswer }) => {
  const {
    table_row_count: rowCount,
    table_layout: tableLayout
  } = question.attributes;
  const className = classnames('c-answer-table qa-answer-table', {
    'c-answer-table--no-headers qa-table-no-headers':
      tableLayout === slimAnswerTableLayout
  });
  const rows = times(rowCount + 1);

  const rowProps = {
    answers,
    mode,
    selected,
    question,
    onToggleAnswer
  };

  return (
    <div className="c-answer-table-wrapper t-padding--left t-padding-large--left@medium">
      <table className={className}>
        <tbody>
          {rows.map(rowIndex => (
            <Row key={rowIndex} rowIndex={rowIndex} {...rowProps} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

AnswerTable.propTypes = {
  answers: PropTypes.arrayOf(answerShape).isRequired,
  mode: modeType.isRequired, // eslint-disable-line react/no-typos
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  question: questionShape.isRequired,
  onToggleAnswer: PropTypes.func.isRequired
};

export default AnswerTable;
