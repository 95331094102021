import React from 'react';
import {
  TextAlignLeft,
  TextAlignRight,
  TextAlignCenter,
  TextAlignJustify
} from '@phosphor-icons/react';
import EditorButton from './editor_button';

export const alignmentTypes = [
  {
    label: 'Left',
    fn: editor => editor.setTextAlign('left'),
    type: 'textAlign',
    icon: <TextAlignLeft className="o-icon o-icon--large" />,
    attributes: { textAlign: 'left' }
  },
  {
    label: 'Right',
    fn: editor => editor.setTextAlign('right'),
    type: 'textAlign',
    icon: <TextAlignRight className="o-icon o-icon--large" />,
    attributes: { textAlign: 'right' }
  },
  {
    label: 'Center',
    fn: editor => editor.setTextAlign('center'),
    type: 'textAlign',
    icon: <TextAlignCenter className="o-icon o-icon--large" />,
    attributes: { textAlign: 'center' }
  },
  {
    label: 'Justify',
    fn: editor => editor.setTextAlign('justify'),
    type: 'textAlign',
    icon: <TextAlignJustify className="o-icon o-icon--large" />,
    attributes: { textAlign: 'justify' }
  }
];

const TextAlignmentControls = () => {
  return (
    <ul className="l-flex c-joined-ui">
      {alignmentTypes.map(buttonProps => (
        <li key={buttonProps.label}>
          <EditorButton {...buttonProps} hideLabel />
        </li>
      ))}
    </ul>
  );
};

export default TextAlignmentControls;
