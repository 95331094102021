import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Countdown from '../countdown';

import { runShape, screenShape, chapterShape } from '../../shapes/entities';
import * as screenTypes from '../../screens';

import { chapterAccessibleAt } from '../../utils/chapter_timer';

import { getNextButtonProps } from '../../selectors/player';

const isResultsScreen = screen =>
  screen.attributes.subtype === screenTypes.results;

const resultsScreenButtonLabel = skipScoring =>
  skipScoring
    ? 'runs.show.next_button.last_slide_no_scoring'
    : 'runs.show.next_button.last_slide_scoring';

const exerciseScreenButtonLabel = (chapter, nextScreenChapter) =>
  nextScreenChapter && chapter.id === nextScreenChapter.id
    ? 'runs.show.next_button.normal_slide'
    : 'runs.show.next_button.next_chapter';

export const NextButton = ({
  chapter,
  onClick,
  disabled,
  run,
  nextScreen,
  nextScreenChapter,
  nextScreenChapterEntryBarrier
}) => {
  const nextScreenChapterAccessDate =
    disabled &&
    nextScreenChapter &&
    chapterAccessibleAt(nextScreenChapter, nextScreenChapterEntryBarrier);

  const nextButtonLabel = isResultsScreen(nextScreen)
    ? resultsScreenButtonLabel(run.attributes.skip_scoring_screen)
    : exerciseScreenButtonLabel(chapter, nextScreenChapter);

  // we highlight non-normal slide transitions with a special button color
  const permanentNextAction =
    nextButtonLabel !== 'runs.show.next_button.normal_slide';
  const buttonStyleClass = permanentNextAction
    ? 'o-button--inverted'
    : 'o-button--test-color';

  return (
    <button
      type="button"
      className={`o-button ${buttonStyleClass} l-width--16-of-16@small-only t-margin--bottom@small-only`}
      onClick={onClick}
      disabled={disabled}
    >
      <FormattedMessage id={nextButtonLabel} />
      {disabled && nextScreenChapterAccessDate && (
        <span className="qa-chapter-countdown">
          {' '}
          <Countdown date={nextScreenChapterAccessDate} />
        </span>
      )}
    </button>
  );
};

NextButton.propTypes = {
  run: runShape.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  chapter: chapterShape.isRequired,
  nextScreen: screenShape.isRequired,
  nextScreenChapter: chapterShape,
  nextScreenChapterEntryBarrier: chapterShape
};

const mapStateToProps = getNextButtonProps;

NextButton.defaultProps = {
  disabled: false,
  nextScreenChapter: null,
  nextScreenChapterEntryBarrier: null
};

export default connect(mapStateToProps)(NextButton);
