import React from 'react';
import PropTypes from 'prop-types';
import camelCase from 'lodash.camelcase';
import classnames from 'classnames';

import * as icons from './icons';

import { alphabetType } from '../../../../shapes/types';

const Glyph = ({
  alphabet,
  glyph,
  highlighted,
  large,
  disabled,
  isCorrect,
  isSelected,
  onClick
}) => {
  const classname = classnames('o-button o-button--glyph qa-glyph', {
    'is-correct': highlighted && isSelected && isCorrect,
    'is-incorrect': highlighted && isSelected !== isCorrect,
    'is-selected': isSelected,
    'o-button--glyph-large': large
  });
  const iconClassname = classnames('o-icon o-button__icon', {
    'o-icon--xx-large': large,
    'o-icon--100': !large
  });
  const src = [alphabet.imageset, glyph.code].join('/');
  const id = camelCase(src);

  return (
    <button
      type="button"
      className={classname}
      onClick={onClick}
      disabled={disabled || isSelected}
    >
      <img
        src={icons[id]}
        width="100"
        height="100"
        className={iconClassname}
        alt=""
      />
    </button>
  );
};

Glyph.propTypes = {
  glyph: PropTypes.shape({ code: PropTypes.string.isRequired }).isRequired,
  disabled: PropTypes.bool.isRequired,
  highlighted: PropTypes.bool.isRequired,
  large: PropTypes.bool,
  isCorrect: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  alphabet: alphabetType.isRequired
};

Glyph.defaultProps = {
  large: true
};

export default Glyph;
