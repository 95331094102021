import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import find from 'lodash.find';

import { runShape, chapterShape, screenShape } from '../../shapes/entities';
import { modeType } from '../../shapes/types';

import { makeGetPlayerProps } from '../../selectors/player';

import ChapterList from '../../components/chapter_list';
import AbortRunButton from '../../components/abort_run_button';
import ConnectedConnectionWarning from '../connection_warning';

import ConnectedExerciseScreen from '../screens/exercise';
import ConnectedResultsScreen from '../screens/results';
import ConnectedHighscoresScreen from '../screens/highscores';
import ConnectedSolutionsGridScreen from '../screens/solutions_grid';
import ConnectedExpulsionScreen from '../screens/expulsion';

import { open } from '../../actions/player';
import { chapterExpired } from '../../utils/chapter_timer';
import * as modes from '../../modes';
import * as screenTypes from '../../screens';

const screenMap = {
  [screenTypes.exercises]: ConnectedExerciseScreen,
  [screenTypes.results]: ConnectedResultsScreen,
  [screenTypes.solutions]: ConnectedSolutionsGridScreen,
  [screenTypes.highscores]: ConnectedHighscoresScreen
};

const containerForScreenType = ({ mode, subtype, chapter }) => {
  if (mode !== modes.playing) return screenMap[subtype];
  if (chapter && chapterExpired(chapter)) return ConnectedExpulsionScreen;
  return screenMap[subtype];
};

const scrollToTop = () => window.scrollTo(0, 0);

export class Player extends Component {
  constructor(props) {
    super(props);

    this.openChapter = this.openChapter.bind(this);
    this.openScreen = this.openScreen.bind(this);
    this.beforeUnload = this.beforeUnload.bind(this);
  }

  componentDidMount() {
    const { screens, index } = this.props;
    const screen = screens[index];
    this.openScreen(screen);
    window.addEventListener('beforeunload', this.beforeUnload);
  }

  componentWillReceiveProps(nextProps) {
    const { index } = nextProps;
    const isNewScreen = index !== this.props.index;
    if (isNewScreen) scrollToTop();
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeUnload);
  }

  openChapter(chapter, run) {
    const { screens, mode } = this.props;
    if (mode === modes.playing && run.attributes.type === 'ExerciseTrail')
      return;
    const screen = screens.find(
      s => s.relationships.chapter.data.id === chapter.id
    );
    this.openScreen(screen);
  }

  openScreen(screen) {
    const { run, open: navigate } = this.props;
    if (screen) navigate(run, screen);
  }

  beforeUnload(e) {
    /* To show the confirmation dialog, the event handler should call preventDefault().
     * Not all browsers support this, and instead require a returnValue to be set.
     * See https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event.
     */
    if (this.props.run.attributes.state === 'ongoing') {
      e.preventDefault();
      e.returnValue = '';
    }
  }

  render() {
    const {
      run,
      mode,
      screens,
      index,
      chapters,
      currentChapterId
    } = this.props;

    const screen = screens[index];
    const { subtype } = screen.attributes;
    const chapter = find(chapters, { id: currentChapterId });

    const ScreenContainer = containerForScreenType({ mode, subtype, chapter });

    const containerKey = currentChapterId
      ? `screen-${screen.id}-chapter-${currentChapterId}`
      : `screen-${screen.id}`;
    const containerClass = classNames('c-runplayer__primary c-card', {
      [`theme-chapter--${currentChapterId}`]: !!currentChapterId
    });

    return (
      <div className="l-content t-padding-topbar--top">
        <div className="c-runplayer t-padding--top">
          <main className={containerClass} role="main">
            <AbortRunButton run={run} mode={mode} />
            <ScreenContainer
              run={run}
              mode={mode}
              chapter={chapter}
              chapters={chapters}
              screens={screens}
              index={index}
              key={containerKey}
            />
          </main>

          <ChapterList
            run={run}
            chapters={chapters}
            currentChapterId={currentChapterId}
            onOpenChapter={this.openChapter}
            mode={mode}
          />
        </div>
        <ConnectedConnectionWarning />
      </div>
    );
  }
}

Player.propTypes = {
  run: runShape.isRequired, // eslint-disable-line react/no-typos
  chapters: PropTypes.arrayOf(chapterShape).isRequired,
  screens: PropTypes.arrayOf(screenShape).isRequired,
  index: PropTypes.number.isRequired,
  open: PropTypes.func.isRequired,
  mode: modeType.isRequired, // eslint-disable-line react/no-typos
  currentChapterId: PropTypes.string // eslint-disable-line react/require-default-props
};

const mapStateToProps = (state, ownProps) => makeGetPlayerProps(ownProps.runId);

const mapDispatchToProps = {
  open
};

export default connect(mapStateToProps, mapDispatchToProps)(Player);
