import React from 'react';

import { EditorContent, useEditor } from '@tiptap/react';
import PropTypes from 'prop-types';
import { StarterKit } from '@tiptap/starter-kit';
import { Link } from '@tiptap/extension-link';
import { Superscript } from '@tiptap/extension-superscript';
import { Subscript } from '@tiptap/extension-subscript';
import Mathematics from '@tiptap-pro/extension-mathematics';
import { TableRow } from '@tiptap/extension-table-row';
import { TableHeader } from '@tiptap/extension-table-header';
import { TableCell } from '@tiptap/extension-table-cell';
import { TextAlign } from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import BubbleMenu from '@tiptap/extension-bubble-menu';
import TipTapEditorContext from './tiptap_editor_context';
import Toolbar from './toolbar';
import { PrintOut } from './toolbar/print_out_button';
import Table from './toolbar/table';
import ImageMenu from './toolbar/image_menu';
import ImageExtended from './toolbar/image_extended';
import Video from './toolbar/video';

export const extensions = [
  StarterKit,
  PrintOut,
  Underline,
  Link.configure({ openOnClick: false }),
  ImageExtended,
  Video,
  Table,
  TableRow,
  TableHeader,
  TableCell,
  Superscript.configure({
    HTMLAttributes: {
      style:
        'vertical-align: super; font-size: .83em; line-height: .5em; ' +
        'position: static;'
    }
  }),
  Subscript.configure({
    HTMLAttributes: {
      style:
        'vertical-align: sub; font-size: .83em; line-height: .5em; ' +
        'position: static;'
    }
  }),
  Mathematics.configure({
    katexOptions: {
      displayMode: true
    }
  }),
  TextAlign.configure({
    types: ['heading', 'paragraph', 'image']
  }),
  BubbleMenu.configure({
    pluginKey: 'imageBubbleMenu'
  })
];

const TipTapEditor = ({ initialContent, handleChange, id }) => {
  const editor = useEditor({
    extensions,
    content: initialContent,
    onUpdate: ({ editor: e }) => {
      const json = e.getJSON();
      handleChange(json);
    },
    onCreate: ({ editor: e }) => {
      const json = e.getJSON();
      handleChange(json);
    }
  });

  return (
    <TipTapEditorContext.Provider value={{ editor }}>
      <div className="l-flex l-flex--column t-gap-xs">
        <Toolbar editor={editor} id={id} />
        <ImageMenu />
        <EditorContent editor={editor} className="c-admin-editor" />
      </div>
    </TipTapEditorContext.Provider>
  );
};

TipTapEditor.propTypes = {
  initialContent: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  handleChange: PropTypes.func,
  id: PropTypes.string
};

TipTapEditor.defaultProps = {
  handleChange: null,
  id: null
};

export default TipTapEditor;
