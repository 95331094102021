import { handleActions } from 'redux-actions';

import { enqueue, sending, sent, fail, resume } from '../../actions/sync';

const initialState = {
  pending: 0,
  sending: false,
  paused: false
};

export default handleActions(
  {
    [enqueue]: state => ({
      ...state,
      pending: state.pending + 1
    }),
    [sending]: state => ({
      ...state,
      sending: true
    }),
    [sent]: state => ({
      ...state,
      pending: state.pending - 1,
      sending: false
    }),
    [fail]: state => ({
      ...state,
      sending: false,
      paused: true
    }),
    [resume]: (state, { payload: { retry } }) => ({
      ...state,
      pending: retry ? state.pending : state.pending - 1,
      paused: false
    })
  },
  initialState
);
