const defaultSVGProperties = 'width="100" height="100" viewBox="0 0 100 100"';

const mergeSvgs = (front, back) => {
  const outerSvg = `<svg xmlns="http://www.w3.org/2000/svg" ${defaultSVGProperties}>`;
  const closingOuterSvg = '</svg>';

  return `${outerSvg}${back}${front}${closingOuterSvg}`;
};

const shrinkSvg = svg =>
  svg.replace('viewBox="0 0 100 100"', 'viewBox="-15 -15 130 130"');

const svgCircle = color => {
  const circle = `<circle fill="${color}" cx="50" cy="50" r="50"/>`;
  return `<svg ${defaultSVGProperties} xmlns="http://www.w3.org/2000/svg">${circle}</svg>`;
};

const addBackground = (iconSvg, backgroundColor) => {
  const shrinkedIcon = shrinkSvg(iconSvg); // needs to be shrinked, since it must fit into the circle
  const circle = svgCircle(backgroundColor);

  return mergeSvgs(shrinkedIcon, circle);
};

const toDataUrl = svg => {
  const base64EncodedSvg = btoa(svg);
  return `data:image/svg+xml;base64,${base64EncodedSvg}`;
};

const iconizeSVG = (iconSvg, backgroundColor) =>
  toDataUrl(addBackground(iconSvg, backgroundColor));

export default iconizeSVG;
