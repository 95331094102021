import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import QuestionScreen from '../../../components/screens/question';

import {
  runShape,
  exerciseShape,
  answerShape,
  questionShape
} from '../../../shapes/entities';
import { modeType } from '../../../shapes/types';

import { makeGetQuestionScreenProps } from '../../../selectors/player';
import * as modes from '../../../modes';

import { answer } from '../../../actions/player';

const toggle = (values, x) =>
  values.indexOf(x) >= 0 ? values.filter(e => e !== x) : values.concat([x]);

const showInstantSolution = (run, exercise) => {
  const { instant_solution: instantSolutionActive } = run.attributes;
  const {
    instant_answer_submitted_at: exerciseInstantAnswered
  } = exercise.attributes;

  return instantSolutionActive && !!exerciseInstantAnswered;
};

export class QuestionScreenWrapper extends Component {
  constructor(props) {
    super(props);

    this.toggleAnswer = this.toggleAnswer.bind(this);
    this.isMultipleChoice =
      props.answers.filter(a => a.attributes.correct).length > 1;
  }

  toggleAnswer({ id }) {
    const {
      run,
      mode,
      exercise,
      answer: update,
      isMultipleChoice
    } = this.props;

    if (mode === modes.review) return;
    if (showInstantSolution(run, exercise)) return;

    const { answered } = exercise.attributes;
    const choices = isMultipleChoice ? toggle(answered, id) : [id];
    update(run, exercise, choices);
  }

  render() {
    const {
      run,
      mode,
      exercise,
      source,
      answers,
      showHighlighter
    } = this.props;
    const { answered: selected } = exercise.attributes;
    const {
      always_show_solution: alwaysShowSolution,
      auto_expand_solution: autoExpandSolution
    } = run.attributes;

    return (
      <QuestionScreen
        mode={mode}
        source={source}
        answers={answers}
        selected={selected}
        onToggleAnswer={this.toggleAnswer}
        alwaysShowSolution={alwaysShowSolution}
        showInstantSolution={showInstantSolution(run, exercise)}
        autoExpandSolution={autoExpandSolution}
        highlightsId={exercise.id}
        showHighlighter={showHighlighter}
      />
    );
  }
}

QuestionScreenWrapper.propTypes = {
  run: runShape.isRequired, // eslint-disable-line react/no-typos
  exercise: exerciseShape.isRequired, // eslint-disable-line react/no-typos
  source: questionShape.isRequired, // eslint-disable-line react/no-typos
  answers: PropTypes.arrayOf(answerShape).isRequired,
  answer: PropTypes.func.isRequired,
  mode: modeType.isRequired, // eslint-disable-line react/no-typos
  isMultipleChoice: PropTypes.bool.isRequired,
  showHighlighter: PropTypes.bool.isRequired
};

const mapStateToProps = makeGetQuestionScreenProps;

const mapDispatchToProps = {
  answer
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionScreenWrapper);
