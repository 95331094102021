import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { alphabetType, focusTestModeType } from '../../../shapes/types';

import Countdown from '../../countdown';
import OnlinePlayfield from './board/online_playfield';
import OfflinePlayfield from './board/offline_playfield';
import * as focusTestModes from './focus_test_modes';

class Board extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ending: null
    };

    this.start = this.start.bind(this);
    this.end = this.end.bind(this);
    this.renderPlayfield = this.renderPlayfield.bind(this);
  }

  componentWillUnmount() {
    this.end();
  }

  start() {
    const { runtime, onEnd } = this.props;
    const duration = runtime * 1000;
    this.setState(() => ({ ending: Date.now() + duration }));
    this.timer = setTimeout(onEnd, duration);
  }

  end() {
    if (typeof this.timer !== 'undefined') clearTimeout(this.timer);
  }

  renderPlayfield() {
    const {
      runId,
      exerciseId,
      runtime,
      alphabet,
      sequence,
      onSelect,
      focusTestMode,
      onManualEvaluation,
      isSelected
    } = this.props;

    if (focusTestMode === focusTestModes.offline) {
      return (
        <OfflinePlayfield
          runId={runId}
          exerciseId={exerciseId}
          runtime={runtime}
          onManualEvaluation={onManualEvaluation}
        />
      );
    }

    return (
      <OnlinePlayfield
        alphabet={alphabet}
        sequence={sequence}
        onClick={onSelect}
        onStart={this.start}
        isSelected={isSelected}
        smallPlayfield={focusTestMode === focusTestModes.smallScreen}
      />
    );
  }

  render() {
    const { ending } = this.state;

    return (
      <div>
        {this.renderPlayfield()}
        <div className="t-margin-small--bottom">
          <span className="t-line-height--ui t-color--text-secondary">
            {ending !== null && <Countdown date={ending} />}
          </span>
        </div>
      </div>
    );
  }
}

Board.propTypes = {
  runtime: PropTypes.number.isRequired,
  runId: PropTypes.string.isRequired,
  exerciseId: PropTypes.string.isRequired,
  focusTestMode: focusTestModeType.isRequired,
  alphabet: alphabetType.isRequired, // eslint-disable-line react/no-typos
  sequence: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  onSelect: PropTypes.func.isRequired,
  onEnd: PropTypes.func.isRequired,
  onManualEvaluation: PropTypes.func.isRequired,
  isSelected: PropTypes.func.isRequired
};

export default Board;
