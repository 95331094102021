import React from 'react';
import { FormattedMessage } from 'react-intl';

/* eslint-disable no-restricted-globals */
const ErrorScreen = () => (
  <div>
    <FormattedMessage id="runs.show.error" />

    <button type="button" onClick={() => location.reload()}>
      <FormattedMessage id="runs.show.reload" />
    </button>
  </div>
);
/* eslint-enable no-restricted-globals */

ErrorScreen.propTypes = {};

export default ErrorScreen;
