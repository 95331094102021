import keys from 'lodash.keys';
import {
  rowCorrectnessMap,
  fillWithCorrectGlyphs,
  fillWithIncorrectGlyphs
} from './glyphs-utils';

const correctnessPercentage = 0.25;

export const count = (arr, fn) =>
  arr.reduce((sum, e, i) => (fn(e, i) ? sum + 1 : sum), 0);

export const count2d = (sequence, fn) =>
  sequence.reduce((sum, row, y) => sum + count(row, (_, x) => fn(y, x)), 0);

const countRowCorrectness = (alphabet, row) =>
  count(rowCorrectnessMap(alphabet, row), correct => (correct ? 1 : 0));

/**
 * Generate a row of glyphs, with a target of correct glyphs to reach if possible.
 * @param {*} alphabet An alphabet object
 * @param {*} correctGlyphBacklog An integer representing missed correct glyphs from the last row
 * @param {*} width The board width
 */
const generateRow = (alphabet, correctGlyphBacklog, width) => {
  const target =
    Math.floor(width * correctnessPercentage) + correctGlyphBacklog;
  const row = new Array(width).fill(null);
  fillWithCorrectGlyphs(alphabet, row, target);
  fillWithIncorrectGlyphs(alphabet, row);
  const actual = countRowCorrectness(alphabet, row);
  return [row, target - actual];
};

/**
 * `generate` generates a matrix (width x height) of glyphs from the given alphabet.
 * The user has to identify correct glyphs. We attempt to have around 25%
 * correct glyphs in the matrix. Algorithm is as follow:
 * - Try to generate a row with a target 25% of correct glyphs
 * - If the target is not met, try to generate the next row with 25% + remnants from the last row
 */
export const generate = (alphabet, width, height) => {
  let correctGlyphBacklog = 0;
  return new Array(height).fill(true).map(() => {
    const [row, backlog] = generateRow(alphabet, correctGlyphBacklog, width);
    correctGlyphBacklog = backlog;
    return row;
  });
};

export const checker = (alphabet, sequence) => {
  const correctnessMap = sequence.map(row => rowCorrectnessMap(alphabet, row));
  return (y, x) => correctnessMap[y][x];
};

export const formatEvaluation = (total, hits, errors, misses) => {
  const points = hits - errors - misses;
  // The result can go below 0 or above 1 when the user enters unrealistic data.
  const result = Math.min(Math.max(0, points / total), 1);

  return {
    result,
    counts: {
      points,
      total,
      hits,
      errors,
      misses
    }
  };
};

const getMaxRowIndex = selected =>
  selected.reduce((max, [y]) => (y > max ? y : max), -1);
const getMaxColumnIndex = (selected, rowIndex) =>
  selected
    .filter(([y]) => y === rowIndex)
    // eslint-disable-next-line no-unused-vars
    .reduce((max, [_y, x]) => (x > max ? x : max), -1);

/**
 * The range represents all the glyphs up to the last selected one.
 * When calculating the number of missed glyphs by the user, we only
 * want to take in consideration the section of the glyphs the user
 * has worked on.
 * @param {*} selected A list of [y, x] pairs
 * @param {*} sequence The glyph matrix
 */
const calculateRange = (selected, sequence) => {
  if (selected.length === 0) {
    return [];
  }
  const rowStop = getMaxRowIndex(selected);
  const columnStop = getMaxColumnIndex(selected, rowStop);

  return sequence
    .slice(0, rowStop)
    .concat([sequence[rowStop].slice(0, columnStop + 1)]);
};

export const evaluator = (alphabet, sequence) => {
  const check = checker(alphabet, sequence);
  const total = count2d(sequence, (y, x) => check(y, x));

  return selection => {
    const parse = key => key.split(',').map(c => parseInt(c, 10)); // map 2d-index "y,x" to [y, x] pairs
    const selected = keys(selection).map(parse);

    const range = calculateRange(selected, sequence);
    const rangeTotal = count2d(range, (y, x) => check(y, x));

    const hits = count(selected, ([y, x]) => check(y, x));
    const errors = selected.length - hits;
    const misses = rangeTotal - hits;

    return formatEvaluation(total, hits, errors, misses);
  };
};

export const noop = () => {};
