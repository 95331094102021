// Default options for the spider graph: do not show a title and prevent the
// plot form jumping around.
export const defaultOption = {
  title: {
    show: false
  },
  animation: false
};

// The opacity of the plot area.
export const opacity = 0.75;

// The default color for a area.
export const color = '#5ba4cf';

// The default color for a line.
export const lineColor = '#457f96';

// The default line width (if we want to display one)
export const lineWidth = 4;

// The scale for each axis. This is supposed to work with relative (percentage)
// values then.
export const scale = {
  min: 0,
  max: 100
};

// The styles to have colored circles for each axis.
export const iconStyle = {
  height: 25,
  width: 25,
  borderRadius: 25
};

// The default style for data points (hiding them)
export const itemStyle = {
  opacity: 0
};

// Function to format the name of an axis as just an icon (colored circle).
export const formatter = (_value, indicator) => `{${indicator.styleId}|}`;

// Defaults for displaying a radar chart: display the chart centered with black
// lines and make it non-interactable.
export const radarDefaults = {
  center: ['50%', '50%'],
  radius: '70%',
  startAngle: 90,
  splitNumber: 5,
  silent: true,
  splitArea: {
    show: false
  },
  axisLine: {
    lineStyle: {
      color: 'black'
    }
  },
  splitLine: {
    lineStyle: {
      color: 'black'
    }
  }
};
