import React, { Component } from 'react';
import PropTypes from 'prop-types';

import RichContentViewer from '../../../../../shared/components/rich/viewer';
import HintButton from './hint_button';

class Hint extends Component {
  constructor(props) {
    super(props);

    this.state = { collapsed: true };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(({ collapsed }) => ({ collapsed: !collapsed }));
  }

  render() {
    const buttonOrHint = this.state.collapsed ? (
      <HintButton onClick={this.toggle} />
    ) : (
      <RichContentViewer content={this.props.content} />
    );

    return <div className="t-margin--bottom">{buttonOrHint}</div>;
  }
}

Hint.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired // eslint-disable-line react/no-typos
};

export default Hint;
