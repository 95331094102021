import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { exerciseShape } from '../../../shapes/entities';
import { modeType } from '../../../shapes/types';
import * as modes from '../../../modes';
import { visited, answered, correct, isQuestion } from './utils';

const playingClassNames = exercise => {
  const isVisited = visited(exercise);
  const isAnswered = answered(exercise);
  const isInfo = !isQuestion(exercise);

  return classnames({
    'o-button--question-not-visited': !isVisited,
    'o-button--question-visited': isVisited && !isAnswered,
    'o-button--question-answered': isVisited && isAnswered,
    'o-button--question-info': isInfo
  });
};

const reviewClassNames = exercise =>
  classnames({
    'o-button--question-correct': isQuestion(exercise) && correct(exercise),
    'o-button--question-incorrect': isQuestion(exercise) && !correct(exercise),
    'o-button--question-info': !isQuestion(exercise),
    'o-button--question-visited': true
  });

const ChapterProgressBarItem = ({
  isActive,
  onClick,
  exercise,
  mode,
  instantSolutionActive
}) => {
  const {
    instant_answer_submitted_at: exerciseInstantAnswered
  } = exercise.attributes;
  const reviewMode =
    mode === modes.review ||
    (instantSolutionActive && !!exerciseInstantAnswered);
  const colorClasses = reviewMode
    ? reviewClassNames(exercise)
    : playingClassNames(exercise);

  const className = classnames('o-button', 'o-button--question', colorClasses, {
    'o-button--question--active': isActive
  });

  return (
    <button
      className={className}
      onClick={() => onClick(exercise)}
      type="button"
    />
  );
};

ChapterProgressBarItem.defaultProps = {
  isActive: false
};

ChapterProgressBarItem.propTypes = {
  exercise: exerciseShape.isRequired,
  onClick: PropTypes.func.isRequired,
  mode: modeType.isRequired, // eslint-disable-line react/no-typos
  isActive: PropTypes.bool,
  instantSolutionActive: PropTypes.bool.isRequired
};

export default ChapterProgressBarItem;
