import { reducer } from 'redux-bees';

export default resource => {
  const action = {
    meta: { type: 'response', api: true },
    payload: { body: resource }
  };

  const bees = reducer(undefined, action);

  return { bees };
};
