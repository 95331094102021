import {
  open,
  answer,
  expireChapter,
  endChapter,
  instantAnswer
} from '../actions/player';
import { paused, resume, sending, sent, fail, enqueue } from '../actions/sync';
import * as screenTypes from '../screens';

const now = () => Date.now();

const wrap = attributes => ({ data: { type: 'events', attributes } });

const mappings = {
  [open]: ({ screen }) => {
    const { subtype } = screen.attributes;

    if (subtype === screenTypes.exercises) {
      const { exercise } = screen.relationships;

      return {
        event_type: 'navigate',
        client_timestamp: now(),
        exercise_id: exercise.data.id
      };
    }

    if (subtype === screenTypes.results) {
      return {
        event_type: 'finished',
        client_timestamp: now()
      };
    }

    return null;
  },
  [expireChapter]: ({ chapter }) => {
    return {
      event_type: 'end_chapter',
      client_timestamp: now(),
      chapter_id: chapter.id
    };
  },
  [endChapter]: ({ chapter }) => {
    return {
      event_type: 'end_chapter',
      client_timestamp: now(),
      chapter_id: chapter.id
    };
  },
  [answer]: ({ exercise, data, score }) => ({
    event_type: 'answered',
    client_timestamp: now(),
    exercise_id: exercise.id,
    answer: data,
    user_score: score
  }),
  [instantAnswer]: ({ exercise }) => ({
    event_type: 'submittedInstantAnswer',
    client_timestamp: now(),
    exercise_id: exercise.id,
    instant_answer_submitted_at: now()
  })
};

const map = (type, payload) =>
  type in mappings ? wrap(mappings[type](payload)) : null;

const noop = () => {};

const buildMiddleware = ({ client }) => store => {
  let proceed = noop;

  const enhance = (send, run, event) => {
    const before = new Promise(resolve => {
      store.dispatch(sending(run, event));
      resolve();
    });

    const after = () => {
      store.dispatch(sent(run, event));
    };

    const interrupt = () => {
      store.dispatch(fail(run, event));

      return new Promise(resolve => {
        proceed = retry => {
          proceed = noop; // reset the proceed function
          if (retry) return resolve(enhance(send, run, event));
          return resolve();
        };

        store.dispatch(paused());
      });
    };

    return before
      .then(send)
      .then(after)
      .catch(interrupt);
  };

  return next => action => {
    const { type, payload, meta } = action;
    const event = map(type, payload, meta);

    if (event && meta.run.attributes.state === 'ongoing') {
      store.dispatch(enqueue(meta.run, event));
      client.push(meta.run, event, enhance);
    }

    if (type === resume.toString()) {
      proceed(payload.retry);
    }

    return next(action);
  };
};

export default buildMiddleware;
