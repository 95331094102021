import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import {
  exerciseShape,
  offlineFocusTestSettingsShape
} from '../../../shapes/entities';

import * as modes from '../../../modes';

function notDoable() {
  return <FormattedMessage id="runs.offline_focus.not_doable" />;
}

function showResults(exercise) {
  const {
    raw_user_score: rawScore,
    user_score: estimatedPoints,
    percent_rank: rank
  } = exercise.attributes;
  const percentRank = rank ? Math.round(rank * 100) : '?';

  return (
    <div>
      <ul className="o-list-reset t-margin--bottom">
        <li>
          <strong>
            <FormattedMessage
              id="runs.offline_focus.raw_score"
              values={{ raw_score: rawScore }}
            />
          </strong>
        </li>
        <li>
          <strong>
            <FormattedMessage
              id="runs.offline_focus.user_percent_rank"
              values={{ user_percent_rank: percentRank }}
            />
          </strong>
        </li>
        <li>
          <strong>
            <FormattedMessage
              id="runs.offline_focus.points"
              values={{ points: estimatedPoints }}
            />
          </strong>
        </li>
      </ul>
      <p className="t-no-margin">
        <FormattedMessage id="runs.offline_focus.raw_score_results_from_this_formula" />
      </p>
      <p>
        <FormattedMessage id="runs.offline_focus.raw_score_formula" />
      </p>
      <p className="t-no-margin">
        <FormattedMessage id="runs.offline_focus.user_percent_rank_details" />
      </p>
      <p className="t-no-margin">
        <FormattedMessage id="runs.offline_focus.points_details" />
      </p>
      <p>
        <FormattedMessage id="runs.offline_focus.percent_rank_will_change" />
      </p>
    </div>
  );
}

class OfflineFocusTestScreen extends Component {
  render() {
    const { mode, exercise, source } = this.props;

    return mode === modes.playing ? notDoable() : showResults(exercise, source);
  }
}

OfflineFocusTestScreen.propTypes = {
  exercise: exerciseShape.isRequired,
  source: offlineFocusTestSettingsShape.isRequired,
  mode: PropTypes.string.isRequired
};

export default OfflineFocusTestScreen;
