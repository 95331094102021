import React from 'react';

import { Node, mergeAttributes } from '@tiptap/core';
import { Printer } from '@phosphor-icons/react';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { FormattedMessage } from 'react-intl';
import EditorButton from './editor_button';

export const PrintOut = Node.create({
  name: 'printOut',
  atom: true,
  content: 'text*',
  group: 'block',
  defining: true,

  addAttributes() {
    return {
      type: {
        default: 'button'
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'button'
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['react-component', mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    const onClick = () => {
      const location = window.location.toString();
      const runPathRegex = /\/runs\/\d+\/?$/;

      if (location.match(runPathRegex)) {
        window.open(`${location}/printout?type=context`);
      } else {
        alert('This button can only be used during a run.'); // eslint-disable-line no-alert
      }

      return null;
    };
    return ReactNodeViewRenderer(() => (
      <NodeViewWrapper className="react-component">
        <button
          className="o-button o-button--ghost print-out__button"
          type="button"
          onClick={onClick}
        >
          <FormattedMessage id="runs.show.print_out_button" />
        </button>
      </NodeViewWrapper>
    ));
  },

  addCommands() {
    return {
      printOut: attributes => ({ commands }) => {
        return commands.insertContent({
          type: this.name,
          content: attributes
        });
      }
    };
  }
});

export const PrintOutButton = () => {
  const buttonProps = {
    label: 'Add print link',
    icon: <Printer className="o-icon o-icon--large" />,
    fn: editor => editor.printOut(),
    type: 'printOut'
  };

  return <EditorButton {...buttonProps} />;
};
