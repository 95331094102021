import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import find from 'lodash.find';

import {
  runShape,
  chapterShape,
  screenShape,
  exerciseShape
} from '../../../shapes/entities';

import { open } from '../../../actions/player';
import { getSolutionsGridScreenProps } from '../../../selectors/player';
import * as screenTypes from '../../../screens';

import SolutionsGridScreen from '../../../components/screens/solutions_grid';

export class SolutionsGridWrapper extends Component {
  constructor(props) {
    super(props);

    this.openResultsScreen = this.openResultsScreen.bind(this);
    this.exit = this.exit.bind(this);
    this.openExerciseScreen = this.openExerciseScreen.bind(this);
  }

  openResultsScreen() {
    const { open: navigate, run, screens } = this.props;
    const solutionsScreen = find(screens, {
      attributes: { subtype: screenTypes.results }
    });
    if (solutionsScreen) {
      navigate(run, solutionsScreen);
    }
  }

  exit() {
    const { run } = this.props;
    window.location.assign(`/runs/${run.id}/return`);
  }

  openExerciseScreen(exercise) {
    const { open: navigate, run, screens } = this.props;
    const { id, type } = exercise;
    const matcher = { relationships: { exercise: { data: { id, type } } } };
    const target = find(screens, matcher);
    if (target) {
      navigate(run, target);
    }
  }

  render() {
    const { chapters, chapterExerciseMap } = this.props;

    return (
      <SolutionsGridScreen
        chapters={chapters}
        onOpenResultsScreen={this.openResultsScreen}
        onExit={this.exit}
        onOpenExerciseScreen={this.openExerciseScreen}
        chapterExerciseMap={chapterExerciseMap}
      />
    );
  }
}

SolutionsGridWrapper.propTypes = {
  run: runShape.isRequired,
  screens: PropTypes.arrayOf(screenShape).isRequired,
  chapters: PropTypes.arrayOf(chapterShape).isRequired,
  chapterExerciseMap: PropTypes.objectOf(PropTypes.arrayOf(exerciseShape))
    .isRequired,
  open: PropTypes.func.isRequired
};

const mapStateToProps = getSolutionsGridScreenProps;

const mapDispatchToProps = {
  open
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SolutionsGridWrapper);
