import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { answerShape } from '../../../shapes/entities';
import { modeType } from '../../../shapes/types';
import { review } from '../../../modes';

import RichContentViewer from '../../../../../shared/components/rich/viewer';
import CorrectnessLabel from '../../correctness_label';

const preventFocus = e => e.preventDefault();

class AnswerListItem extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
  }

  reviewMode() {
    return this.props.mode === review;
  }

  toggle() {
    if (this.reviewMode()) return;

    this.props.onToggle(this.props.answer);
  }

  render() {
    const { answer, isSelected, horizontalLayout } = this.props;
    const showCorrectnessLabel = this.reviewMode() && answer.attributes.correct;

    const className = classnames(
      'o-button',
      'o-button--chapter-color',
      'o-button--answer',
      'c-answer-list__answer',
      {
        'is-correct': this.reviewMode() && answer.attributes.correct,
        'is-incorrect': this.reviewMode() && !answer.attributes.correct,
        'c-answer-list__answer--horizontal': horizontalLayout,
        'o-button--answer-horizontal': horizontalLayout,
        'l-width--16-of-16': !horizontalLayout
      }
    );

    // TODO: this should be merged with the answer-table item component, as they only differ visually
    return (
      <button
        type="button"
        className={className}
        onClick={this.toggle}
        onMouseDown={preventFocus}
        onKeyDown={this.toggle}
        aria-pressed={isSelected}
      >
        <span className="o-button__answer-label">
          <RichContentViewer content={answer.attributes.content} />
        </span>{' '}
        {showCorrectnessLabel && <CorrectnessLabel />}
      </button>
    );
  }
}

AnswerListItem.propTypes = {
  mode: modeType.isRequired, // eslint-disable-line react/no-typos
  answer: answerShape.isRequired, // eslint-disable-line react/no-typos
  isSelected: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  horizontalLayout: PropTypes.bool.isRequired
};

export default AnswerListItem;
