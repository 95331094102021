/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

import sample from 'lodash.sample';

// A translation of the ruby alphabet description file to js.
// See: app/services/focus_test_pdf/alphabets.rb
// Make sure to synchronize any changes.
//
// An alphabet always has two function:
// * `check`: given the current glyph and the ones before and after
//            returns a bool whether the current glyph needs to be crossed
// * `correct`: given a glyph-index-triple (the current glyph index and the ones before and after)
//              it returns the glyph-index-triple to make the current one "correct" or null
//              if no option would make it correct.
//              Glyph indices can be:
//                * a number (to reference a glyph),
//                * `null` to indicate no glyph was placed yet, or
//                * `undefined` to indicate that this position is out of the row-array (before 0 or >= row.length)
//              The function returns again a glyph-triple where the glyphs are:
//                * a number (to reference a glyph),
//                * `null` to indicate that this position does not need to change to make the current glyph correct

const odd = glyph => glyph && glyph.value % 2 === 1;
const even = glyph => glyph && glyph.value % 2 === 0;
const zip_stringify = (arr1, arr2) => arr1.map((k, i) => `${k},${arr2[i]}`);

export const b2_1 = {
  imageset: 'bq-line',
  glyphs: [
    { code: '0b1', value: false },
    { code: '0b2', value: true },
    { code: '1b0', value: false },
    { code: '1b1', value: true },
    { code: '1b2', value: false },
    { code: '2b0', value: true },
    { code: '2b1', value: false },
    { code: '2b2', value: false },
    { code: '0q1', value: false },
    { code: '0q2', value: false },
    { code: '1q0', value: false },
    { code: '1q1', value: false },
    { code: '1q2', value: false },
    { code: '2q0', value: false },
    { code: '2q1', value: false },
    { code: '2q2', value: false }
  ],
  check: (_before, curr, _after) => curr && curr.value,
  correct: (_before, curr, _after) => {
    if (curr !== null) {
      return null;
    }
    return [null, sample([1, 3, 5]), null];
  }
};

export const bd_1 = {
  imageset: 'letters',
  glyphs: [
    { code: 'b', value: 'b' },
    { code: 'd', value: 'd' }
  ],
  check: (_before, curr, after) =>
    after && curr && curr.value === 'b' && after.value === 'd',
  correct: (_before, curr, after) => {
    if (curr !== null || (after && after.value === 'b')) {
      return null;
    }

    return [null, 0, 1];
  }
};

export const bd_2 = {
  imageset: 'letters',
  glyphs: [
    { code: 'b', value: 'b' },
    { code: 'd', value: 'd' }
  ],
  check: (before, curr, _after) =>
    before && curr && curr.value === 'd' && before.value === 'b',
  correct: (before, curr, _after) => {
    if (curr !== null || (before && before.value === 'd')) {
      return null;
    }

    return [0, 1, null];
  }
};

export const bowling_1 = {
  imageset: 'circle-dots-2x2',
  glyphs: [
    { code: '0001', value: 0b0001 },
    { code: '0010', value: 0b0010 },
    { code: '0011', value: 0b0011 },
    { code: '0100', value: 0b0100 },
    { code: '0101', value: 0b0101 },
    { code: '0110', value: 0b0110 },
    { code: '0111', value: 0b0111 },
    { code: '1000', value: 0b1000 },
    { code: '1001', value: 0b1001 },
    { code: '1010', value: 0b1010 },
    { code: '1011', value: 0b1011 },
    { code: '1100', value: 0b1100 },
    { code: '1101', value: 0b1101 },
    { code: '1110', value: 0b1110 }
  ],
  check: (_before, curr, after) =>
    after &&
    curr &&
    ((curr.value === 0b0100 && after.value === 0b0010) ||
      (curr.value === 0b0110 && after.value === 0b1011)),
  correct: (_before, curr, after) => {
    if (curr !== null) {
      return null;
    }
    if (after && after.value === 0b0010) {
      return [null, 3, null];
    }
    if (after && after.value === 0b1011) {
      return [null, 5, null];
    }
    return sample([
      [null, 3, 1],
      [null, 5, 10]
    ]);
  }
};

export const bowling_2 = {
  imageset: 'circle-dots-2x2',
  glyphs: [
    { code: '0001', value: 0b0001 },
    { code: '0010', value: 0b0010 },
    { code: '0011', value: 0b0011 },
    { code: '0100', value: 0b0100 },
    { code: '0101', value: 0b0101 },
    { code: '0110', value: 0b0110 },
    { code: '0111', value: 0b0111 },
    { code: '1000', value: 0b1000 },
    { code: '1001', value: 0b1001 },
    { code: '1010', value: 0b1010 },
    { code: '1011', value: 0b1011 },
    { code: '1100', value: 0b1100 },
    { code: '1101', value: 0b1101 },
    { code: '1110', value: 0b1110 }
  ],
  check: (before, curr, _after) =>
    before &&
    curr &&
    ((before.value === 0b1101 && curr.value === 0b0110) ||
      (before.value === 0b0100 && curr.value === 0b0010)),
  correct: (before, curr, _after) => {
    if (curr !== null) {
      return null;
    }
    if (before && before.value === 0b1101) {
      return [null, 5, null];
    }
    if (before && before.value === 0b0100) {
      return [null, 1, null];
    }
    return sample([
      [12, 5, null],
      [3, 1, null]
    ]);
  }
};

export const db_1 = {
  imageset: 'letters',
  glyphs: [
    { code: 'b', value: 'b' },
    { code: 'd', value: 'd' }
  ],
  check: (_before, curr, after) =>
    after && curr && curr.value === 'd' && after.value === 'b',
  correct: (_before, curr, after) => {
    if (curr !== null || (after && after.value === 'd')) {
      return null;
    }
    return [null, 1, 0];
  }
};

export const db_2 = {
  imageset: 'letters',
  glyphs: [
    { code: 'b', value: 'b' },
    { code: 'd', value: 'd' }
  ],
  check: (before, curr, _after) =>
    before && curr && curr.value === 'b' && before.value === 'd',
  correct: (before, curr, _after) => {
    if (curr !== null || (before && before.value === 'b')) {
      return null;
    }
    return [1, 0, null];
  }
};

const deckungsgleich_glyphs = [
  { code: '0101', value: [0, 1, 0, 1] }, // 0, correct with [4, 5]
  { code: '0111', value: [0, 1, 1, 1] }, // 1, correct with 2
  { code: '1010', value: [1, 0, 1, 0] }, // 2, correct with [1, 3]
  { code: '1101', value: [1, 1, 0, 1] }, // 3, correct with 2
  { code: '1110', value: [1, 1, 1, 0] }, // 4, correct with 0
  { code: '1011', value: [1, 0, 1, 1] } // 5, correct with 0
];

const deckungsgleich_check = (curr, glyph) => {
  return (
    glyph &&
    curr &&
    zip_stringify(curr.value, glyph.value).filter(x => x === '1,1').length === 1
  );
};

const deckungsgleich_correct = (curr, glyph, isBefore) => {
  if (curr !== null) {
    return null;
  }

  if (glyph && glyph.value) {
    switch (glyph.value) {
      case [1, 0, 1, 0]:
        return [null, sample([3, 1]), null];
      case [0, 1, 0, 1]:
        return [null, sample([4, 5]), null];
      // These two cases are the same (Fall through)
      case [1, 1, 0, 1]:
      case [0, 1, 1, 1]:
        return [null, 2, null];
      // These two cases are the same (Fall through)
      case [1, 1, 1, 0]:
      case [1, 0, 1, 1]:
        return [null, 0, null];
      default:
        return null;
    }
  }

  const sided_combinations = [
    [3, 2],
    [1, 2],
    [4, 0],
    [5, 0]
  ];

  const combinations = sided_combinations.concat(
    sided_combinations.slice().reverse() // Using .slice() to make a copy
  );
  const [newCurr, newGlyph] = sample(combinations);
  return isBefore ? [newGlyph, newCurr, null] : [null, newCurr, newGlyph];
};

export const deckungsgleich_1 = {
  imageset: 'circle-dots-2x2-rotated',
  glyphs: deckungsgleich_glyphs,
  check: (_before, curr, after) => deckungsgleich_check(curr, after),
  correct: (_before, curr, after) => deckungsgleich_correct(curr, after, false)
};

export const deckungsgleich_2 = {
  imageset: 'circle-dots-2x2-rotated',
  glyphs: deckungsgleich_glyphs,
  check: (before, curr, _after) => deckungsgleich_check(curr, before),
  correct: (before, curr, _after) => deckungsgleich_correct(curr, before, true)
};

export const deckungsgleich_linien_1 = {
  imageset: 'box-with-lines',
  glyphs: deckungsgleich_glyphs,
  check: (_before, curr, after) => deckungsgleich_check(curr, after),
  correct: (_before, curr, after) => deckungsgleich_correct(curr, after, false)
};

export const deckungsgleich_linien_2 = {
  imageset: 'box-with-lines',
  glyphs: deckungsgleich_glyphs,
  check: (before, curr, _after) => deckungsgleich_check(curr, before),
  correct: (before, curr, _after) => deckungsgleich_correct(curr, before, true)
};

export const gerade_zahlen_1 = {
  imageset: 'numbers',
  glyphs: [
    { code: '1', value: 1 },
    { code: '2', value: 2 },
    { code: '3', value: 3 },
    { code: '4', value: 4 },
    { code: '5', value: 5 },
    { code: '6', value: 6 }
  ],
  check: (before, curr, after) => odd(before) && even(curr) && odd(after),
  correct: (before, curr, after) => {
    if (curr !== null || even(before) || even(after)) {
      return null;
    }
    if (odd(before) && after === null) {
      return [null, sample([1, 3, 5]), sample([0, 2, 4])];
    }
    if (before === null && odd(after)) {
      return [sample([0, 2, 4]), sample([1, 3, 5]), null];
    }
    return [sample([0, 2, 4]), sample([1, 3, 5]), sample([0, 2, 4])];
  }
};

export const hufeisen_1 = {
  imageset: 'horseshoe',
  glyphs: [
    { code: 'n', value: 'n' },
    { code: 'ne', value: 'ne' },
    { code: 'e', value: 'e' },
    { code: 'se', value: 'se' },
    { code: 's', value: 's' },
    { code: 'sw', value: 'sw' },
    { code: 'w', value: 'w' },
    { code: 'nw', value: 'nw' }
  ],
  check: (before, curr, after) =>
    after &&
    curr &&
    ((curr.value === 'nw' && after.value === 'ne') ||
      (curr.value === 's' && after.value === 'e')),
  correct: (before, curr, after) => {
    if (
      curr !== null ||
      (after && after.value !== 'ne') ||
      (after && after.value !== 'e')
    ) {
      return null;
    }

    if (after && after.value === 'ne') {
      return [null, 7, null];
    }

    if (after && after.value === 'e') {
      return [null, 4, null];
    }

    return sample([
      [null, 7, 1],
      [null, 4, 2]
    ]);
  }
};

const kastchen_kreuze_glyphs = [
  { code: '0001', value: [0, 0, 0, 1] }, // 0
  { code: '0010', value: [0, 0, 1, 0] }, // 1
  { code: '0011', value: [0, 0, 1, 1] }, // 2
  { code: '0100', value: [0, 1, 0, 0] }, // 3
  { code: '0110', value: [0, 1, 1, 0] }, // 4
  { code: '0111', value: [0, 1, 1, 1] }, // 5
  { code: '1000', value: [1, 0, 0, 0] }, // 6
  { code: '1001', value: [1, 0, 0, 1] }, // 7
  { code: '1011', value: [1, 0, 1, 1] }, // 8
  { code: '1100', value: [1, 1, 0, 0] }, // 9
  { code: '1101', value: [1, 1, 0, 1] }, // 10
  { code: '1110', value: [1, 1, 1, 0] }, // 11
  { code: '1111', value: [1, 1, 1, 1] } // 12
];

const kastchen_kreuze_check = (curr, glyph) => {
  return (
    glyph &&
    curr &&
    curr.value.every(
      (x, index) =>
        (x === 0 && glyph.value[index] === 1) ||
        (x === 1 && glyph.value[index] === 0)
    )
  );
};

const kastchen_kreuze_correct = (curr, glyph, isBefore) => {
  if (curr !== null) {
    return null;
  }

  const indexes = Array.from(Array(kastchen_kreuze_glyphs.length).keys());

  if (glyph && glyph.value) {
    switch (glyph.value) {
      case [1, 1, 1, 1]:
        return [null, null, null];
      case [1, 1, 1, 0]:
        return [null, 0, null];
      case [1, 1, 0, 1]:
        return [null, 1, null];
      case [1, 1, 0, 0]:
        return [null, 2, null];
      case [1, 0, 1, 1]:
        return [null, 3, null];
      case [1, 0, 0, 1]:
        return [null, 4, null];
      case [1, 0, 0, 0]:
        return [null, 5, null];
      case [0, 1, 1, 1]:
        return [null, 6, null];
      case [0, 1, 1, 0]:
        return [null, 7, null];
      case [0, 1, 0, 0]:
        return [null, 8, null];
      case [0, 0, 1, 1]:
        return [null, 9, null];
      case [0, 0, 1, 0]:
        return [null, 10, null];
      case [0, 0, 0, 1]:
        return [null, 11, null];
      default:
        return null;
    }
  }

  const sided_combinations = [
    [0, 11],
    [1, 10],
    [2, 9],
    [3, 8],
    [4, 7],
    [5, 6]
  ];

  // The glyph index 12, which contains a full cross, will never be correct.
  // With our current algorithm, the glyph 12 would *never* be included in the
  // generated sequence, since no combination can ever make it correct.
  //
  // In order to have the glyph 12 appear nonetheless, while trying to stay
  // close to the goal of having 25% correctness in the sequence, we are randomly
  // chosing a pair with the glyph 12 and adding it to the list of correct combinations.
  //
  // This will skew the chances of generating a sequence with 25% correctness down a little.
  // If it turns out, that the % of correctness is too low, we could choose to toss
  // a coin and include `random_incorrect` in the list of correct combinations
  // only with a certain probability.
  const random_incorrect = sample(
    indexes.map(i => [i, 12]).concat(indexes.slice(0, -1).map(i => [12, i]))
  );

  const combinations = sided_combinations
    .concat(
      sided_combinations.slice().reverse() // Using .slice() to make a copy
    )
    .concat([random_incorrect]);

  const [newCurr, newGlyph] = sample(combinations);
  return isBefore ? [newGlyph, newCurr, null] : [null, newCurr, newGlyph];
};

export const kastchen_kreuze_1 = {
  imageset: 'box-with-crosshairs',
  glyphs: kastchen_kreuze_glyphs,
  check: (_before, curr, after) => kastchen_kreuze_check(curr, after, false),
  correct: (_before, curr, after) => kastchen_kreuze_correct(curr, after, false)
};

export const kastchen_kreuze_2 = {
  imageset: 'box-with-crosshairs',
  glyphs: kastchen_kreuze_glyphs,
  check: (before, curr, _after) => kastchen_kreuze_check(curr, before, true),
  correct: (before, curr, _after) => kastchen_kreuze_correct(curr, before, true)
};

export const kasten_linien_1 = {
  imageset: 'box-with-line',
  glyphs: [
    { code: 'n', value: true },
    { code: 'e', value: false },
    { code: 's', value: true },
    { code: 'w', value: false }
  ],
  check: (_before, curr, after) => after && curr && curr.value === after.value,
  correct: (_before, curr, after) => {
    if (curr !== null) {
      return null;
    }

    if (after && after.value) {
      return [null, sample([0, 2]), null];
    }
    if (after && !after.value) {
      return [null, sample([1, 3]), null];
    }

    const combinations = [
      [1, 1],
      [3, 3],
      [1, 3],
      [3, 1],
      [0, 0],
      [2, 2],
      [0, 2],
      [2, 0]
    ];
    const [newCurr, newAfter] = sample(combinations);
    return [null, newCurr, newAfter];
  }
};

export const kasten_linien_2 = {
  imageset: 'box-with-line',
  glyphs: [
    { code: 'n', value: true },
    { code: 'e', value: false },
    { code: 's', value: true },
    { code: 'w', value: false }
  ],
  check: (before, curr, _after) =>
    before && curr && curr.value === before.value,
  correct: (before, curr, _after) => {
    if (curr !== null) {
      return null;
    }

    if (before && before.value) {
      return [null, sample([0, 2]), null];
    }
    if (before && !before.value) {
      return [null, sample([1, 3]), null];
    }

    const combinations = [
      [1, 1],
      [3, 3],
      [1, 3],
      [3, 1],
      [0, 0],
      [2, 2],
      [0, 2],
      [2, 0]
    ];
    const [newBefore, newCurr] = sample(combinations);
    return [newBefore, newCurr, null];
  }
};

export const kreise_4_augen_1 = {
  imageset: 'circle-dots-3x3',
  glyphs: [
    { code: '001000101', value: 3 },
    { code: '100000101', value: 3 },
    { code: '101000001', value: 3 },
    { code: '101000100', value: 3 },
    { code: '101000101', value: 4 },
    { code: '001010100', value: 3 },
    { code: '001010101', value: 4 },
    { code: '100010001', value: 3 },
    { code: '100010101', value: 4 },
    { code: '101010001', value: 4 },
    { code: '101010100', value: 4 },
    { code: '101010101', value: 5 },
    { code: '001001001', value: 3 },
    { code: '001001101', value: 4 },
    { code: '100100100', value: 3 },
    { code: '100100101', value: 4 },
    { code: '101001001', value: 4 },
    { code: '101100100', value: 4 }
  ],
  check: (_before, curr, _after) => curr && curr.value === 4,
  correct: (_before, curr, _after) => {
    if (curr !== null) {
      return null;
    }
    return [null, sample([4, 6, 8, 9, 10, 13, 15, 16, 17]), null];
  }
};

export const pq_1 = {
  imageset: 'letters',
  glyphs: [
    { code: 'p', value: 'p' },
    { code: 'q', value: 'q' }
  ],
  check: (_before, curr, after) =>
    after && curr && curr.value === 'p' && after.value === 'q',
  correct: (_before, curr, after) => {
    if (curr !== null || (after && after.value === 'p')) {
      return null;
    }

    return [null, 0, 1];
  }
};

export const pq_2 = {
  imageset: 'letters',
  glyphs: [
    { code: 'p', value: 'p' },
    { code: 'q', value: 'q' }
  ],
  check: (before, curr, _after) =>
    before && curr && curr.value === 'q' && before.value === 'p',
  correct: (before, curr, _after) => {
    if (curr !== null || (before && before.value === 'q')) {
      return null;
    }
    return [0, 1, null];
  }
};

export const pqbd_1 = {
  imageset: 'letters',
  glyphs: [
    { code: 'p', value: 'p' },
    { code: 'q', value: 'q' },
    { code: 'b', value: 'b' },
    { code: 'd', value: 'd' }
  ],
  check: (_before, curr, after) =>
    after &&
    curr &&
    ((curr.value === 'p' && after.value === 'q') ||
      (curr.value === 'b' && after.value === 'd')),
  correct: (_before, curr, after) => {
    if (
      curr !== null ||
      (after && after.value === 'p') ||
      (after && after.value === 'b')
    ) {
      return null;
    }
    if (after && after.value === 'q') {
      return [null, 0, 1];
    }
    if (after && after.value === 'd') {
      return [null, 2, 3];
    }
    return sample([
      [null, 0, 1],
      [null, 2, 3]
    ]);
  }
};

export const pqbd_2 = {
  imageset: 'letters',
  glyphs: [
    { code: 'p', value: 'p' },
    { code: 'q', value: 'q' },
    { code: 'b', value: 'b' },
    { code: 'd', value: 'd' }
  ],
  check: (before, curr, _after) =>
    before &&
    curr &&
    ((curr.value === 'q' && before.value === 'p') ||
      (curr.value === 'd' && before.value === 'b')),
  correct: (before, curr, _after) => {
    if (
      curr !== null ||
      (before && before.value === 'q') ||
      (before && before.value === 'd')
    ) {
      return null;
    }
    if (before && before.value === 'p') {
      return [0, 1, null];
    }
    if (before && before.value === 'b') {
      return [2, 3, null];
    }
    return sample([
      [0, 1, null],
      [2, 3, null]
    ]);
  }
};

export const qp_1 = {
  imageset: 'letters',
  glyphs: [
    { code: 'p', value: 'p' },
    { code: 'q', value: 'q' }
  ],
  check: (_before, curr, after) =>
    curr && after && curr.value === 'q' && after.value === 'p',
  correct: (_before, curr, after) => {
    if (curr !== null || (after && after.value === 'q')) {
      return null;
    }
    return [null, 1, 0];
  }
};

export const qp_2 = {
  imageset: 'letters',
  glyphs: [
    { code: 'p', value: 'p' },
    { code: 'q', value: 'q' }
  ],
  check: (before, curr, _after) =>
    before && curr && curr.value === 'p' && before.value === 'q',
  correct: (before, curr, _after) => {
    if (curr !== null || (before && before.value === 'p')) {
      return null;
    }
    return [1, 0, null];
  }
};

export const doppelter_nachfolger = {
  imageset: 'numbers',
  glyphs: [
    { code: '1', value: 1 },
    { code: '2', value: 2 },
    { code: '3', value: 3 },
    { code: '4', value: 4 },
    { code: '6', value: 6 },
    { code: '8', value: 8 }
  ],
  check: (_before, curr, after) =>
    curr && after && curr.value * 2 === after.value,
  correct: (_before, curr, after) => {
    if (curr !== null) {
      return null;
    }
    if (after && after.value) {
      switch (after.value) {
        case 2:
          return [null, 0, null];
        case 4:
          return [null, 1, null];
        case 6:
          return [null, 2, null];
        case 8:
          return [null, 3, null];
        default:
          return null;
      }
    }
    return sample([
      [null, 0, 1],
      [null, 1, 3],
      [null, 2, 4],
      [null, 3, 5]
    ]);
  }
};

export const summe_6_1 = {
  imageset: 'numbers',
  glyphs: [
    { code: '1', value: 1 },
    { code: '2', value: 2 },
    { code: '3', value: 3 },
    { code: '4', value: 4 },
    { code: '5', value: 5 },
    { code: '6', value: 6 }
  ],
  check: (_before, curr, after) =>
    curr && after && curr.value + after.value === 6,
  correct: (_before, curr, after) => {
    if (curr !== null) {
      return null;
    }

    if (after && after.value) {
      const currIndex = 6 - after.value - 1;
      if (currIndex >= 0 && currIndex < 6) {
        return [null, currIndex, null];
      }
      return null;
    }
    return sample([
      [null, 0, 4],
      [null, 1, 3],
      [null, 2, 2],
      [null, 3, 1],
      [null, 4, 0]
    ]);
  }
};

export const tfl_1 = {
  imageset: 'tfl',
  glyphs: [
    { code: 't', value: 't' },
    { code: 'f', value: 'f' },
    { code: 'l', value: 'l' }
  ],
  check: (before, curr, _after) =>
    before && curr && curr.value === 'f' && before.value === 't',
  correct: (before, curr, _after) => {
    if (
      curr !== null ||
      (before && before.value === 'f') ||
      (before && before.value === 'l')
    ) {
      return null;
    }
    return [0, 1, null];
  }
};

export const wuerfel_summe_5_1 = {
  imageset: 'dice',
  glyphs: [
    { code: '1', value: 1 },
    { code: '2', value: 2 },
    { code: '3', value: 3 },
    { code: '4', value: 4 }
  ],
  check: (_before, curr, after) =>
    curr && after && curr.value + after.value === 5,
  correct: (_before, curr, after) => {
    if (curr !== null) {
      return null;
    }

    if (after && after.value) {
      const currIndex = 5 - after.value - 1;
      if (currIndex >= 0 && currIndex < 5) {
        return [null, currIndex, null];
      }
      return null;
    }

    return sample([
      [null, 0, 3],
      [null, 1, 2],
      [null, 2, 1],
      [null, 3, 0]
    ]);
  }
};

export const yin_yang_1 = {
  imageset: 'yin-yang',
  glyphs: [
    { code: 'n', value: 0 },
    { code: 'e', value: 1 },
    { code: 's', value: 2 },
    { code: 'w', value: 3 }
  ],
  check: (_before, curr, after) =>
    after &&
    curr &&
    curr.value !== after.value &&
    (curr.value + after.value) % 2 === 0,
  correct: (_before, curr, after) => {
    if (curr !== null) {
      return null;
    }
    if (after && after.value) {
      switch (after.value) {
        case 0:
          return [null, 2, null];
        case 1:
          return [null, 3, null];
        case 2:
          return [null, 0, null];
        case 3:
          return [null, 1, null];
        default:
          return null;
      }
    }

    return sample([
      [null, 2, 0],
      [null, 3, 1],
      [null, 0, 2],
      [null, 1, 3]
    ]);
  }
};

export const zahlen_und_kreise_1 = {
  imageset: 'circle-number',
  glyphs: [
    { code: '11000', value: true },
    { code: '10100', value: true },
    { code: '10010', value: true },
    { code: '10001', value: true },
    { code: '10110', value: false },
    { code: '11001', value: false },
    { code: '11011', value: false },
    { code: '11111', value: false },
    { code: '20010', value: false },
    { code: '21000', value: false },
    { code: '21100', value: true },
    { code: '21001', value: true },
    { code: '20111', value: false },
    { code: '21101', value: false },
    { code: '21011', value: false },
    { code: '21111', value: false },
    { code: '31000', value: false },
    { code: '30010', value: false },
    { code: '31100', value: false },
    { code: '30011', value: false },
    { code: '30110', value: false },
    { code: '31001', value: false },
    { code: '31110', value: true },
    { code: '30111', value: true },
    { code: '31101', value: true },
    { code: '31011', value: true },
    { code: '31111', value: false },
    { code: '41000', value: false },
    { code: '40100', value: false },
    { code: '40010', value: false },
    { code: '40001', value: false },
    { code: '41100', value: false },
    { code: '40011', value: false },
    { code: '40110', value: false },
    { code: '41001', value: false },
    { code: '40111', value: false },
    { code: '41011', value: false },
    { code: '41111', value: true }
  ],
  check: (_before, curr, _after) => curr && curr.value,
  correct: (_before, curr, _after) => {
    if (curr !== null) {
      return null;
    }
    return [null, sample([0, 1, 2, 3, 10, 11, 22, 23, 24, 25, 37]), null];
  }
};
