import React from 'react';
import { string, bool } from 'prop-types';

import { infoPageShape } from '../../../shapes/entities';

import RichContentViewer from '../../../../../shared/components/rich/viewer';
import HighlightContainer from '../../highlight/container';

const InfoPageScreen = ({ source, highlightsId, showHighlighter }) => (
  <HighlightContainer
    id={highlightsId}
    key={highlightsId}
    showControls={showHighlighter}
  >
    <RichContentViewer content={source.attributes.content} />
  </HighlightContainer>
);

InfoPageScreen.propTypes = {
  highlightsId: string.isRequired,
  // The no-typos rule currently does not handle custom prop-type classes, see:
  // https://github.com/yannickcr/eslint-plugin-react/issues/1389
  source: infoPageShape.isRequired, // eslint-disable-line react/no-typos
  showHighlighter: bool
};

InfoPageScreen.defaultProps = {
  showHighlighter: false
};

export default InfoPageScreen;
