import classnames from 'classnames';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { CaretDown } from '@phosphor-icons/react';

export function setFocus(item) {
  if (item) {
    item.focus({ preventScroll: true });
  }
}

export const DropdownPosition = {
  right: 'c-dropdown--right',
  top: 'c-dropdown--top',
  left: 'c-dropdown--left'
};

const DropdownContext = createContext({
  id: 'dropdown',
  isOpen: false,
  openDropdown: () => {},
  closeDropdown: () => {},
  position: 'right'
});

export function DropdownButton({
  label,
  title,
  openDescription,
  closeDescription,
  icon
}) {
  const { openDropdown, closeDropdown, id, isOpen } = useContext(
    DropdownContext
  );
  const buttonRef = useRef(null);

  let onClick = closeDropdown;
  let description = closeDescription;

  if (!isOpen) {
    onClick = openDropdown;
    description = openDescription;
  }

  return (
    <button
      type="button"
      ref={buttonRef}
      onClick={onClick}
      aria-controls={id}
      aria-haspopup="true"
      aria-expanded={isOpen}
      title={title}
      className="o-button o-button--toolbar"
    >
      {icon}
      {label}
      <CaretDown className="o-icon o-icon--medium t-margin-auto--left" />
      <span className="o-visuallyhidden">{description}</span>
    </button>
  );
}

DropdownButton.propTypes = {
  label: PropTypes.string,
  openDescription: PropTypes.string,
  closeDescription: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.node
};

DropdownButton.defaultProps = {
  label: null,
  openDescription: 'Open dropdown',
  closeDescription: 'Close dropdown',
  title: null,
  icon: null
};

export function DropdownList({ children }) {
  const { isOpen, id, closeDropdown, position } = useContext(DropdownContext);
  const contentRef = useRef(null);
  const className = classnames('c-dropdown', DropdownPosition[position]);

  useEffect(() => {
    if (isOpen) setFocus(contentRef.current);
  }, [isOpen]);

  return (
    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */
    <div
      id={id}
      ref={contentRef}
      hidden={!isOpen}
      className={className}
      onClick={closeDropdown}
      tabIndex={-1}
      role="menu"
    >
      {children}
    </div>
  );
}

DropdownList.propTypes = {
  children: PropTypes.node
};

DropdownList.defaultProps = {
  children: null
};

export function DropdownWrapper({ position, children, id, className }) {
  const [isOpen, setIsOpen] = useState(false);
  const openDropdown = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeDropdown = useCallback(() => {
    setIsOpen(false);
  }, []);

  const dropdownRef = useRef(null);

  const closeDropdownOnOutsideClick = useCallback(
    event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target))
        closeDropdown();
    },
    [dropdownRef, closeDropdown]
  );

  const closeDropdownOnEscape = useCallback(
    event => {
      if (event.code === 'Escape') closeDropdown();
    },
    [closeDropdown]
  );

  useEffect(() => {
    document.addEventListener('mousedown', closeDropdownOnOutsideClick);
    document.addEventListener('keydown', closeDropdownOnEscape);
    return () => {
      document.removeEventListener('mousedown', closeDropdownOnOutsideClick);
      document.removeEventListener('keydown', closeDropdownOnEscape);
    };
  }, [closeDropdownOnOutsideClick, closeDropdownOnEscape]);

  const classNames = classnames(
    'c-dropdown-wrapper',
    position !== 'right' ? 'c-dropdown-wrapper--right' : null,
    className
  );

  return (
    <DropdownContext.Provider
      value={{
        id,
        isOpen,
        openDropdown,
        closeDropdown,
        position
      }}
    >
      <div ref={dropdownRef} className={classNames}>
        {children}
      </div>
    </DropdownContext.Provider>
  );
}

DropdownWrapper.propTypes = {
  id: PropTypes.string,
  position: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string
};

DropdownWrapper.defaultProps = {
  id: null,
  className: null,
  position: 'bottom',
  children: PropTypes.node
};

const Dropdown = {
  Wrapper: DropdownWrapper,
  List: DropdownList,
  Button: DropdownButton
};
export default Dropdown;
