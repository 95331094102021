import React from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown-now';

import { formatter } from '../run_player/components/countdown';

const renderer = ({ completed, days, hours, minutes, seconds }) => {
  if (completed) return <span>Bereit!</span>;
  return formatter({ days, hours, minutes, seconds });
};

renderer.propTypes = {
  completed: PropTypes.bool.isRequired,
  days: PropTypes.number.isRequired,
  hours: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired,
  seconds: PropTypes.number.isRequired
};

const RunCountdown = ({ date }) => (
  <Countdown date={date} renderer={renderer} />
);

RunCountdown.propTypes = {
  date: PropTypes.string.isRequired
};

export default RunCountdown;
