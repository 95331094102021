import { createAction } from 'redux-actions';

export const paused = createAction('sync:paused');

export const resume = createAction(
  'sync:resume',
  (options = { retry: true }) => options
);

export const sending = createAction(
  'sync:sending',
  (_, event) => ({ event }),
  run => ({ run })
);

export const sent = createAction(
  'sync:sent',
  (_, event) => ({ event }),
  run => ({ run })
);

export const fail = createAction(
  'sync:fail',
  (_, event) => ({ event }),
  run => ({ run })
);

export const enqueue = createAction(
  'sync:enqueue',
  (_, event) => ({ event }),
  run => ({ run })
);
