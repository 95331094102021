import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import humanizeDuration from 'humanize-duration';

import ManualEvaluationForm from './manual_evaluation_form';
import { locale } from '../../../../../../lib/internationalize';

function extractNumbers(string) {
  return string.replace(/[^0-9]/g, '');
}
class OfflinePlayfield extends Component {
  constructor(props) {
    super(props);

    this.state = {
      manualEvaluation: { hits: 0, errors: 0, misses: 0 }
    };

    this.handleManualEvaluationChange = this.handleManualEvaluationChange.bind(
      this
    );
  }

  handleManualEvaluationChange(name, value) {
    const { manualEvaluation } = this.state;
    const { onManualEvaluation } = this.props;
    const parsedValue = parseInt(extractNumbers(value), 10) || 0;
    const newEvaluation = { ...manualEvaluation, [name]: parsedValue };
    this.setState({ manualEvaluation: newEvaluation });
    onManualEvaluation(newEvaluation);
  }

  render() {
    const { runtime, runId, exerciseId } = this.props;
    const duration = humanizeDuration(runtime * 1000, { language: locale });
    const baseUrl = `/runs/${runId}/exercise/${exerciseId}/focus_test_pdf`;

    return (
      <>
        <div className="t-margin-large--bottom">
          <p>
            <FormattedMessage
              id="runs.focus.offline.intro"
              values={{ duration }}
            />
            {` `}
            <a
              className="qa-exercise-download"
              href={baseUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="runs.focus.offline.download_exercise" />
            </a>
            .
          </p>
          <p>
            <FormattedMessage
              id="runs.focus.offline.exercise_instructions"
              values={{ duration }}
            />
          </p>
          <p>
            <FormattedMessage id="runs.focus.offline.solution_instructions_1" />
            {` `}
            <a
              className="qa-solution-download"
              href={`${baseUrl}?show_solution=1`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="runs.focus.offline.download_solution" />
            </a>
            {` `}
            <FormattedMessage id="runs.focus.offline.solution_instructions_2" />
          </p>
          <p>
            <FormattedMessage id="runs.focus.offline.ready_1" />
            {` `}
            <strong>
              <FormattedMessage id="runs.focus.offline.correct" />
            </strong>
            ,{` `}
            <strong>
              <FormattedMessage id="runs.focus.offline.wrong" />
            </strong>
            {` `}
            <FormattedMessage id="runs.focus.offline.ready_2" />
            {` `}
            <strong>
              <FormattedMessage id="runs.focus.offline.missed" />
            </strong>
            {` `}
            <FormattedMessage id="runs.focus.offline.ready_3" />
          </p>
        </div>
        <ManualEvaluationForm onChange={this.handleManualEvaluationChange} />
      </>
    );
  }
}

OfflinePlayfield.propTypes = {
  runtime: PropTypes.number.isRequired,
  runId: PropTypes.string.isRequired,
  exerciseId: PropTypes.string.isRequired,
  onManualEvaluation: PropTypes.func.isRequired
};

export default OfflinePlayfield;
