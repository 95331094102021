import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { chapterShape, exerciseShape } from '../../../shapes/entities';

import ChapterSection from './chapter_section';
import Controls from '../../controls';
import ControlItem from '../../control_item';
import { isNotFocusTest } from './utils';

class SolutionsGridScreen extends Component {
  render() {
    const {
      chapters,
      onOpenResultsScreen,
      onExit,
      onOpenExerciseScreen,
      chapterExerciseMap
    } = this.props;

    let startNumber = 1;
    const chapterSections = chapters.map(chapter => {
      const exercises = chapterExerciseMap[chapter.id];
      if (exercises.length === 0) {
        return null;
      }

      const item = (
        <ChapterSection
          key={chapter.id}
          chapter={chapter}
          exercises={exercises}
          startNumber={startNumber}
          onOpenExerciseScreen={onOpenExerciseScreen}
        />
      );
      startNumber += exercises.filter(exercise => isNotFocusTest(exercise))
        .length;
      return item;
    });

    return (
      <article>
        <h1 className="o-h1 t-margin-small--bottom">
          <FormattedMessage id="runs.show.solutions_grid.title" />
        </h1>
        <FormattedMessage
          id="runs.show.solutions_grid.description"
          tagName="p"
        />
        <div>{chapterSections}</div>

        <Controls>
          <ControlItem>
            <button
              type="button"
              className="o-button o-button--inverted l-width--16-of-16@small-only t-margin--bottom@small-only qa-results-button"
              onClick={onOpenResultsScreen}
            >
              <FormattedMessage id="runs.show.solutions_grid.back_to_results" />
            </button>
          </ControlItem>
          <ControlItem>
            <button
              type="button"
              className="o-button o-button--test-color l-width--16-of-16@small-only t-margin--bottom@small-only qa-exit-button"
              onClick={onExit}
            >
              <FormattedMessage id="runs.show.to_startpage" />
            </button>
          </ControlItem>
        </Controls>
      </article>
    );
  }
}

SolutionsGridScreen.propTypes = {
  chapters: PropTypes.arrayOf(chapterShape).isRequired,
  chapterExerciseMap: PropTypes.objectOf(PropTypes.arrayOf(exerciseShape))
    .isRequired,
  onOpenResultsScreen: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
  onOpenExerciseScreen: PropTypes.func.isRequired
};

export default SolutionsGridScreen;
