import { buildApi, get } from 'redux-bees';

export const endpoints = {
  getRun: { method: get, path: '/runs/:id' },
  getRunSubTestStatistics: {
    method: get,
    path: '/runs/:id/sub_test_statistics'
  },
  getMedicalTestStatistics: {
    method: get,
    path: '/medical_tests/:id/sub_test_statistics?created_before=:createdBefore'
  },
  getRunStatistic: {
    method: get,
    path: '/runs/:id/run_statistic'
  },
  getRunStatisticsByTrail: {
    method: get,
    path: '/trails/:id/run_statistics'
  },
  getTrailStatistic: {
    method: get,
    path: '/trails/:id/trail_statistic'
  },
  getRaceHighscores: {
    method: get,
    path: '/races/:id/highscores?limit=:limit'
  }
};

const configureOptions = options => ({
  credentials: 'same-origin',
  ...options
});

export const config = {
  baseUrl: '/api/v1',
  configureOptions
};

export default buildApi(endpoints, config);
