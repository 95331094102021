import React from 'react';
import PropTypes from 'prop-types';
import CountdownNow from 'react-countdown-now';

export const formatter = ({ days, hours, minutes, seconds }) => {
  const daysAndHours = Number(hours) + days * 24;

  const time = [daysAndHours, minutes, seconds]
    .map(i => `0${i}`.slice(-2))
    .join(':')
    .replace(/^[0:]*/, '');

  return <span>{time}</span>;
};

formatter.propTypes = {
  days: PropTypes.number.isRequired,
  hours: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired,
  seconds: PropTypes.number.isRequired
};

const renderer = ({ completed, days, hours, minutes, seconds }) => {
  if (completed) return <span />;
  return formatter({ days, hours, minutes, seconds });
};

renderer.propTypes = {
  completed: PropTypes.bool.isRequired,
  days: PropTypes.number.isRequired,
  hours: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired,
  seconds: PropTypes.number.isRequired
};

const Countdown = ({ date }) => (
  <CountdownNow date={date} renderer={renderer} />
);

Countdown.propTypes = {
  date: PropTypes.number.isRequired
};

export default Countdown;
