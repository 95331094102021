import React from 'react';

import SVG from 'react-inlinesvg';
import checkSVG from '../../../../../assets/images/circle-check.svg';

const CorrectnessLabel = () => (
  <SVG
    src={checkSVG}
    alt=""
    className="o-icon t-margin-x-small--left"
    width="17"
    height="17"
    role="presentation"
  />
);

CorrectnessLabel.propTypes = {};

export default CorrectnessLabel;
