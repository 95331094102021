import {
  compressToUTF16 as compress,
  decompressFromUTF16 as decompress
} from 'lz-string';

import localStorage from '../../../../lib/localstorage';

const HIGHLIGHT_KEY = 'QUESTION_HIGHLIGHTS';
const EMPTY_DRAWING = '{"lines":[],"width":"100%","height":"100%"}';

function loadStorage() {
  const value = localStorage.getItem(HIGHLIGHT_KEY);
  if (!value) return [];
  return JSON.parse(decompress(value));
}

function seedCache(data) {
  const cache = {};
  data.forEach(({ id, highlights }) => {
    cache[id] = highlights;
  });
  return cache;
}

// list of { id, highlights }, sorted by time (old to new).
let STORAGE = loadStorage();

// working copy of highlights, for easier lookup as { [id]: highlights }
const CACHE = seedCache(STORAGE);
// ⚠️additionally, this CACHE includes _all_ highlights of the current session,
// independently of whether they could be persisted or not.

function persistStorage() {
  try {
    localStorage.setItem(HIGHLIGHT_KEY, compress(JSON.stringify(STORAGE)));
  } catch (error) {
    // In case the error was caused by size-limitations, slice away the oldest (first) element and retry.
    if (STORAGE.length > 1) {
      STORAGE = STORAGE.slice(1);
      persistStorage();
    }
  }
}

export function setHighlights(id, highlights) {
  if (CACHE[id] === highlights) return;

  CACHE[id] = highlights;
  STORAGE = STORAGE.filter(ea => ea.id !== id); // remove older entries
  if (highlights !== EMPTY_DRAWING) STORAGE.push({ id, highlights });

  persistStorage();
}

export function getHighlights(id) {
  return CACHE[id] || '';
}
