import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

class UserFeedback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formOpen: false,
      feedbackSubmitted: false,
      sending: false,
      message: ''
    };

    this.openUserFeedbackForm = this.openUserFeedbackForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  openUserFeedbackForm() {
    this.setState({ formOpen: true });
  }

  handleSubmit(event) {
    this.setState({ sending: true, message: '' });
    this.props.onSubmit({ message: this.state.message }).then(() => {
      this.setState({ formOpen: false, feedbackSubmitted: true });
    });
    event.preventDefault();
  }

  handleChange(event) {
    this.setState({ message: event.target.value });
  }

  render() {
    const { formOpen, message, feedbackSubmitted, sending } = this.state;

    const openFormButton = (
      <button
        type="button"
        onClick={this.openUserFeedbackForm}
        className="c-user-feedback--open-button o-button o-button--small"
        disabled={formOpen}
      >
        <FormattedMessage id="runs.show.userFeedback.open" />
      </button>
    );
    const feedbackForm = (
      <form onSubmit={this.handleSubmit}>
        <h2 className="o-h2">
          <FormattedMessage id="runs.show.userFeedback.headline" />
        </h2>
        <ul className="l-grid">
          <li className="c-user-feedback--input-element l-grid__item l-width--6-of-12@large">
            <textarea
              value={message}
              onChange={this.handleChange}
              rows={5}
              className="c-user-feedback--text-input qa-user-feedback--message"
            />
          </li>
          <li className="c-user-feedback--input-element l-grid__item l-width--6-of-12@large">
            <button
              type="submit"
              className="o-button o-button--small"
              disabled={sending || message.length === 0}
            >
              {sending ? (
                <FormattedMessage id="runs.show.userFeedback.sending" />
              ) : (
                <FormattedMessage id="runs.show.userFeedback.submit" />
              )}
            </button>
          </li>
        </ul>
      </form>
    );
    const submittedMessage = (
      <FormattedMessage id="runs.show.userFeedback.submitted" />
    );

    return (
      <div className="c-user-feedback t-padding">
        {!feedbackSubmitted && (formOpen ? feedbackForm : openFormButton)}
        {feedbackSubmitted && submittedMessage}
      </div>
    );
  }
}

UserFeedback.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default UserFeedback;
