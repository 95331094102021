export const defaultHeaders = {
  'Content-Type': 'application/vnd.api+json',
  Accept: 'application/vnd.api+json'
};

const DEFAULT_ATTEMPTS = 5;
const getRetryDelay = n => 500 * 3 ** (DEFAULT_ATTEMPTS - n); // 0.5 to 40.5s -> ~1 minute until user is notified

class SyncClient {
  constructor({ headers }) {
    this.queue = Promise.resolve();
    this.headers = headers;
  }

  push(run, event, fn = send => send()) {
    this.queue = this.queue.then(() => {
      const send = () => this.send(run, event);
      const item = fn(send, run, event);
      return item;
    });

    return this.queue;
  }

  send(run, event, remainingAttempts = DEFAULT_ATTEMPTS) {
    const method = 'POST';
    const url = `/api/v1/runs/${run.id}/events`;
    const headers = this.headers(defaultHeaders);
    const body = JSON.stringify(event);

    const options = { method, headers, body, credentials: 'same-origin' };

    return fetch(url, options)
      .then(response => {
        if (response.ok) return response;
        const error = new Error('SyncClient Error');
        error.response = response;
        throw error;
      })
      .catch(error => {
        if (remainingAttempts <= 1) throw error;
        return this.retry(run, event, remainingAttempts - 1);
      });
  }

  retry(run, event, remainingAttempts) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(this.send(run, event, remainingAttempts));
      }, getRetryDelay(remainingAttempts));
    });
  }
}

export default SyncClient;
