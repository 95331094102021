import React from 'react';

import { EditorContent, useEditor } from '@tiptap/react';

import PropTypes from 'prop-types';
import { extensions } from './tiptap';
import internationalize from '../../../lib/internationalize';

const TipTapViewer = ({ content }) => {
  const editor = useEditor({
    extensions,
    content,
    editable: false,
    editorProps: {
      attributes: {
        class: 'c-tiptap-content-viewer o-clearfix',
        'data-testid': 'tiptap-content-viewer'
      }
    }
  });

  return <EditorContent editor={editor} />;
};

TipTapViewer.propTypes = {
  content: PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types
};

const Viewer = ({ content }) => {
  return <TipTapViewer content={content} />;
};

Viewer.propTypes = {
  content: PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types
};

export default internationalize(Viewer);
