import PropTypes from 'prop-types';
import mapValues from 'lodash.mapvalues';

import answerMode from '../../../shared/shapes/answer_mode';
import { focusTestModeType } from './types';

const jsonApiResourceIdentifierShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
});

const jsonApiShape = (attributes = null, relationships = null) => {
  const fmt = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  };

  if (attributes !== null) {
    fmt.attributes = PropTypes.shape(attributes).isRequired;
  }

  if (relationships !== null) {
    const rels = mapValues(
      relationships,
      relShape => PropTypes.shape({ data: relShape }).isRequired
    );

    fmt.relationships = PropTypes.shape(rels).isRequired;
  }

  return PropTypes.shape(fmt);
};

export const runShape = jsonApiShape({
  state: PropTypes.string.isRequired,
  name: PropTypes.string,
  type: PropTypes.string.isRequired,
  skip_scoring_screen: PropTypes.bool.isRequired,
  always_show_solution: PropTypes.bool.isRequired,
  always_show_feedback: PropTypes.bool.isRequired,
  instant_solution: PropTypes.bool.isRequired,
  auto_expand_solution: PropTypes.bool.isRequired
});

export const runStatisticShape = jsonApiShape({
  user_score: PropTypes.number.isRequired,
  achievable_score: PropTypes.number.isRequired,
  ranking_score: PropTypes.number.isRequired,
  percent_rank: PropTypes.number.isRequired,
  standard_value: PropTypes.number.isRequired,
  mean_score_at_time_of_ranking: PropTypes.number.isRequired,
  runs_count_at_time_of_ranking: PropTypes.number.isRequired,
  highest_score_at_time_of_ranking: PropTypes.number.isRequired
});

export const trailShape = jsonApiShape({
  color: PropTypes.string.isRequired
});

export const trailStatisticShape = jsonApiShape({
  runs_count: PropTypes.number.isRequired,
  mean_score: PropTypes.number.isRequired,
  highest_score: PropTypes.number.isRequired
});

export const raceShape = jsonApiResourceIdentifierShape;

export const chapterShape = jsonApiShape({
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  icon_url: PropTypes.string
});

const questionAnsweredShape = PropTypes.arrayOf(PropTypes.string);

const focusTestAnsweredShape = PropTypes.shape({
  sequence: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  selection: PropTypes.object.isRequired,
  focusTestMode: focusTestModeType.isRequired
});

const offlineFocusTestAnsweredShape = PropTypes.shape({
  sequence: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  selection: PropTypes.object.isRequired
});

const offlineFocusTestCacheShape = PropTypes.shape({
  sequence: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  focusTestMode: focusTestModeType
});

export const exerciseShape = jsonApiShape({
  first_visited_at: PropTypes.string,
  answered: PropTypes.oneOfType([
    focusTestAnsweredShape,
    offlineFocusTestAnsweredShape,
    questionAnsweredShape
  ]).isRequired,
  achievable_score: PropTypes.number.isRequired,
  raw_user_score: PropTypes.number,
  user_score: PropTypes.number.isRequired,
  percent_rank: PropTypes.number,
  offline_focus_test_cache: offlineFocusTestCacheShape
});

export const infoPageShape = jsonApiShape({
  content: PropTypes.object.isRequired
});

export const focusTestSettingsShape = jsonApiShape({
  alphabet: PropTypes.string.isRequired,
  number_of_lines: PropTypes.number.isRequired,
  max_run_time_seconds: PropTypes.number.isRequired
});

export const offlineFocusTestSettingsShape = jsonApiShape({
  number_of_correct_symbols: PropTypes.number.isRequired
});

export const questionBlockShape = jsonApiShape({
  context: PropTypes.object
});

export const questionShape = jsonApiShape({
  content: PropTypes.object.isRequired,
  solution: PropTypes.object,
  hint: PropTypes.object,
  answer_mode: answerMode.isRequired,
  multiple_choice: PropTypes.bool.isRequired
});

export const answerShape = jsonApiShape({
  content: PropTypes.object.isRequired,
  correct: PropTypes.bool.isRequired,
  sort_order: PropTypes.number
});

export const sourceShape = PropTypes.oneOfType([
  infoPageShape,
  focusTestSettingsShape,
  offlineFocusTestSettingsShape,
  questionBlockShape,
  questionShape
]);

export const screenShape = jsonApiShape({
  subtype: PropTypes.string.isRequired
});

export const subTestShape = jsonApiShape({
  color: PropTypes.string.isRequired,
  icon_url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
});

export const subTestStatisticsShape = jsonApiShape(
  {
    user_score: PropTypes.number.isRequired,
    achievable_score: PropTypes.number.isRequired,
    ranking_score: PropTypes.number.isRequired
  },
  {
    sub_test: jsonApiResourceIdentifierShape
  }
);

export const spiderGraphShape = PropTypes.shape({
  data: PropTypes.arrayOf(subTestStatisticsShape).isRequired,
  included: PropTypes.arrayOf(subTestShape).isRequired
});

export const questionCountsShape = PropTypes.shape({
  totalQuestionsCount: PropTypes.number.isRequired,
  seenQuestionsCount: PropTypes.number.isRequired,
  correctQuestionsCount: PropTypes.number.isRequired,
  wrongQuestionsCount: PropTypes.number.isRequired
});

export const scoringResultsShape = PropTypes.shape({
  achievableScore: PropTypes.number.isRequired,
  userScore: PropTypes.number.isRequired,
  totalQuestionsCount: PropTypes.number.isRequired,
  seenQuestionsCount: PropTypes.number.isRequired,
  correctQuestionsCount: PropTypes.number.isRequired,
  subTestDifference: PropTypes.number.isRequired,
  firstAttempt: PropTypes.bool.isRequired
});

export const highscoreEntryShape = PropTypes.shape({
  runId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userScore: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired
});

export const raceHighscoresShape = PropTypes.shape({
  allTimeBest: PropTypes.arrayOf(highscoreEntryShape).isRequired
});
