import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import RichContentViewer from '../../../../../shared/components/rich/viewer';

class Solution extends Component {
  constructor(props) {
    super(props);

    this.state = { collapsed: true };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(({ collapsed }) => ({ collapsed: !collapsed }));
  }

  render() {
    const { content, collapsible } = this.props;
    const { collapsed } = this.state;

    const hideSolution = collapsible && collapsed;

    return (
      <div className="t-margin--top t-padding">
        {hideSolution && (
          <button
            type="button"
            className="o-button o-button--chapter-color o-button--small"
            aria-expanded="false"
            aria-controls="expander-solution"
            onClick={this.toggle}
          >
            <FormattedMessage id="runs.show.toggle_solution" />
          </button>
        )}
        <div id="expander-solution" hidden={hideSolution}>
          <h2 className="t-no-margin">
            <FormattedMessage id="runs.show.solution_header" />
          </h2>
          <div className="t-padding--top">
            <RichContentViewer content={content} />
          </div>
        </div>
      </div>
    );
  }
}

Solution.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired, // eslint-disable-line react/no-typos
  collapsible: PropTypes.bool
};

Solution.defaultProps = {
  collapsible: true
};

export default Solution;
