import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import camelCase from 'lodash.camelcase';

import { alphabetType } from '../../../shapes/types';
import * as icons from './board/icons';
import * as descriptionExamples from './description-examples';

/* eslint-disable react/no-array-index-key */
const render = alphabet => fragments => {
  const parts = fragments.flatMap(template =>
    template.match(/\[[^\]]*\]|[^[]+/g)
  );

  const elements = parts.map((part, index) => {
    const { length } = part;

    if (part[0] === '[' && part[length - 1] === ']') {
      const code = part.substring(1, length - 1);

      const src = [alphabet.imageset, code].join('/');
      const id = camelCase(src);

      return (
        <img
          key={index}
          src={icons[id]}
          className="o-icon o-icon--medium"
          alt=""
        />
      );
    }

    return <span key={index}>{part}</span>;
  });

  return elements;
};
/* eslint-enable react/no-array-index-key */

const Description = ({ alphabet, alphabetId }) => {
  const src = descriptionExamples[camelCase(alphabetId)];

  return (
    <>
      <h1 className="t-text-weight--bold">
        <FormattedMessage id={`runs.focus.title.${alphabetId}`} />
      </h1>
      <p>
        <FormattedMessage id={`runs.focus.description.${alphabetId}`}>
          {render(alphabet)}
        </FormattedMessage>
      </p>
      {src && (
        <div className="t-margin-small--bottom">
          <img
            src={descriptionExamples[camelCase(alphabetId)]}
            alt="the focus test alphabet example"
            className="o-icon--50"
          />
        </div>
      )}
    </>
  );
};

Description.propTypes = {
  alphabetId: PropTypes.string.isRequired,
  alphabet: alphabetType.isRequired // eslint-disable-line react/no-typos
};

export default Description;
