import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import ConnectedRoot from './containers/root';
import init from './store';

const Main = ({ id }) => {
  const store = init();

  return (
    <Provider store={store}>
      <ConnectedRoot runId={id} />
    </Provider>
  );
};

Main.propTypes = {
  id: PropTypes.number.isRequired
};

export default Main;
