import { createAction } from 'redux-actions';

export const open = createAction(
  'player:open-screen',
  (_, screen) => ({ screen }),
  run => ({ run })
);

export const answer = createAction(
  'player:answer-question',
  (_, exercise, data, score = null) => ({ exercise, data, score }),
  run => ({ run })
);

export const expireChapter = createAction(
  'player:expire-chapter',
  (_, chapter) => ({ chapter }),
  run => ({ run })
);

export const endChapter = createAction(
  'player:end-chapter',
  (_, chapter) => ({ chapter }),
  run => ({ run })
);

export const instantAnswer = createAction(
  'player:submit-instant-answer-for-question',
  (_, exercise) => ({ exercise }),
  run => ({ run })
);
