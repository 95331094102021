import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { chapterShape, exerciseShape } from '../../../shapes/entities';

import QuestionButton from './question_button';
import { correct } from '../../../containers/screens/exercise/utils';
import { isNotFocusTest } from './utils';

const sum = (a, b) => a + b;
const achievableChapterScore = exercises =>
  exercises
    .map(exercise => exercise.attributes.achievable_score)
    .reduce(sum, 0);
const userChapterScore = exercises => {
  const score = exercises
    .map(exercise => exercise.attributes.user_score)
    .reduce(sum, 0);

  return score < 0 ? 0 : score;
};

const ChapterSection = ({
  chapter,
  startNumber,
  exercises,
  onOpenExerciseScreen
}) => {
  const { name } = chapter.attributes;
  let index = 0;

  const questionButtons = exercises.map(exercise => {
    let exerciseNumber;

    // The focus test never has a numbering
    if (isNotFocusTest(exercise)) {
      exerciseNumber = startNumber + index;
      index += 1;
    }
    return (
      <li key={exercise.id}>
        <QuestionButton
          correct={correct(exercise)}
          exerciseNumber={exerciseNumber}
          onClick={() => onOpenExerciseScreen(exercise)}
        />
      </li>
    );
  });

  return (
    <section className="o-section">
      <header className="solutions-grid--header">
        <h2 className="o-h3 t-text-weight--bold t-margin-small--bottom">
          {name}
        </h2>
        <FormattedMessage
          id="runs.show.solutions_grid.x_of_y_points"
          values={{
            x: userChapterScore(exercises),
            y: achievableChapterScore(exercises)
          }}
        />
      </header>
      <ul className="solutions-grid--questions">{questionButtons}</ul>
    </section>
  );
};

ChapterSection.propTypes = {
  chapter: chapterShape.isRequired,
  exercises: PropTypes.arrayOf(exerciseShape).isRequired,
  startNumber: PropTypes.number.isRequired,
  onOpenExerciseScreen: PropTypes.func.isRequired
};

export default ChapterSection;
