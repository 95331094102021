/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const ManualEvaluationForm = ({ onChange }) => {
  const handleChange = event => {
    const {
      target: { name, value }
    } = event;
    onChange(name, value);
  };

  return (
    <form className="l-grid">
      <div className="l-grid__item l-width--4-of-12@medium">
        <ul className="o-list-reset">
          <li className="t-margin--bottom">
            <label>
              <span className="o-label">
                <FormattedMessage id="runs.focus.offline.correctly_selected_symbols" />
              </span>
              <input
                type="number"
                name="hits"
                required
                min={0}
                onChange={handleChange}
                className="o-input o-input--text"
              />
            </label>
          </li>
          <li className="t-margin--bottom">
            <label>
              <span className="o-label">
                <FormattedMessage id="runs.focus.offline.incorrectly_selected_symbols" />
              </span>
              <input
                type="number"
                name="errors"
                required
                min={0}
                onChange={handleChange}
                className="o-input o-input--text"
              />
            </label>
          </li>
          <li className="t-margin--bottom">
            <label>
              <span className="o-label">
                <FormattedMessage id="runs.focus.offline.missed_symbols" />
              </span>
              <input
                type="number"
                name="misses"
                required
                min={0}
                onChange={handleChange}
                className="o-input o-input--text"
              />
            </label>
          </li>
        </ul>
      </div>
    </form>
  );
};

ManualEvaluationForm.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default ManualEvaluationForm;
