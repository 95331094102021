import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import enhanceWithClickOutside from 'react-click-outside';

import courseShape from '../shapes/course';

import CourseList from './course_list';
import DropdownButton from './dropdown_button';

export class CoursesMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { isActive: false };
    this.toggle = this.toggle.bind(this);
  }

  handleClickOutside() {
    this.setState({ isActive: false });
  }

  toggle() {
    this.setState(({ isActive }) => ({ isActive: !isActive }));
  }

  render() {
    const { courses } = this.props;
    const numberOfCourses = courses.length;
    if (numberOfCourses === 0) {
      return <div />;
    }

    const activeCourse = courses.find(course => course.active);
    const inactiveCourses = courses.filter(course => !course.active);
    const hasInactiveCourses = inactiveCourses.length > 0;

    const { isActive } = this.state;
    const className = classNames('c-dropdown__panel c-nav__section', {
      'c-dropdown--active': isActive
    });

    return (
      <div className="c-dropdown c-dropdown--courses qa-courses-menu">
        <DropdownButton
          activeCourse={activeCourse}
          hasInactiveCourses={hasInactiveCourses}
          onClick={this.toggle}
          isActive={isActive}
        />
        {hasInactiveCourses && (
          <section
            className={className}
            aria-labelledby="current_courses_label"
          >
            <div className="c-dropdown__inner">
              <CourseList courses={courses} />
            </div>
          </section>
        )}
      </div>
    );
  }
}

CoursesMenu.propTypes = {
  courses: PropTypes.arrayOf(courseShape).isRequired // eslint-disable-line react/no-typos
};

export default enhanceWithClickOutside(CoursesMenu);
