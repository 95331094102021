import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { RadarChart } from 'echarts/charts';
import { TitleComponent } from 'echarts/components';
import normalize from 'json-api-normalizer';
import sortBy from 'lodash.sortby';
import values from 'lodash.values';
import find from 'lodash.find';

import spiderizeData from './spiderize_data';
import iconizeSvg from './iconize_svg';
import { defaultOption, formatter, radarDefaults } from './constants';

echarts.use([RadarChart, TitleComponent]);

const objectify = item => {
  const { color, opacity, type, statistics, lineWidth } = item;
  return { color, opacity, type, lineWidth, data: normalize(statistics) };
};

export const normalizeInput = input => input.map(objectify);

const extractSubTests = input =>
  values(
    input
      .map(item => sortBy(item.data.subTests, 'id'))
      .reduce((acc, item) => ({ ...acc, ...item }), {})
  );

class SpiderGraphic extends Component {
  constructor(props) {
    super(props);
    const normalizedInput = normalizeInput(props.input);

    this.state = {
      input: normalizedInput,
      subTests: extractSubTests(normalizedInput)
    };
  }

  componentDidMount() {
    this.state.subTests.forEach(subTest => {
      fetch(subTest.attributes.iconUrl)
        .then(response => {
          if (response.ok) return response.text();
          return '';
        })
        .then(svg => {
          const { subTests } = this.state;
          const updatedSubTest = find(subTests, { id: subTest.id });
          updatedSubTest.attributes.iconSVG = iconizeSvg(
            svg,
            subTest.attributes.color
          );
          this.setState({ subTests });
        });
    });
  }

  render() {
    const { axes, styles, data } = spiderizeData(
      this.state.input,
      this.state.subTests
    );
    const { radarOptionOverrides, chartStyle } = this.props;
    const name = { formatter, rich: styles };
    const radar = [
      {
        ...radarDefaults,
        axisName: name,
        indicator: axes,
        ...radarOptionOverrides
      }
    ];
    const series = [{ type: 'radar', data }];
    const chartOption = { ...defaultOption, radar, series };

    return (
      <ReactEchartsCore
        echarts={echarts}
        option={chartOption}
        style={chartStyle}
      />
    );
  }
}

SpiderGraphic.propTypes = {
  input: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      color: PropTypes.string,
      statistics: PropTypes.object,
      opacity: PropTypes.number,
      lineWidth: PropTypes.number
    })
  ).isRequired,
  radarOptionOverrides: PropTypes.shape({
    radius: PropTypes.string
  }),
  chartStyle: PropTypes.shape({
    height: PropTypes.string
  })
};

SpiderGraphic.defaultProps = {
  radarOptionOverrides: {},
  chartStyle: {}
};

export default SpiderGraphic;
