// The main goal of this module is to be able to mock the localStorage in tests. But it also guards against runtimes that have no localstorage.

function getStorage() {
  const replacementStorage = {
    getItem: () => null,
    setItem: () => {},
    removeItem: () => {}
  };
  try {
    return window.localStorage || replacementStorage;
  } catch (error) {
    return replacementStorage;
  }
}

export default getStorage();
