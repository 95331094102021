import React from 'react';
import { string, bool } from 'prop-types';

import { questionBlockShape } from '../../../shapes/entities';

import RichContentViewer from '../../../../../shared/components/rich/viewer';
import HighlightContainer from '../../highlight/container';

const QuestionBlockScreen = ({ highlightsId, source, showHighlighter }) => {
  if (source.attributes && source.attributes.context) {
    return (
      <HighlightContainer
        id={highlightsId}
        key={highlightsId}
        showControls={showHighlighter}
      >
        <RichContentViewer content={source.attributes.context} />
      </HighlightContainer>
    );
  }

  return null;
};

QuestionBlockScreen.propTypes = {
  highlightsId: string.isRequired,
  source: questionBlockShape,
  showHighlighter: bool
};

QuestionBlockScreen.defaultProps = {
  source: {},
  showHighlighter: false
};

export default QuestionBlockScreen;
