import React from 'react';
import PropTypes from 'prop-types';

import { questionShape, answerShape } from '../../../shapes/entities';
import { modeType } from '../../../shapes/types';
import { hasContent } from '../../../../../shared/components/rich/utils';
import answerModes from '../../../../../shared/constants/answer_modes';

import RichContentViewer from '../../../../../shared/components/rich/viewer';
import HighlightContainer from '../../highlight/container';
import AnswerList from './answer_list';
import AnswerTable from './answer_table';
import Hint from './hint';
import Solution from './solution';

import * as modes from '../../../modes';

function answerPresentation(
  answers,
  isReview,
  selected,
  source,
  onToggleAnswer
) {
  const { answer_mode: answerMode } = source.attributes;
  const props = {
    answers,
    mode: isReview ? modes.review : modes.playing,
    onToggleAnswer,
    question: source,
    selected
  };
  if (answerMode === answerModes.table.id) {
    return <AnswerTable {...props} />;
  }
  return <AnswerList {...props} />;
}

const QuestionScreen = ({
  mode,
  source,
  answers,
  selected,
  onToggleAnswer,
  alwaysShowSolution,
  showInstantSolution,
  autoExpandSolution,
  highlightsId,
  showHighlighter
}) => {
  const { content, hint, solution } = source.attributes;

  const showHint = hasContent(hint);
  const isEditing = mode === modes.editing;
  const isReview = mode === modes.review || showInstantSolution || isEditing;
  const showSolution = (isReview || alwaysShowSolution) && hasContent(solution);
  const isCollapsible = isEditing ? false : !autoExpandSolution;

  return (
    <div key={source.id}>
      <div className="t-padding--ends">
        <HighlightContainer
          id={highlightsId}
          key={highlightsId}
          showControls={showHighlighter}
        >
          <RichContentViewer content={content} />
        </HighlightContainer>
      </div>
      {showHint && <Hint content={hint} />}
      {answerPresentation(answers, isReview, selected, source, onToggleAnswer)}
      {showSolution && (
        <Solution content={solution} collapsible={isCollapsible} />
      )}
    </div>
  );
};

QuestionScreen.propTypes = {
  // The no-typos rule currently does not handle custom prop-type classes, see:
  // https://github.com/yannickcr/eslint-plugin-react/issues/1389
  source: questionShape.isRequired,
  answers: PropTypes.arrayOf(answerShape).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleAnswer: PropTypes.func.isRequired,
  mode: modeType.isRequired,
  alwaysShowSolution: PropTypes.bool,
  showInstantSolution: PropTypes.bool,
  autoExpandSolution: PropTypes.bool,
  highlightsId: PropTypes.string.isRequired,
  showHighlighter: PropTypes.bool
};

QuestionScreen.defaultProps = {
  alwaysShowSolution: false,
  showInstantSolution: false,
  autoExpandSolution: false,
  showHighlighter: false
};

export default QuestionScreen;
