import React from 'react';
import PropTypes from 'prop-types';

import courseShape from '../shapes/course';

import CourseLinkItem from './course_link';

const CourseList = ({ courses }) => {
  return (
    <ul className="o-list-reset c-course-list">
      {courses.map(course => (
        <li key={course.id}>
          <CourseLinkItem course={course} />
        </li>
      ))}
    </ul>
  );
};

CourseList.propTypes = {
  courses: PropTypes.arrayOf(courseShape).isRequired
};

export default CourseList;
