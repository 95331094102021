import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import humanizeDuration from 'humanize-duration';

import { locale } from '../../../../../lib/internationalize';

import smallScreenGraphic from '../../../../../../assets/images/small_screen_focus_test.png';
import bigScreenGraphic from '../../../../../../assets/images/big_screen_focus_test.png';
import offlineGraphic from '../../../../../../assets/images/offline_focus_test.png';

const Start = ({
  runtime,
  onStartSmallScreen,
  onStartBigScreen,
  onStartOffline
}) => {
  const duration = humanizeDuration(runtime * 1000, { language: locale });

  return (
    <div className="l-grid">
      <div className="l-grid__item l-width--8-of-12@medium">
        <h2>
          <FormattedMessage id="runs.focus.title.start" />
        </h2>
        <p>
          <FormattedMessage id="runs.focus.description.start" />
        </p>
      </div>
      <div className="l-grid__item">
        <ul className="l-grid l-flex-grid l-flex-grid--3-col@large t-padding--top">
          <li className="l-grid__item l-width--4-of-12@medium qa-focus-test--small-screen">
            <div className="l-flex-grid__intro">
              <h2>
                <FormattedMessage id="runs.focus.start_small_screen.title" />
              </h2>

              <p>
                <FormattedMessage id="runs.focus.start_small_screen.description" />
              </p>
              <p>
                <FormattedMessage id="runs.focus.hint" values={{ duration }} />
              </p>
            </div>
            <div className="l-flex-grid__end">
              <img
                src={smallScreenGraphic}
                alt="the focus test optimized for mobile phones"
              />
            </div>

            <div>
              <button
                className="o-button o-button--test-color l-width--16-of-16@small-only"
                type="button"
                onClick={onStartSmallScreen}
              >
                <FormattedMessage id="runs.focus.start_small_screen.submit" />
              </button>
            </div>
          </li>

          <li className="l-grid__item l-width--4-of-12@medium qa-focus-test--big-screen">
            <div className="l-flex-grid__intro">
              <h2>
                <FormattedMessage id="runs.focus.start_big_screen.title" />
              </h2>

              <p>
                <FormattedMessage id="runs.focus.start_big_screen.description" />
              </p>
              <p>
                <FormattedMessage id="runs.focus.hint" values={{ duration }} />
              </p>
            </div>
            <div className="l-flex-grid__end">
              <img
                src={bigScreenGraphic}
                alt="the focus test optimized desktops"
              />
            </div>

            <div>
              <button
                className="o-button o-button--test-color l-width--16-of-16@small-only"
                type="button"
                onClick={onStartBigScreen}
              >
                <FormattedMessage id="runs.focus.start_big_screen.submit" />
              </button>
            </div>
          </li>

          <li className="l-grid__item l-width--4-of-12@medium t-no-margin qa-focus-test--offline">
            <div className="l-flex-grid__intro">
              <h2>
                <FormattedMessage id="runs.focus.start_offline.title" />
              </h2>

              <p>
                <FormattedMessage id="runs.focus.start_offline.description" />
              </p>
              <p>
                <FormattedMessage
                  id="runs.focus.start_offline.hint"
                  values={{ duration }}
                />
              </p>
            </div>

            <div className="l-flex-grid__end">
              <img
                src={offlineGraphic}
                alt="the focus test ready for download, just as the real test"
              />
            </div>

            <div>
              <button
                className="o-button o-button--test-color l-width--16-of-16@small-only"
                type="button"
                onClick={onStartOffline}
              >
                <FormattedMessage id="runs.focus.start_offline.submit" />
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

Start.propTypes = {
  onStartSmallScreen: PropTypes.func.isRequired,
  onStartBigScreen: PropTypes.func.isRequired,
  onStartOffline: PropTypes.func.isRequired,
  runtime: PropTypes.number.isRequired
};

export default Start;
