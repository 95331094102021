import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ChapterItem from './item';

import { chapterShape, runShape } from '../../shapes/entities';
import { modeType } from '../../shapes/types';
import * as modes from '../../modes';

const ChapterList = ({
  chapters,
  currentChapterId,
  onOpenChapter,
  mode,
  run
}) => {
  const items = chapters.map(chapter => {
    const listItemClass = classNames(
      'c-chapter-list__item',
      `theme-chapter--${chapter.id}`
    );
    return (
      <li key={chapter.id} className={listItemClass}>
        <ChapterItem
          chapter={chapter}
          onClick={() => onOpenChapter(chapter, run)}
          isActive={chapter.id === currentChapterId}
          isDisabled={
            mode === modes.playing && run.attributes.type !== 'LessonTrail'
          }
        />
      </li>
    );
  });

  return (
    <nav aria-label="chapters" className="c-runplayer__nav">
      <ul className="o-list-reset c-chapter-list">{items}</ul>
    </nav>
  );
};

ChapterList.propTypes = {
  chapters: PropTypes.arrayOf(chapterShape).isRequired,
  currentChapterId: PropTypes.string, // eslint-disable-line react/require-default-props
  onOpenChapter: PropTypes.func.isRequired,
  mode: modeType.isRequired, // eslint-disable-line react/no-typos
  run: runShape.isRequired // eslint-disable-line react/no-typos
};

export default ChapterList;
