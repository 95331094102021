import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const RetryRunButton = ({ runId }) => (
  <div className="t-text-align--center">
    <a
      className="o-button o-button--inverted l-width--16-of-16@small-only t-margin--bottom@small-only qa-evaluation-link"
      href={`/runs/${runId}/retry`}
    >
      <FormattedMessage id="runs.show.to_retry_run" />
    </a>
  </div>
);

RetryRunButton.propTypes = {
  runId: PropTypes.string.isRequired
};

export default RetryRunButton;
