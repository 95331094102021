const YOUTUBEMATCH_URL = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
const VIMEOMATCH_URL = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/; // eslint-disable-line no-useless-escape

const YOUTUBE_PREFIX = 'https://www.youtube.com/embed/';
const VIMEO_PREFIX = 'https://player.vimeo.com/video/';

export function isYoutube(url) {
  return YOUTUBEMATCH_URL.test(url);
}

export function isVimeo(url) {
  return VIMEOMATCH_URL.test(url);
}

export function getYoutubeSrc(url) {
  const id = url && url.match(YOUTUBEMATCH_URL)[1];
  return {
    srcID: id,
    srcType: 'youtube',
    url
  };
}
export function getVimeoSrc(url) {
  const id = url.match(VIMEOMATCH_URL)[3];
  return {
    srcID: id,
    srcType: 'vimeo',
    url
  };
}

export const getSrc = ({ url }) => {
  if (isYoutube(url)) {
    const { srcID } = getYoutubeSrc(url);
    return `${YOUTUBE_PREFIX}${srcID}`;
  }
  if (isVimeo(url)) {
    const { srcID } = getVimeoSrc(url);
    return `${VIMEO_PREFIX}${srcID}`;
  }
  return url;
};
