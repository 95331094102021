import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import OfflineFocusTestScreen from '../../../components/screens/offline_focus_test';

import {
  runShape,
  exerciseShape,
  offlineFocusTestSettingsShape
} from '../../../shapes/entities';

import * as modes from '../../../modes';

import { answer } from '../../../actions/player';

export class OfflineFocusTestScreenWrapper extends Component {
  constructor(props) {
    super(props);

    this.update = this.update.bind(this);
  }

  update(sequence, selection, score) {
    const { run, mode, exercise, answer: update } = this.props;
    if (mode === modes.review) return;
    const choices = { sequence, selection };
    update(run, exercise, choices, score);
  }

  render() {
    const { mode, exercise, source, toggleBlockNext } = this.props;

    return (
      <OfflineFocusTestScreen
        key={source.id}
        mode={mode}
        exercise={exercise}
        source={source}
        onUpdate={this.update}
        toggleBlockNext={toggleBlockNext}
      />
    );
  }
}

OfflineFocusTestScreenWrapper.propTypes = {
  run: runShape.isRequired,
  exercise: exerciseShape.isRequired,
  source: offlineFocusTestSettingsShape.isRequired,
  answer: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  toggleBlockNext: PropTypes.func.isRequired
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  answer
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OfflineFocusTestScreenWrapper);
