import React from 'react';
import { func, string, bool } from 'prop-types';

function ColorButton({ code, onClick, label, selected }) {
  return (
    <button
      type="button"
      data-color={code}
      onClick={onClick}
      className="o-button o-button--color"
      aria-pressed={selected}
    >
      <span className="o-visuallyhidden">{label}</span>
      <svg
        className="o-icon o-icon--color-well o-button__icon"
        viewBox="0 0 30 30"
        xmlns="http://www.w3.org/2000/svg"
        width="42"
        height="42"
      >
        <circle cx="15" cy="15" r="13" fill={code} />
      </svg>
    </button>
  );
}

ColorButton.propTypes = {
  code: string.isRequired,
  label: string.isRequired,
  selected: bool,
  onClick: func.isRequired
};

ColorButton.defaultProps = {
  selected: false
};

export default ColorButton;
