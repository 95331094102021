import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import classNames from 'classnames';

import defaultIcon from '../../../../../../public/images/default_chapter_icon.svg';
import { chapterShape } from '../../shapes/entities';

const ChapterItem = ({ onClick, chapter, isActive, isDisabled }) => {
  const { name } = chapter.attributes;
  const iconUrl = chapter.attributes.icon_url || defaultIcon;

  const buttonClass = classNames(
    'o-button',
    'o-button--icon',
    'o-button--chapter-color',
    { 'is-highlighted': isActive },
    { 'not-highlighted': !isActive }
  );

  return (
    <button
      type="button"
      className={buttonClass}
      onClick={onClick}
      disabled={isDisabled}
    >
      <SVG
        src={iconUrl}
        alt=""
        className="o-icon o-icon--large"
        height="30"
        width="30"
        role="presentation"
      />
      <span className="o-visuallyhidden">{name}</span>
    </button>
  );
};

ChapterItem.propTypes = {
  chapter: chapterShape.isRequired, // eslint-disable-line react/no-typos
  isActive: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default ChapterItem;
