import React from 'react';
import PropTypes from 'prop-types';
import { getEntity } from 'redux-bees';

import { infoPageShape } from '../../application/run_player/shapes/entities';
import stateify from '../utils/stateify';

import InfoPageScreen from '../../application/run_player/components/screens/info_page';
import internationalize from '../../lib/internationalize';

const InfoPagePreview = ({ resource }) => {
  const state = stateify(resource);
  const { type, id } = resource.data;
  const source = getEntity(state, { type, id });
  return <InfoPageScreen highlightsId={`${type}-${id}`} source={source} />;
};

InfoPagePreview.propTypes = {
  resource: PropTypes.shape({
    data: infoPageShape.isRequired
  }).isRequired
};

export default internationalize(InfoPagePreview);
