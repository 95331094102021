import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TipTapEditorContext from '../tiptap_editor_context';

const EditorButton = ({
  label,
  fn,
  type,
  icon,
  attributes,
  hideLabel,
  variant
}) => {
  const { editor } = useContext(TipTapEditorContext);
  const buttonClassname = classNames('o-button o-button--toolbar', {
    [`o-button--toolbar-${variant}`]: variant
  });
  const labelClassname = classNames({ 'o-visuallyhidden': hideLabel });
  return (
    <button
      type="button"
      className={buttonClassname}
      onClick={() => {
        const fnReturn = fn(editor.chain().focus());
        if (fnReturn) fnReturn.run();
      }}
      aria-label={label}
      aria-pressed={editor && type && editor.isActive(type, attributes)}
      title={label}
    >
      {icon}
      <span className={labelClassname}>{label}</span>
    </button>
  );
};

EditorButton.propTypes = {
  fn: PropTypes.func.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  hideLabel: PropTypes.bool,
  attributes: PropTypes.shape({
    level: PropTypes.number,
    textAlign: PropTypes.string
  }),
  icon: PropTypes.node,
  variant: PropTypes.string
};

EditorButton.defaultProps = {
  label: null,
  hideLabel: false,
  icon: null,
  type: null,
  variant: null,
  attributes: {}
};

export default EditorButton;
