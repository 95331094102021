import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FocusTestScreen from '../../../components/screens/focus_test';

import {
  runShape,
  exerciseShape,
  focusTestSettingsShape
} from '../../../shapes/entities';

import * as modes from '../../../modes';

import { answer } from '../../../actions/player';

export class FocusTestScreenWrapper extends Component {
  constructor(props) {
    super(props);

    this.update = this.update.bind(this);
  }

  update(sequence, selection, score, focusTestMode, manualEvaluation) {
    const { run, mode, exercise, answer: update } = this.props;
    if (mode === modes.review) return;
    const choices = { sequence, selection, focusTestMode, manualEvaluation };
    update(run, exercise, choices, score);
  }

  render() {
    const {
      run,
      mode,
      exercise,
      source,
      toggleBlockNext,
      onScreenLeave
    } = this.props;

    return (
      <FocusTestScreen
        key={source.id}
        runId={run.id}
        mode={mode}
        exercise={exercise}
        source={source}
        onUpdate={this.update}
        toggleBlockNext={toggleBlockNext}
        onScreenLeave={onScreenLeave}
      />
    );
  }
}

FocusTestScreenWrapper.propTypes = {
  run: runShape.isRequired,
  exercise: exerciseShape.isRequired,
  source: focusTestSettingsShape.isRequired,
  answer: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  toggleBlockNext: PropTypes.func.isRequired,
  onScreenLeave: PropTypes.func.isRequired
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  answer
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FocusTestScreenWrapper);
