import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import internationalize from '../../lib/internationalize';
import RichContentViewer from '../../shared/components/rich/viewer';

class RichTextPreview extends Component {
  constructor(props) {
    super(props);

    this.state = { show: false };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(({ show }) => ({ show: !show }));
  }

  render() {
    const { content } = this.props;

    const classname = classnames(
      'rich-text-preview__content',
      this.state.show && 'is-visible'
    );

    return (
      <div className="rich-text-preview">
        <button
          type="button"
          className="o-link o-link--test-color t-text-weight--bold o-h5 t-no-margin"
          onClick={this.toggle}
          aria-expanded={this.state.show}
        >
          <FormattedMessage
            id={
              this.state.show
                ? 'rich_text_preview.read_less'
                : 'rich_text_preview.read_more'
            }
          />
        </button>
        <div className={classname}>
          <RichContentViewer content={content} />
        </div>
      </div>
    );
  }
}

RichTextPreview.propTypes = {
  content: PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types
};

export default internationalize(RichTextPreview);
