import { createSelector, createStructuredSelector } from 'reselect';
import { getEntity, getRelationship } from 'redux-bees';
import values from 'lodash.values';
import find from 'lodash.find';
import findLastIndex from 'lodash.findlastindex';

import * as modes from '../modes';
import * as screenTypes from '../screens';
import { augmentRunSubTestStats } from '../utils/sub_test_stats';

const runref = id => ({ type: 'runs', id });
const getAchievableScore = ({ attributes }) => attributes.achievable_score;
const getUserScore = ({ attributes }) => attributes.user_score;
const realSubTestStatistic = statistic => statistic && Number(statistic.id) > 0;
const sum = (a, b) => a + b;

export const getRun = (state, runId) => getEntity(state, runref(runId));

export const getTrail = (state, runId) => {
  const run = getRun(state, runId);
  if (!run) return undefined;

  const { id } = getRun(state, runId).relationships.trail.data;
  return getEntity(state, { type: 'trails', id });
};

export const getPlayerMode = (state, runId) => {
  const { attributes } = getRun(state, runId);
  return attributes.state === 'ongoing' ? modes.playing : modes.review;
};

export const makeGetPlayerScreens = runId =>
  createSelector(
    state => getRun(state, runId).relationships.screens.data,
    state => state.bees.entities.screens,
    (data, screens) => {
      const state = { bees: { entities: { screens } } };
      const run = { relationships: { screens: { data } } };
      return getRelationship(state, run, 'screens');
    }
  );

export const makeGetPlayerScreenIndex = runId =>
  createSelector(
    state => getRun(state, runId).relationships.screens.data,
    state => getRun(state, runId).relationships.current_screen.data,
    (all, current) => all.findIndex(s => s.id === current.id)
  );

export const makeGetRunChapters = runId =>
  createSelector(
    state => getRun(state, runId).relationships.chapters.data,
    state => state.bees.entities.chapters,
    (data, chapters) => {
      const state = { bees: { entities: { chapters } } };
      const run = { relationships: { chapters: { data } } };
      return getRelationship(state, run, 'chapters');
    }
  );

export const getCurrentChapterId = (state, runId) => {
  const run = getRun(state, runId);
  const currentScreen = getRelationship(state, run, 'current_screen');
  return currentScreen.relationships && currentScreen.relationships.chapter
    ? currentScreen.relationships.chapter.data.id
    : null;
};

export const getExerciseScreenProps = (state, { chapter, screens, index }) => {
  const screen = screens[index];

  const exercises = getRelationship(state, chapter, 'exercises');
  const exercise = getRelationship(state, screen, 'exercise');
  const source = getRelationship(state, screen, 'source');

  const nextScreen = screens[index + 1];
  const nextScreenChapter =
    nextScreen && nextScreen.attributes.subtype !== screenTypes.results
      ? getRelationship(state, nextScreen, 'chapter')
      : null;
  const nextScreenChapterEntryBarrier = nextScreenChapter
    ? getRelationship(state, nextScreenChapter, 'entry_barrier')
    : null;

  return {
    exercises,
    exercise,
    source,
    nextScreen,
    nextScreenChapter,
    nextScreenChapterEntryBarrier
  };
};

export const getNextButtonProps = (state, { nextScreen }) => {
  const nextScreenChapter =
    nextScreen && nextScreen.attributes.subtype !== screenTypes.results
      ? getRelationship(state, nextScreen, 'chapter')
      : null;
  const nextScreenChapterEntryBarrier = nextScreenChapter
    ? getRelationship(state, nextScreenChapter, 'entry_barrier')
    : null;

  return {
    nextScreenChapter,
    nextScreenChapterEntryBarrier
  };
};

export const getExpulsionScreenProps = (state, { chapter, screens }) => {
  const { id, type } = chapter;
  const matcher = { relationships: { chapter: { data: { id, type } } } };

  const endOfChapterIndex = findLastIndex(screens, matcher);

  const nextScreen = screens[endOfChapterIndex + 1];
  const nextScreenChapter =
    nextScreen && nextScreen.attributes.subtype !== screenTypes.results
      ? getRelationship(state, nextScreen, 'chapter')
      : null;
  const nextScreenChapterEntryBarrier = nextScreenChapter
    ? getRelationship(state, nextScreenChapter, 'entry_barrier')
    : null;

  return {
    nextScreen,
    nextScreenChapter,
    nextScreenChapterEntryBarrier
  };
};

export const makeGetQuestionScreenProps = () =>
  createStructuredSelector({
    answers: (state, { source }) => getRelationship(state, source, 'answers'),
    isMultipleChoice: (state, { source }) => source.attributes.multiple_choice
  });

export const makeGetPlayerProps = runId =>
  createStructuredSelector({
    run: state => getRun(state, runId),
    chapters: makeGetRunChapters(runId),
    currentChapterId: state => getCurrentChapterId(state, runId),
    screens: makeGetPlayerScreens(runId),
    index: makeGetPlayerScreenIndex(runId),
    mode: state => getPlayerMode(state, runId)
  });

export const createChapterExerciseMap = (state, chapters) => {
  const withPoints = ({ attributes }) => attributes.achievable_score > 0;
  return chapters.reduce(
    (acc, chapter) => ({
      ...acc,
      [chapter.id]: getRelationship(state, chapter, 'exercises').filter(
        withPoints
      )
    }),
    {}
  );
};

export const getSolutionsGridScreenProps = () => {
  return createStructuredSelector({
    chapterExerciseMap: (state, { chapters }) =>
      createChapterExerciseMap(state, chapters)
  });
};

export const getRunSubTestStatistics = state => {
  const {
    run_sub_test_statistics: runSubTestStatistics,
    sub_test_statistics: subTestStatistics,
    sub_tests: subTests
  } = state.bees.entities;

  if (!(runSubTestStatistics && subTestStatistics && subTests)) return null;

  return {
    data: augmentRunSubTestStats(
      values(runSubTestStatistics),
      values(subTestStatistics)
    ),
    included: values(subTests)
  };
};

export const getSubTestStatistics = state => {
  const {
    sub_test_statistics: subTestStatistics,
    sub_tests: subTests
  } = state.bees.entities;

  if (!(subTestStatistics && subTests)) return null;

  return {
    data: values(subTestStatistics),
    included: values(subTests)
  };
};

export const getRunStatistic = (state, run) => {
  const { run_statistics: runStatistics } = state.bees.entities;
  if (!runStatistics) return null;

  return find(
    values(runStatistics),
    runStatistic => runStatistic.attributes.run_id.toString() === run.id
  );
};

export const getRunStatisticsByTrail = state => {
  const { run_statistics: runStatistics } = state.bees.entities;
  if (!runStatistics) return null;

  // All runStatistics should be coming from the run's trail, so we don't need to filter.
  return values(runStatistics);
};

export const getTrailStatistic = (state, trail) => {
  const { trail_statistics: trailStatistics } = state.bees.entities;
  if (!trailStatistics) return null;

  return find(
    values(trailStatistics),
    trailStatistic => trailStatistic.attributes.trail_id.toString() === trail.id
  );
};

export const getLastExerciseVisitedAt = state => {
  const { exercises } = state.bees.entities;

  const getVisitedAt = exercise => exercise.attributes.first_visited_at;
  const maxValue = (a, b) => (a > b ? a : b);
  const noNulls = visitedAt => visitedAt !== null;

  return values(exercises)
    .map(getVisitedAt)
    .filter(noNulls)
    .reduce(maxValue, null);
};

export const getSubTestResultsDifference = state => {
  const {
    sub_test_statistics: subTestStatistics,
    run_sub_test_statistics: runSubTestStatistics
  } = state.bees.entities;

  const getSubTest = statistic => getRelationship(state, statistic, 'sub_test');

  const withSubTestStatistic = runSubTestStatistic => {
    const subTest = getSubTest(runSubTestStatistic);
    const subTestStatistic = values(subTestStatistics).find(
      statistic => getSubTest(statistic) === subTest
    );
    return { runSubTestStatistic, subTestStatistic };
  };

  const validPairs = ({ runSubTestStatistic, subTestStatistic }) => {
    if (!realSubTestStatistic(subTestStatistic)) return false;
    const {
      achievable_score: currentAchievableScore
    } = runSubTestStatistic.attributes;
    const {
      achievable_score: lastAchievableScore
    } = subTestStatistic.attributes;
    return currentAchievableScore > 0 && lastAchievableScore > 0;
  };

  const subTestScore = statistic => {
    const {
      achievable_score: achievableScore,
      user_score: userScore
    } = statistic.attributes;
    return (userScore / achievableScore) * 100;
  };

  const scores = ({ runSubTestStatistic, subTestStatistic }) => {
    const currentScore = subTestScore(runSubTestStatistic);
    const lastScore = subTestScore(subTestStatistic);
    return { currentScore, lastScore };
  };

  const sumIndividualScores = (a, b) => {
    return {
      currentScore: a.currentScore + b.currentScore,
      lastScore: a.lastScore + b.lastScore
    };
  };

  const statisticPairs = values(runSubTestStatistics)
    .map(withSubTestStatistic)
    .filter(validPairs);

  const { currentScore, lastScore } = statisticPairs
    .map(scores)
    .reduce(sumIndividualScores, { currentScore: 0, lastScore: 0 });
  const subTestDifference =
    currentScore === 0 && lastScore === 0
      ? 0
      : Math.round((currentScore / lastScore) * 100) - 100;

  const firstAttempt = statisticPairs.length === 0;

  return { subTestDifference, firstAttempt };
};

export const getScoringResults = state => {
  const {
    run_sub_test_statistics: runSubTestStatistics,
    exercises
  } = state.bees.entities;

  const questionTypes = [
    'questions',
    'focus_test_settings',
    'offline_focus_test_sections'
  ];

  const getQuestionExercise = ({ relationships: { source } }) =>
    questionTypes.includes(source.data.type);
  const getCorrectExercise = ({ attributes }) =>
    attributes.user_score >= attributes.achievable_score;
  const getSeenExercise = ({ attributes }) =>
    attributes.first_visited_at !== null;

  const achievableScore = values(runSubTestStatistics)
    .map(getAchievableScore)
    .reduce(sum, 0);
  const userScore = values(runSubTestStatistics)
    .map(getUserScore)
    .reduce(sum, 0);
  const totalQuestionsCount = values(exercises).filter(getQuestionExercise)
    .length;
  const seenQuestionsCount = values(exercises)
    .filter(getQuestionExercise)
    .filter(getSeenExercise).length;
  const correctQuestionsCount = values(exercises)
    .filter(getQuestionExercise)
    .filter(getCorrectExercise).length;
  const { subTestDifference, firstAttempt } = getSubTestResultsDifference(
    state
  );

  return {
    achievableScore,
    userScore,
    totalQuestionsCount,
    seenQuestionsCount,
    correctQuestionsCount,
    subTestDifference,
    firstAttempt
  };
};

export const getSyncPendingEvents = state => state.sync.pending;

export const getSyncPaused = state => state.sync.paused;

export const getMedicalTestId = (state, runId) => {
  const run = getRun(state, runId);
  const medicalTest = getRelationship(state, run, 'medical_test');
  return medicalTest.id;
};

export const getRace = (state, runId) => {
  const run = getRun(state, runId);
  return getRelationship(state, run, 'race');
};

const transformRaceHighscores = highscoresList =>
  highscoresList.map(entry => ({
    runId: entry.run_id.toString(),
    userId: entry.user_id.toString(),
    userScore: entry.user_score,
    duration: entry.duration
  }));

export const getRaceHighscores = (state, runId) => {
  const race = getRace(state, runId);
  if (!race) return null;

  const highscores = getEntity(state, { type: 'race_highscores', id: race.id });
  if (!highscores) return null;

  return {
    allTimeBest: transformRaceHighscores(highscores.attributes.all_time_best)
  };
};

export const makeGetResultsScreenProps = runId =>
  createSelector(
    state => getRelationship(state, getRun(state, runId), 'sub_test'),
    state => getRunStatistic(state, getRun(state, runId)),
    state => getRunStatisticsByTrail(state),
    state => getTrail(state, runId),
    state => getTrailStatistic(state, getTrail(state, runId)),
    getRunSubTestStatistics,
    getSubTestStatistics,
    getSyncPendingEvents,
    state => getMedicalTestId(state, runId),
    getLastExerciseVisitedAt,
    getScoringResults,
    state => getRace(state, runId),
    state => getRaceHighscores(state, runId),
    (
      subTest,
      runStatistic,
      runStatisticsByTrail,
      trail,
      trailStatistic,
      runSubTestStatistics,
      subTestStatistics,
      pendingEvents,
      medicalTestId,
      lastExerciseVisitedAt,
      scoringResults,
      race,
      raceHighscores
    ) => ({
      subTest,
      runStatistic,
      runStatisticsByTrail,
      trail,
      trailStatistic,
      runSubTestStatistics,
      subTestStatistics,
      pendingEvents,
      medicalTestId,
      statsLoaded:
        !!runStatistic &&
        !!runStatisticsByTrail &&
        !!trailStatistic &&
        !!runSubTestStatistics &&
        !!subTestStatistics,
      lastExerciseVisitedAt,
      scoringResults,
      race,
      raceHighscores
    })
  );

export const makeGetHighscoresScreenProps = runId =>
  createSelector(
    state => getRace(state, runId),
    state => getRaceHighscores(state, runId),
    (race, raceHighscores) => ({
      race,
      raceHighscores
    })
  );
