import { defaultMessages } from '../../default';

export const headerRowAndColumnAnswerTableLayout = 'header_row_and_column';
export const slimAnswerTableLayout = 'slim';

export const tableLayouts = [
  {
    id: headerRowAndColumnAnswerTableLayout,
    name: defaultMessages.tableLayoutsHeaderRowAndColumn
  },
  {
    id: slimAnswerTableLayout,
    name: defaultMessages.tableLayoutsSlim
  }
];
