import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { answerShape } from '../../../../shapes/entities';
import { modeType } from '../../../../shapes/types';
import { review } from '../../../../modes';

import RichContentViewer from '../../../../../../shared/components/rich/viewer';
import CorrectnessLabel from '../../../correctness_label';

const preventFocus = e => e.preventDefault();

class Item extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
  }

  reviewMode() {
    return this.props.mode === review;
  }

  toggle() {
    if (this.reviewMode()) return;

    this.props.onToggle(this.props.answer);
  }

  render() {
    const { showContent, answer, isSelected } = this.props;
    const showCorrectnessLabel = this.reviewMode() && answer.attributes.correct;
    const buttonClassName = classnames(
      'o-button o-button--answer o-button--answer-table qa-answer-table--answer',
      {
        'no-content': !showContent,
        'is-correct': showCorrectnessLabel,
        'is-incorrect': this.reviewMode() && !answer.attributes.correct
      }
    );

    // TODO: this should be merged with the answer-list item component, as they only differ visually
    return (
      <button
        type="button"
        className={buttonClassName}
        onClick={this.toggle}
        onMouseDown={preventFocus}
        onKeyDown={this.toggle}
        aria-pressed={isSelected}
      >
        {showContent && (
          <RichContentViewer content={answer.attributes.content} />
        )}{' '}
        {showCorrectnessLabel && <CorrectnessLabel />}
      </button>
    );
  }
}

Item.propTypes = {
  mode: modeType.isRequired, // eslint-disable-line react/no-typos
  answer: answerShape.isRequired, // eslint-disable-line react/no-typos
  showContent: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired
};

export default Item;
