const alphabet = [
  'QWRTZXHSSDKLPPPMNBVCL',
  'aouaoueiieeey',
  'btrplknxmqwrtss',
  'aeuiooy',
  'tbxcvzqwrttrssrrrplkmnhgg',
  '123456789'
];

/*
 * This little snippet maps an integer to a 6-character player name.
 *
 * Names are stable per ID.
 * Names are not unique but have a relatively good distribution.
 */
const playerName = idStr => {
  const id = parseInt(idStr, 10);

  return Array(6)
    .fill()
    .map((_, i) =>
      alphabet[i].substr(((id + 1) * (i + 1)) % alphabet[i].length, 1)
    )
    .join('');
};

export default playerName;
