export const blockStyleFn = block => {
  const textAlign = block.getData().get('text-align');
  if (textAlign) return `t-text-align--${textAlign}`;
  return 't-text-align--left';
};

export const customStyleMap = {
  SUBSCRIPT: {
    verticalAlign: 'sub',
    fontSize: '.83em',
    lineHeight: '0.5em'
  },
  SUPERSCRIPT: {
    verticalAlign: 'super',
    fontSize: '.83em',
    lineHeight: '0.5em'
  }
};

export const hasContent = rawContent => {
  if (!rawContent) {
    return false;
  }

  if (rawContent.type !== 'doc') {
    return false;
  }

  if (!rawContent.content) {
    return false;
  }

  if (rawContent.content.length === 0) {
    return false;
  }

  if (
    rawContent.content.length === 1 &&
    rawContent.content[0].type === 'paragraph' &&
    (rawContent.content[0].content === undefined ||
      rawContent.content[0].content.length === 0)
  ) {
    return false;
  }
  return true;
};

export const getSelectedText = editorState => {
  const selection = editorState.getSelection();

  if (!selection.isCollapsed()) {
    const contentState = editorState.getCurrentContent();
    const startBlockKey = selection.getStartKey();

    if (startBlockKey === selection.getEndKey()) {
      return contentState
        .getBlockForKey(startBlockKey)
        .getText()
        .slice(selection.getStartOffset(), selection.getEndOffset());
    }

    return null;
  }
  return null;
};
