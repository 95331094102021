import React, { useCallback, useContext } from 'react';
import { Link as LinkIcon, LinkBreak } from '@phosphor-icons/react';
import EditorButton from './editor_button';
import TipTapEditorContext from '../tiptap_editor_context';

export const InlineLink = () => {
  const { editor } = useContext(TipTapEditorContext);

  const setLinkCallback = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href;
    const url = window.prompt('Please insert link:', previousUrl); // eslint-disable-line no-alert

    if (url === '' || url === null)
      return editor
        .chain()
        .extendMarkRange('link')
        .unsetLink();

    return editor
      .chain()
      .extendMarkRange('link')
      .toggleLink({ href: url });
  }, [editor]);

  return (
    <div className="l-flex c-joined-ui">
      <EditorButton
        icon={<LinkIcon className="o-icon o-icon--large" />}
        label="Add Link"
        fn={setLinkCallback}
        type="link"
        hideLabel
      />
      <EditorButton
        icon={<LinkBreak className="o-icon o-icon--large" />}
        fn={e => e.unsetLink()}
        label="Remove Link"
        type="button"
        hideLabel
      />
    </div>
  );
};

export default InlineLink;
