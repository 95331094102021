import React from 'react';
import PropTypes from 'prop-types';
import values from 'lodash.values';

import * as alignments from './alignments';

const alignmentMap = {
  [alignments.split]:
    'l-flex l-flex--split l-flex--center l-flex--col@small-only',
  [alignments.end]: 'l-flex l-flex--end l-flex--col@small-only'
};

const Controls = props => {
  const alignment = alignmentMap[props.align];

  return (
    <div className="c-runplayer__controls">
      <ul className={`o-list-reset ${alignment}`}>{props.children}</ul>
    </div>
  );
};

Controls.defaultProps = {
  children: null,
  align: alignments.split
};

Controls.propTypes = {
  children: PropTypes.node,
  align: PropTypes.oneOf(values(alignments))
};

export default Controls;
