/* eslint-disable import/prefer-default-export */

import unionBy from 'lodash.unionby';

// Fills in holes in the run sub-test-statistics with placeholder data.
// NOTE: we also need to update the 'type' to be 'run_sub_test_statistics'.
export const augmentRunSubTestStats = (runSubTestStats, subTestStats) => {
  const type = 'run_sub_test_statistics';
  const fillerStats = subTestStats.map(statistic => ({ ...statistic, type }));
  return unionBy(
    runSubTestStats,
    fillerStats,
    'relationships.sub_test.data.id'
  );
};
