import React from 'react';
import PropTypes from 'prop-types';

const QuestionButton = ({ correct, exerciseNumber, onClick }) => {
  const correctnessClass = correct ? 'is-correct' : 'is-incorrect';

  return (
    <button
      type="button"
      className={`o-button--square ${correctnessClass} qa-question-button`}
      onClick={onClick}
    >
      {exerciseNumber}
    </button>
  );
};

QuestionButton.propTypes = {
  correct: PropTypes.bool.isRequired,
  exerciseNumber: PropTypes.number,
  onClick: PropTypes.func.isRequired
};

QuestionButton.defaultProps = {
  exerciseNumber: null
};

export default QuestionButton;
