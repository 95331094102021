import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import pathsShape from '../shapes/paths';

const DashboardMenuItem = ({
  paths,
  highlightLink,
  showCompendium,
  showLectures,
  showTutorials
}) => {
  const classnameBase =
    'o-nav-link o-nav-link--menu o-truncate-with-ellipsis t-font-family--body t-no-margin c-dropdown__item';
  const classnameHighlighted =
    'o-nav-link o-nav-link--menu is-highlighted o-truncate-with-ellipsis t-font-family--body t-no-margin c-dropdown__item';

  return (
    <ul className="o-list-reset c-nav__section">
      <li>
        <a
          href={paths.eLearning}
          className={
            highlightLink === 'root' ? classnameHighlighted : classnameBase
          }
        >
          <FormattedMessage id="shared.user_account_menu.e_learning" />
        </a>
      </li>
      {showCompendium && (
        <li>
          <a
            href={paths.compendium}
            className={
              highlightLink === 'compendium'
                ? classnameHighlighted
                : classnameBase
            }
          >
            <FormattedMessage id="shared.user_account_menu.compendium" />
          </a>
        </li>
      )}
      {showLectures && (
        <li>
          <a
            href={paths.lectures}
            className={
              highlightLink === 'lecture' ? classnameHighlighted : classnameBase
            }
          >
            <FormattedMessage id="shared.user_account_menu.lectures" />
          </a>
        </li>
      )}
      {showTutorials && (
        <li>
          <a
            href={paths.tutorials}
            className={
              highlightLink === 'tutorial'
                ? classnameHighlighted
                : classnameBase
            }
          >
            <FormattedMessage id="shared.user_account_menu.tutorials" />
          </a>
        </li>
      )}
    </ul>
  );
};

DashboardMenuItem.propTypes = {
  showLectures: PropTypes.bool.isRequired,
  showTutorials: PropTypes.bool.isRequired,
  showCompendium: PropTypes.bool.isRequired,
  paths: pathsShape.isRequired,
  highlightLink: PropTypes.string.isRequired
};

export default DashboardMenuItem;
