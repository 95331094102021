import React from 'react';
import PropTypes from 'prop-types';
import {
  ArrowLineDown,
  ArrowLineUp,
  X,
  ArrowLineRight,
  ArrowLineLeft,
  Square,
  SquaresFour,
  SquareHalf,
  SquareHalfBottom,
  SquareLogo,
  Table as TableIcon
} from '@phosphor-icons/react';
import { Table as TiptapTable } from '@tiptap/extension-table';
import EditorButton from './editor_button';
import Dropdown from './drop_down';

export const tableMainControls = [
  {
    label: 'Add table',
    icon: <TableIcon className="o-icon o-icon--large" />,
    fn: editor => editor.insertTable({ rows: 3, cols: 3, withHeaderRow: true }),
    type: 'table'
  },
  {
    label: 'Delete table',
    icon: <X className="o-icon o-icon--large" />,
    fn: editor => editor.deleteTable()
  }
];
export const columnControls = [
  {
    label: 'Add before',
    icon: <ArrowLineLeft className="o-icon o-icon--large" />,
    fn: editor => editor.addColumnBefore()
  },
  {
    label: 'Add after',
    icon: <ArrowLineRight className="o-icon o-icon--large" />,
    fn: editor => editor.addColumnAfter()
  },
  {
    label: 'Delete',
    icon: <X className="o-icon o-icon--large" />,
    fn: editor => editor.deleteColumn()
  }
];
export const rowControls = [
  {
    label: 'Add before',
    icon: <ArrowLineUp className="o-icon o-icon--large" />,
    fn: editor => editor.addRowBefore()
  },
  {
    label: 'Add after',
    icon: <ArrowLineDown className="o-icon o-icon--large" />,
    fn: editor => editor.addRowAfter()
  },
  {
    label: 'Delete',
    icon: <X className="o-icon o-icon--large" />,
    fn: editor => editor.deleteRow()
  }
];
export const headerControls = [
  {
    label: 'Toggle column',
    icon: <SquareHalf className="o-icon o-icon--large" />,
    fn: editor => editor.toggleHeaderColumn()
  },
  {
    label: 'Toggle row',
    icon: <SquareHalfBottom className="o-icon o-icon--large" />,
    fn: editor => editor.toggleHeaderRow()
  }
];
export const borderControls = [
  {
    label: 'Toggle borders',
    icon: <SquareLogo className="o-icon o-icon--large" />,
    fn: editor => editor.toggleBorders(),
    hideLabel: true
  }
];
export const cellControls = [
  {
    label: 'Split cell',
    icon: <SquaresFour className="o-icon o-icon--large" />,
    fn: editor => editor.splitCell(),
    hideLabel: true
  },
  {
    label: 'Merge cells',
    icon: <Square className="o-icon o-icon--large" />,
    fn: editor => editor.mergeCells(),
    hideLabel: true
  }
];

export const TableControls = ({ id }) => {
  return (
    <>
      <ul className="l-flex c-joined-ui c-admin-toolbar__table-main">
        {tableMainControls.map(buttonProps => (
          <li key={buttonProps.label}>
            <EditorButton {...buttonProps} />
          </li>
        ))}
      </ul>
      <div className="c-admin-toolbar__table-extra l-flex t-gap-xs">
        <Dropdown.Wrapper id={`${id}-column-actions`}>
          <Dropdown.Button label="Columns…" />
          <Dropdown.List>
            {columnControls.map(buttonProps => (
              <li key={buttonProps.label}>
                <EditorButton {...buttonProps} variant="menu" />
              </li>
            ))}
          </Dropdown.List>
        </Dropdown.Wrapper>
        <Dropdown.Wrapper id={`${id}-row-actions`}>
          <Dropdown.Button label="Rows…" />
          <Dropdown.List>
            {rowControls.map(buttonProps => (
              <li key={buttonProps.label}>
                <EditorButton {...buttonProps} variant="menu" />
              </li>
            ))}
          </Dropdown.List>
        </Dropdown.Wrapper>
        <Dropdown.Wrapper id={`${id}-headers-actions`}>
          <Dropdown.Button label="Headers…" />
          <Dropdown.List>
            {headerControls.map(buttonProps => (
              <li key={buttonProps.label}>
                <EditorButton {...buttonProps} variant="menu" />
              </li>
            ))}
          </Dropdown.List>
        </Dropdown.Wrapper>
        <div className="l-flex t-gap-xs">
          <ul className="l-flex c-joined-ui">
            {cellControls.map(buttonProps => (
              <li key={buttonProps.label}>
                <EditorButton {...buttonProps} />
              </li>
            ))}
          </ul>
          {borderControls.map(buttonProps => (
            <EditorButton {...buttonProps} key={buttonProps.label} />
          ))}
        </div>
      </div>
    </>
  );
};

TableControls.propTypes = {
  id: PropTypes.string
};

TableControls.defaultProps = {
  id: null
};

const Table = TiptapTable.extend({
  addAttributes() {
    const parent = this.parent ? this.parent() : {};
    return {
      ...parent,
      class: null
    };
  },
  addCommands() {
    const toggleBordersCommand = () => ({ commands }) => {
      const attrs = this.editor.getAttributes('table');
      return commands.updateAttributes('table', {
        class: attrs.class ? '' : 'o-templated-table'
      });
    };

    return this.parent
      ? {
          ...this.parent(),
          toggleBorders: toggleBordersCommand
        }
      : {};
  }
});

export default Table;
