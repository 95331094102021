import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getEntity, getRequestError, isRequestLoading } from 'redux-bees';

import internationalize from '../../../../lib/internationalize';
import api from '../../api';

import { runShape } from '../../shapes/entities';

import LoadingScreen from '../../components/screens/loading';
import ErrorScreen from '../../components/screens/error';

import ConnectedPlayer from '../player';

export class Root extends Component {
  componentDidMount() {
    this.load();
  }

  load() {
    const { getRun, runId } = this.props;
    getRun({ id: runId });
  }

  render() {
    const { isLoading, error, run, runId } = this.props;

    if (isLoading) return <LoadingScreen />;
    if (error) return <ErrorScreen error={error} />;
    if (run) return <ConnectedPlayer runId={runId} />;

    return null;
  }
}

Root.propTypes = {
  error: PropTypes.string,
  getRun: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  runId: PropTypes.number.isRequired,
  run: runShape
};

Root.defaultProps = {
  error: '',
  run: null
};

export const IntlRoot = internationalize(Root);

const mapStateToProps = (state, ownProps) => {
  const getRunParams = [{ id: ownProps.runId }];

  const isLoading = isRequestLoading(state, api.getRun, getRunParams);
  const error = getRequestError(state, api.getRun, getRunParams) || null;
  const run = getEntity(state, { type: 'runs', id: ownProps.runId });

  return { error, isLoading, run };
};

const mapDispatchToProps = {
  getRun: api.getRun
};

export default connect(mapStateToProps, mapDispatchToProps)(IntlRoot);
