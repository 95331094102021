import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import classnames from 'classnames';

import courseShape from '../shapes/course';

import caret from '../../../../assets/images/caret.svg';
import defaultIcon from '../../../../../public/images/default_e_learning_icon.svg';

const DropdownButton = ({
  activeCourse,
  hasInactiveCourses,
  onClick,
  isActive
}) => {
  const name = activeCourse ? (
    activeCourse.displayName
  ) : (
    <FormattedMessage id="shared.user_account_menu.select_course" />
  );
  const iconUrl = activeCourse ? activeCourse.iconUrl : defaultIcon;
  const buttonClassname = classnames(
    'o-nav-link o-nav-link--topbar c-dropdown__button o-hidden@small-and-medium',
    { 'is-active': isActive }
  );

  return (
    <button
      type="button"
      onClick={onClick}
      className={buttonClassname}
      title={name}
    >
      <span className="o-button__label o-button__label--flex">
        <SVG
          src={iconUrl}
          alt=""
          className="o-icon o-button__icon l-flex__fixed t-margin-x-small--right"
          width="16"
          height="16"
          role="presentation"
        />
        <span className="o-truncate-with-ellipsis t-margin-x-small--right">
          {name}
        </span>
        {hasInactiveCourses && (
          <SVG
            src={caret}
            alt=""
            className="o-icon o-icon--small o-button__icon l-flex__fixed"
            width="16"
            height="16"
            role="presentation"
          />
        )}
      </span>
    </button>
  );
};

DropdownButton.defaultProps = {
  activeCourse: null
};

DropdownButton.propTypes = {
  activeCourse: courseShape,
  hasInactiveCourses: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired
};

export default DropdownButton;
