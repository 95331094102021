import React from 'react';
import { FormattedMessage } from 'react-intl';
import { runShape } from '../../../shapes/entities';

const EvaluationLink = ({ run }) => {
  return (
    <div className="l-flex-grid__end t-text-align--center t-padding--top">
      <a
        className="o-button o-button--inverted l-width--16-of-16@small-only t-margin--bottom@small-only qa-evaluation-link"
        href={`/runs/${run.id}/run_evaluation`}
      >
        <FormattedMessage id="runs.show.to_evaluation" />
      </a>
    </div>
  );
};

EvaluationLink.propTypes = {
  run: runShape.isRequired
};

EvaluationLink.defaultProps = {};

export default EvaluationLink;
