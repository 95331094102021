import sortBy from 'lodash.sortby';
import values from 'lodash.values';
import isNumber from 'lodash.isnumber';
import isString from 'lodash.isstring';

import {
  iconStyle,
  scale,
  opacity as defaultOpacity,
  color as defaultColor,
  lineColor as defaultLineColor,
  lineWidth as defaultLineWidth,
  itemStyle
} from './constants';

const graphConfig = ({ axes, styles }, subTest) => {
  const { name, color, iconSVG } = subTest.attributes;
  const styleId = subTest.id;
  const axis = { ...scale, text: name, styleId };
  const backgroundColor = iconSVG ? { image: iconSVG } : color;
  const style = { ...iconStyle, backgroundColor };
  return {
    axes: [...axes, axis],
    styles: { ...styles, [styleId]: style }
  };
};

const computeRelativeScore = statistic => {
  const { userScore, achievableScore } = statistic.attributes;
  return (userScore / achievableScore) * 100;
};

const applyDefaults = item => {
  const { color, opacity, lineWidth } = item;

  return {
    ...item,
    color: isString(color) ? color : defaultColor,
    lineColor: isString(color) ? color : defaultLineColor,
    opacity: isNumber(opacity) ? opacity : defaultOpacity,
    width: isNumber(lineWidth) ? lineWidth : defaultLineWidth
  };
};

const graphData = item => {
  const { color, opacity, width, lineColor, data, type } = applyDefaults(item);
  const statistics = sortBy(values(data[type]), stat =>
    parseInt(stat.relationships.subTest.data.id, 10)
  );
  const value = statistics.map(computeRelativeScore);
  const areaStyle = { opacity, color };
  const lineStyle = { width, color: lineColor };
  return { value, areaStyle, itemStyle, lineStyle };
};

const spiderizeData = (input, subTests) => {
  const config = { axes: [], styles: {} };
  const sortedSubTests = sortBy(subTests, st => parseInt(st.id, 10));

  const data = input.map(graphData);
  const { axes, styles } = sortedSubTests.reduce(graphConfig, config);

  return { axes, styles, data };
};

export default spiderizeData;
