import React, { useContext } from 'react';
import { BubbleMenu } from '@tiptap/react';
import TipTapEditorContext from '../tiptap_editor_context';
import { imageAlignments, imageSizes } from './image_constants';
import icons from './icons/alignment.svg';

const ImageMenu = () => {
  const { editor } = useContext(TipTapEditorContext);

  function setImgSize(size) {
    editor
      .chain()
      .focus()
      .setImgAttrs({
        size
      })
      .setNodeSelection(editor.view.state.selection.from)
      .run();
  }

  function setImgAlignment(align) {
    editor
      .chain()
      .focus()
      .setImgAttrs({
        align
      })
      .setNodeSelection(editor.view.state.selection.from)
      .run();
  }

  const isActive = property => editor && editor.isActive('image', property);

  return (
    <BubbleMenu
      className="c-dropdown c-dropdown--full-width"
      pluginKey="imageBubbleMenu"
      editor={editor}
      tippyOptions={{
        duration: 100,
        placement: 'auto'
      }}
      shouldShow={({ editor: editorRenamed }) => {
        // Only show the bubble menu for images
        return editorRenamed.isActive('image');
      }}
    >
      <>
        <ul className="l-flex c-joined-ui">
          {imageSizes.map(size => (
            <li key={size}>
              <button
                className="o-button o-button--toolbar"
                onClick={() => setImgSize(size)}
                type="button"
                aria-label={size}
                aria-pressed={isActive({ size })}
              >
                {size}
              </button>
            </li>
          ))}
        </ul>

        <ul className="l-flex c-joined-ui">
          {imageAlignments.map(alignment => (
            <li key={alignment}>
              <button
                className="o-button o-button--toolbar"
                onClick={() => setImgAlignment(alignment)}
                type="button"
                aria-label={alignment}
                aria-pressed={isActive({ align: alignment })}
              >
                <svg
                  className="o-icon o-icon--large"
                  role="presentation"
                  width="32"
                  height="32"
                  viewBox="0 0 100 100"
                >
                  <use xlinkHref={`${icons}#${alignment}`} />
                </svg>
                <span className="o-visuallyhidden">{alignment}</span>
              </button>
            </li>
          ))}
        </ul>
      </>
    </BubbleMenu>
  );
};

export default ImageMenu;
