import React from 'react';
import { string } from 'prop-types';
import CanvasDraw from '@win11react/react-canvas-draw';
import classnames from 'classnames';

import { brushType } from './prop_types';

const HighlightCanvas = React.forwardRef(({ color, brush, ...props }, ref) => {
  return (
    <div
      className={classnames('o-absolute-cover c-highlight__canvas', {
        'is-active': color
      })}
    >
      <CanvasDraw
        canvasWidth="100%"
        canvasHeight="100%"
        lazyRadius={brush.brushRadius / 2}
        brushRadius={brush.brushRadius}
        brushColor={color + brush.opacity || 'transparent'}
        catenaryColor="transparent"
        backgroundColor="transparent"
        hideGrid
        ref={ref}
        disabled={!color}
        {...props}
      />
    </div>
  );
});

HighlightCanvas.propTypes = {
  color: string.isRequired,
  brush: brushType.isRequired
};

export default HighlightCanvas;
