import isEmpty from 'lodash.isempty';

export const isQuestion = exercise =>
  exercise.relationships.source.data.type === 'questions';

export const visited = exercise => !!exercise.attributes.first_visited_at;

export const answered = exercise => !isEmpty(exercise.attributes.answered);

export const skipped = exercise =>
  visited(exercise) &&
  isEmpty(exercise.attributes.answered) &&
  isQuestion(exercise);

export const completed = exercise => visited(exercise) && answered(exercise);

export const correct = exercise => exercise.attributes.user_score > 0;
