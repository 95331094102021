import React from 'react';
import { TextB, TextItalic, TextUnderline } from '@phosphor-icons/react';

const textStyles = [
  {
    label: 'Bold',
    icon: <TextB className="o-icon o-icon--large" />,
    fn: editor => editor.toggleBold(),
    type: 'bold',
    hideLabel: true
  },
  {
    label: 'Italic',
    icon: <TextItalic className="o-icon o-icon--large" />,
    fn: editor => editor.toggleItalic(),
    type: 'italic',
    hideLabel: true
  },
  {
    label: 'Underline',
    icon: <TextUnderline className="o-icon o-icon--large" />,
    fn: editor => editor.toggleUnderline(),
    type: 'underline',
    hideLabel: true
  }
];
const verticalAlignment = [
  {
    label: 'SUP',
    fn: editor => editor.toggleSuperscript(),
    type: 'superscript'
  },
  {
    label: 'SUB',
    fn: editor => editor.toggleSubscript(),
    type: 'subscript'
  }
];

export { textStyles, verticalAlignment };
