import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const HintButton = ({ onClick }) => (
  <button
    type="button"
    className="o-button o-button--chapter-color o-button--small"
    onClick={onClick}
  >
    <FormattedMessage id="runs.show.show_hint" />
  </button>
);

HintButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default HintButton;
