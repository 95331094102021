import { handleActions } from 'redux-actions';
import { getRelationship } from 'redux-bees';
import update from 'immutability-helper';
import get from 'lodash.get';
import xor from 'lodash.xor';

import {
  open,
  answer,
  expireChapter,
  instantAnswer
} from '../../actions/player';
import * as screenTypes from '../../screens';

const now = () => new Date().toISOString();

const $attr = name => fn => ({ attributes: { [name]: fn } });

const $visit = entity =>
  update(
    entity,
    $attr('first_visited_at')(value => value || now())
  );

const evaluate = (state, exercise, data) => {
  const compat = { bees: state };

  const question = getRelationship(compat, exercise, 'source');
  const answers = getRelationship(compat, question, 'answers');

  const { achievable_score: achievableScore } = exercise.attributes;

  const solution = answers.filter(a => a.attributes.correct).map(a => a.id);
  const diff = xor(data, solution);

  return diff.length === 0 ? achievableScore : 0;
};

export default handleActions(
  {
    [open]: (state, { payload: { screen }, meta: { run } }) => {
      const { subtype } = screen.attributes;

      const chapter = get(screen, ['relationships', 'chapter', 'data']);
      const exercise = get(screen, ['relationships', 'exercise', 'data']);

      return update(state, {
        entities: {
          runs: {
            [run.id]: {
              attributes: {
                state:
                  subtype === screenTypes.results &&
                  run.attributes.state === 'ongoing'
                    ? { $set: 'finished' }
                    : {}
              },
              relationships: {
                current_screen: { data: { id: { $set: screen.id } } },
                current_exercise: { data: exercise ? { $set: exercise } : {} }
              }
            }
          },
          chapters: chapter ? { [chapter.id]: $visit } : {},
          exercises: exercise ? { [exercise.id]: $visit } : {}
        }
      });
    },
    [answer]: (state, { payload: { exercise, data, score } }) => {
      const result =
        typeof score === 'number' ? score : evaluate(state, exercise, data);

      return update(state, {
        entities: {
          exercises: {
            [exercise.id]: {
              attributes: {
                answered: { $set: data },
                user_score: { $set: result }
              }
            }
          }
        }
      });
    },
    [instantAnswer]: (state, { payload: { exercise } }) => {
      return update(state, {
        entities: {
          exercises: {
            [exercise.id]: {
              attributes: {
                instant_answer_submitted_at: { $set: now() }
              }
            }
          }
        }
      });
    },
    [expireChapter]: (state, { payload: { chapter } }) =>
      update(state, {
        entities: {
          chapters: {
            [chapter.id]: {
              attributes: {
                max_run_time_expired: { $set: true }
              }
            }
          }
        }
      })
  },
  {}
);
