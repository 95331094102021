import { getEntity, getRelationship } from 'redux-bees';
import update from 'immutability-helper';
import keyBy from 'lodash.keyby';
import get from 'lodash.get';

import * as screenTypes from '../../screens';

const reference = ({ type, id }) => ({ type, id });

const screenId = (runId, chapterIndex, screenOffset) =>
  [runId, chapterIndex, screenOffset].join(':');

const buildExerciseScreen = (run, index, offset, chapter, exercise) => ({
  type: 'screens',
  id: screenId(run.id, index, offset),
  attributes: {
    subtype: screenTypes.exercises
  },
  relationships: {
    chapter: { data: reference(chapter) },
    exercise: { data: reference(exercise) },
    source: exercise.relationships.source
  }
});

const buildResultsScreen = (run, index, offset) => ({
  type: 'screens',
  id: screenId(run.id, index, offset),
  attributes: {
    subtype: screenTypes.results
  }
});

const buildSolutionsScreen = (run, index, offset) => ({
  type: 'screens',
  id: screenId(run.id, index, offset),
  attributes: {
    subtype: screenTypes.solutions
  }
});

const buildHighscoresScreen = (run, index, offset) => ({
  type: 'screens',
  id: screenId(run.id, index, offset),
  attributes: {
    subtype: screenTypes.highscores
  }
});

/*
 * Returns the initial screen index when loading a run.
 * It is the results screen on a finished/cancelled run that allows scoring,
 * otherwise it is the `current_exercise` which is the exercise the player last visited.
 */
const initialScreenIndex = (run, screens) => {
  if (
    run.attributes.state !== 'ongoing' &&
    !run.attributes.skip_scoring_screen
  ) {
    return screens.findIndex(screen => {
      return screen.attributes.subtype === screenTypes.results;
    });
  }

  const currentExerciseId = get(
    run,
    'relationships.current_exercise.data.id',
    0
  );
  // Defensive: This shouldn't happen, but leads to annoying blank pages in some tests.
  if (!currentExerciseId) return 0;

  return screens.findIndex(screen => {
    if (screen.attributes.subtype !== screenTypes.exercises) return false;
    return screen.relationships.exercise.data.id === currentExerciseId;
  });
};

export const screenify = (bees, runref) => {
  const state = { bees };

  const run = getEntity(state, runref);
  const chapters = getRelationship(state, run, 'chapters');

  const screens = chapters
    .map((chapter, index) => {
      const exercises = getRelationship(state, chapter, 'exercises');

      return exercises.map((exercise, offset) =>
        buildExerciseScreen(run, index, offset, chapter, exercise)
      );
    })
    .reduce((all, part) => all.concat(part), [])
    .concat([buildResultsScreen(run, chapters.length, 0)])
    .concat([buildSolutionsScreen(run, chapters.length + 1, 0)])
    .concat([buildHighscoresScreen(run, chapters.length + 2, 0)]);

  const index = initialScreenIndex(run, screens);

  return { screens, index };
};

export default (state, action) => {
  const { payload, meta } = action;
  if (!(meta && payload)) return state;

  const { api, type, name } = meta;
  if (!(api && type === 'response' && name === 'getRun')) return state;

  const runref = reference(payload.body.data);
  const { screens, index } = screenify(state, runref);

  return update(state, {
    entities: {
      runs: {
        [runref.id]: {
          relationships: {
            $merge: {
              current_screen: { data: reference(screens[index]) },
              screens: { data: screens.map(reference) }
            }
          }
        }
      },
      screens: {
        $set: keyBy(screens, s => s.id)
      }
    }
  });
};
