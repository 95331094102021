import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import internationalize from '../../lib/internationalize';

const TestProgressBar = ({ total, correct }) => {
  const noQuestions = total === 0;
  const progress = noQuestions ? 0 : correct / total;
  const radius = 38.7; // magic number from the SVG
  const circumference = 2 * Math.PI * radius;
  const dashoffset = circumference * (1 - progress);

  return (
    <div className="c-activity-ring t-text-align--center">
      <h2 className="c-activity-ring__summary o-absolute-center t-margin--bottom t-font-family--body">
        <span className="c-activity-ring__large t-text-weight--bold">
          {correct}
        </span>
        <small>
          <FormattedMessage id="runs.results.of" />
          <br />
          {total === 1 ? (
            <FormattedMessage id="runs.results.points.one" values={{ total }} />
          ) : (
            <FormattedMessage
              id="runs.results.points.many"
              values={{ total }}
            />
          )}
        </small>
      </h2>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="100"
        viewBox="0 0 100 100"
        className="c-activity-ring__ring"
      >
        <path
          className="c-activty-ring__background"
          d="M50,7.494A42.506,42.506,0,1,0,92.506,50,42.507,42.507,0,0,0,50,7.494Zm0,77.44A34.934,34.934,0,1,1,84.934,50,34.933,34.933,0,0,1,50,84.934Z"
        />
        <circle
          className="c-activty-ring__progress qa-progress"
          cx="50"
          cy="50"
          r={radius}
          strokeDashoffset={dashoffset}
          strokeDasharray={circumference}
        />
      </svg>
    </div>
  );
};

TestProgressBar.propTypes = {
  total: PropTypes.number.isRequired,
  correct: PropTypes.number.isRequired
};

export default internationalize(TestProgressBar);
