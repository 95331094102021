import React from 'react';
import { connect } from 'react-redux';
import { bool, func } from 'prop-types';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';

import { resume } from '../../actions/sync';
import { getSyncPaused, getSyncPendingEvents } from '../../selectors/player';
import alertSVG from '../../../../../assets/images/exclamation.svg';

export class ConnectionWarning extends React.Component {
  constructor(props) {
    super(props);

    this.state = { retrying: false };

    this.handleResume = this.handleResume.bind(this);
  }

  // It would be cleaner to wait on the resume-call promise to decide when to
  // allow re-retrying. Unfortunately the sync-client returns promises that
  // either succeed, or never resolve. So we listen to state changes instead.
  componentDidUpdate(prevProps) {
    if (
      this.state.retrying &&
      // success                  || failed again
      (this.props.allEventsSynced || (this.props.paused && !prevProps.paused))
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ retrying: false });
    }
  }

  handleResume() {
    this.setState({ retrying: true });
    this.props.onResume();
  }

  render() {
    const { paused } = this.props;
    const { retrying } = this.state;

    if (!paused && !retrying) {
      return null;
    }

    return (
      <div className="c-connection-warning__container">
        <div className="c-connection-warning t-text-align--center">
          <span className="t-color--warning">
            <SVG src={alertSVG} alt="" className="o-icon" role="presentation" />
          </span>
          <p className="t-margin-small--bottom">
            <FormattedMessage id="runs.show.connection_error.description" />
          </p>
          <button
            type="button"
            onClick={this.handleResume}
            className="o-button o-button--secondary o-button--small"
            disabled={retrying}
          >
            <FormattedMessage id="runs.show.connection_error.action" />
            {retrying && '…'}
          </button>
        </div>
      </div>
    );
  }
}
ConnectionWarning.propTypes = {
  paused: bool.isRequired,
  allEventsSynced: bool.isRequired,
  onResume: func.isRequired
};

const mapStateToProps = state => ({
  paused: getSyncPaused(state),
  allEventsSynced: getSyncPendingEvents(state) < 1
});

export default connect(mapStateToProps, { onResume: resume })(
  ConnectionWarning
);
