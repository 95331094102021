import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Row from './board/row';

import { alphabetType } from '../../../shapes/types';
import { checker, evaluator, formatEvaluation, noop } from './utils';

// Trying to be as close to the official ruleset as possible:
// http://www.tms-info.org/index.php?id=ergebnis_und_auswertung
const Score = ({
  alphabet,
  sequence,
  selection,
  userScore,
  manualEvaluation,
  isSelected
}) => {
  const check = checker(alphabet, sequence);

  let evaluation = evaluator(alphabet, sequence)(selection);
  if (manualEvaluation) {
    evaluation = formatEvaluation(
      evaluation.counts.total,
      manualEvaluation.hits,
      manualEvaluation.errors,
      manualEvaluation.misses
    );
  }
  const { counts } = evaluation;

  /* eslint-disable react/no-array-index-key */
  return (
    <>
      <ul>
        <li>
          <FormattedMessage
            id="runs.focus.scoring.correctly_selected_symbols"
            values={{ count: counts.hits }}
          />
        </li>
        <li>
          <FormattedMessage
            id="runs.focus.scoring.incorrectly_selected_symbols"
            values={{ count: counts.errors }}
          />
        </li>
        <li>
          <FormattedMessage
            id="runs.focus.scoring.missed_symbols"
            values={{ count: counts.misses }}
          />
        </li>
        <li>
          <FormattedMessage
            id="runs.focus.scoring.points"
            values={{ points: counts.points }}
          />
        </li>
        <li>
          <FormattedMessage
            id="runs.focus.scoring.score"
            values={{ score: userScore }}
          />
        </li>
      </ul>
      <p className="t-margin-large--bottom">
        <FormattedMessage id="runs.focus.scoring.points_explainer" />
      </p>
      <ol className="o-list-reset c-focus-test__grid">
        {sequence.map((row, y) => (
          <li key={y}>
            <Row
              index={y}
              large={false}
              glyphs={row.map(i => alphabet.glyphs[i])}
              isSelected={isSelected}
              alphabet={alphabet}
              check={check}
              onClick={noop}
              highlighted
              disabled
            />
          </li>
        ))}
      </ol>
    </>
  );
  /* eslint-enable react/no-array-index-key */
};

Score.propTypes = {
  alphabet: alphabetType.isRequired,
  sequence: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  userScore: PropTypes.number.isRequired,
  isSelected: PropTypes.func.isRequired,
  manualEvaluation: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  selection: PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types
};

Score.defaultProps = {
  manualEvaluation: null
};

export default Score;
