import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ControlItem = props => {
  const classname = classnames({
    [`t-order-${props.order}@small-only`]: props.order
  });
  return <li className={classname}>{props.children}</li>;
};

ControlItem.defaultProps = {
  children: null,
  order: null
};

ControlItem.propTypes = {
  children: PropTypes.node,
  order: PropTypes.number
};

export default ControlItem;
