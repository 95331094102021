import PropTypes from 'prop-types';

export default PropTypes.shape({
  activity: PropTypes.string.isRequired,
  profile: PropTypes.string.isRequired,
  performance: PropTypes.string.isRequired,
  signOut: PropTypes.string.isRequired,
  eLearning: PropTypes.string.isRequired,
  compendium: PropTypes.string.isRequired
});
