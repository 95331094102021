import React from 'react';
import { FormattedMessage } from 'react-intl';
import SVG from 'react-inlinesvg';

import { runShape } from '../../shapes/entities';
import internationalize from '../../../../lib/internationalize';
import { modeType } from '../../shapes/types';

import closeIcon from '../../../../../assets/images/close.svg';
import exitIcon from '../../../../../assets/images/exit.svg';

import * as modes from '../../modes';

const AbortRunButton = ({ run, mode }) => {
  const route = `/runs/${run.id}/return`;
  const currentIcon = mode === modes.review ? exitIcon : closeIcon;

  return (
    <div className="t-text-align--right">
      <a href={route} title="" className="o-button o-button--pale t-no-padding">
        <SVG
          src={currentIcon}
          alt=""
          className="o-icon o-icon--medium"
          height="30"
          width="30"
          role="presentation"
        />
        <span className="o-visuallyhidden">
          <FormattedMessage id="runs.show.abort" />
        </span>
      </a>
    </div>
  );
};

AbortRunButton.propTypes = {
  run: runShape.isRequired,
  mode: modeType.isRequired // eslint-disable-line·react/no-typos
};
export default internationalize(AbortRunButton);
