import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { runShape, screenShape, chapterShape } from '../../../shapes/entities';

import Controls from '../../../components/controls';
import ControlItem from '../../../components/control_item';
import ConnectedNextButton from '../../../components/next_button';

import pauseGus from '../../../../../../assets/images/pause-gus.svg';

import { getExpulsionScreenProps } from '../../../selectors/player';

import { open } from '../../../actions/player';
import {
  chapterAccessibleAt,
  setChapterAccessTimeout
} from '../../../utils/chapter_timer';
import * as alignments from '../../../components/controls/alignments';

const nextScreenHasBarrier = ({
  nextScreenChapter,
  nextScreenChapterEntryBarrier
}) =>
  nextScreenChapter &&
  chapterAccessibleAt(nextScreenChapter, nextScreenChapterEntryBarrier) >=
    Date.now();

export class ExpulsionScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      enableNext: !nextScreenHasBarrier(props)
    };

    this.setChapterAccessTimer = this.setChapterAccessTimer.bind(this);
    this.openNextScreen = this.openNextScreen.bind(this);
  }

  componentDidMount() {
    this.setChapterAccessTimer();
  }

  componentWillUnmount() {
    if (this.accessTimer) clearTimeout(this.accessTimer);
  }

  setChapterAccessTimer() {
    const { enableNext } = this.state;
    if (enableNext) return;
    const { nextScreenChapter, nextScreenChapterEntryBarrier } = this.props;
    this.accessTimer = setChapterAccessTimeout(
      nextScreenChapter,
      nextScreenChapterEntryBarrier,
      () => this.setState({ enableNext: true })
    );
  }

  openNextScreen() {
    const { run, nextScreen: screen, open: navigate } = this.props;
    if (screen) navigate(run, screen);
  }

  render() {
    const { run, chapter, nextScreen } = this.props;

    const { enableNext } = this.state;

    return (
      <article className="qa-expulsion-screen">
        <div className="t-text-align--center">
          <FormattedMessage id="runs.expulsion.title" tagName="h2" />
          <p>
            <img
              className="l-image-align l-image-align--center"
              src={pauseGus}
              alt="Pause Gus"
              width="400"
            />
          </p>
        </div>

        <Controls align={alignments.end}>
          <ControlItem>
            <ConnectedNextButton
              onClick={this.openNextScreen}
              disabled={!enableNext}
              run={run}
              nextScreen={nextScreen}
              chapter={chapter}
            />
          </ControlItem>
        </Controls>
      </article>
    );
  }
}

ExpulsionScreen.propTypes = {
  run: runShape.isRequired,
  nextScreen: screenShape.isRequired,
  chapter: chapterShape.isRequired,
  nextScreenChapter: chapterShape,
  nextScreenChapterEntryBarrier: chapterShape,
  open: PropTypes.func.isRequired
};

ExpulsionScreen.defaultProps = {
  nextScreenChapter: null,
  nextScreenChapterEntryBarrier: null
};

const mapStateToProps = getExpulsionScreenProps;

const mapDispatchToProps = {
  open
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpulsionScreen);
