import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import RetryRunButton from './retry_run_button';
import { runShape, runStatisticShape } from '../../../shapes/entities';

// NOTE: In more recent versions of react-intl, `FormattedNumber` passes on `signDisplay`.
// This option to the underlying `Intl.NumberFormat` API can be set to `exceptZero`, and
// we don't need any of the following.
const roundWithPrecision = (value, precision) => {
  const multiplier = 10 ** (precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

const safeStandardValue = value => roundWithPrecision(value, 1);

const RunRankingCard = ({ run, runStatistic, color }) => {
  const style = { color };
  const { allow_run_retry: allowRunRetry } = run.attributes;

  // To ensure that percent rank and standard value displayed are consistent,
  // we use the cached_trail_* attributes from the run statistic instead of the ones from
  // the trail itself because those might have changed. The cached attributes are the values
  // at the time when the percent rank and standard value were calculated.
  const usersCount = (
    <span style={style}>
      {runStatistic.attributes.runs_count_at_time_of_ranking}
      <sup>*</sup>
    </span>
  );
  const highestScore = (
    <span style={style}>
      {runStatistic.attributes.highest_score_at_time_of_ranking}
      <sup>*</sup>
    </span>
  );

  const meanScore = (
    <span style={style}>
      <FormattedNumber
        value={runStatistic.attributes.mean_score_at_time_of_ranking}
        maximumFractionDigits={1}
      />
      <sup>*</sup>
    </span>
  );

  const userScore = (
    <span style={style}>{runStatistic.attributes.user_score}</span>
  );

  const percentRank = (
    <span style={style}>
      <FormattedNumber
        value={runStatistic.attributes.percent_rank}
        maximumFractionDigits={1}
      />
      %<sup>*</sup>
    </span>
  );

  const standardValue = (
    <span style={style}>
      <FormattedNumber
        value={safeStandardValue(runStatistic.attributes.standard_value)}
        maximumFractionDigits={1}
      />
      <sup>*</sup>
    </span>
  );

  const trailType = (
    <FormattedMessage
      id={
        run.attributes.purpose === 'simulation'
          ? 'runs.results.run_ranking_card.simulation'
          : 'runs.results.run_ranking_card.practice'
      }
    />
  );

  return (
    <article className="l-grid__item c-results-card qa-run-ranking-card">
      <div className="l-flex-grid__intro l-flex-grid__intro-ranking">
        <header className="t-margin--bottom">
          <h2 className="o-h2 t-text-weight--bold">
            <FormattedMessage id="runs.results.run_ranking_card.header" />
          </h2>
        </header>
        <div className="t-padding--top">
          <p className="t-marging--botton">
            <FormattedMessage
              id="runs.results.run_ranking_card.run_statistics"
              values={{
                user_score: userScore,
                percent_rank: percentRank,
                standard_value: standardValue
              }}
            />
          </p>
          <p>
            <FormattedMessage
              id="runs.results.run_ranking_card.trail_statistics"
              values={{
                trail_type: trailType,
                users_count: usersCount,
                highest_score: highestScore,
                mean_score: meanScore
              }}
            />
          </p>
          <p className="t-font-size--75">
            <FormattedMessage id="runs.results.run_ranking_card.disclaimer" />
          </p>
        </div>
      </div>

      {allowRunRetry && (
        <div className="l-flex-grid__end t-text-align--center t-padding--top">
          <RetryRunButton runId={run.id} />
        </div>
      )}
    </article>
  );
};

RunRankingCard.propTypes = {
  run: runShape.isRequired,
  color: PropTypes.string.isRequired,
  runStatistic: runStatisticShape.isRequired
};

export default RunRankingCard;
