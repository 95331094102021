/* global ReactOnRails:false */

import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import * as bees from 'redux-bees';

import { chain } from './reducers/utils';
import playerReducer from './reducers/player';
import syncReducer from './reducers/sync';

import sync from './middlewares/sync';

import SyncClient from './sync';

const env = process.env.NODE_ENV;

export default () => {
  const reducer = combineReducers({
    bees: chain(bees.reducer, playerReducer),
    sync: syncReducer
  });

  const client = new SyncClient({ headers: ReactOnRails.authenticityHeaders });
  const middleware = [bees.middleware(), sync({ client })];

  /* eslint-disable no-underscore-dangle */
  const build =
    env === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;
  /* eslint-enable no-underscore-dangle */

  const enhancer = build(applyMiddleware(...middleware));

  return createStore(reducer, enhancer);
};
