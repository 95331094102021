import React from 'react';
import EditorButton from './editor_button';
import { textStyles, verticalAlignment } from './inline_types';

const InlineControls = () => {
  return (
    <>
      <ul className="l-flex c-joined-ui">
        {textStyles.map(buttonProps => (
          <li key={buttonProps.label}>
            <EditorButton {...buttonProps} />
          </li>
        ))}
      </ul>
      <ul className="l-flex c-joined-ui">
        {verticalAlignment.map(buttonProps => (
          <li key={buttonProps.label}>
            <EditorButton {...buttonProps} />
          </li>
        ))}
      </ul>
    </>
  );
};

export default InlineControls;
