import React from 'react';
import { IntlProvider } from 'react-intl';
import { translations } from '../translations';
import { defaultLocale } from '../default';

require('@formatjs/intl-pluralrules/polyfill');
require('@formatjs/intl-pluralrules/locale-data/de');
require('@formatjs/intl-pluralrules/locale-data/en');

const getDocumentLocale = () => document.documentElement.lang;
export const locale = getDocumentLocale() || defaultLocale;
const messages = translations[locale];

const internationalize = Component => props => (
  <IntlProvider locale={locale} key={locale} messages={messages}>
    <Component {...props} />
  </IntlProvider>
);

export default internationalize;
