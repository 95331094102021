import fineSVG from '../../../../../assets/images/brush_fine.svg';
import markerSVG from '../../../../../assets/images/brush_marker.svg';

export const COLORS = [
  { label: 'yellow', code: '#ffff7b' },
  { label: 'pink', code: '#ff00bb' },
  { label: 'green', code: '#0aff00' },
  { label: 'blue', code: '#00cdff' },
  { label: 'orange', code: '#fe8c2c' },
  { label: 'violet', code: '#8500ff' }
];

export const BRUSHES = {
  fine: { label: 'fine', opacity: 'FF', brushRadius: 2, icon: fineSVG },
  marker: { label: 'marker', opacity: '55', brushRadius: 12, icon: markerSVG }
};
