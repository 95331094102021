import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { answerShape, questionShape } from '../../../../shapes/entities';
import { modeType } from '../../../../shapes/types';

import Cell from './cell';

function times(length) {
  return Array.from({ length }, (_, i) => i);
}

const Row = ({
  answers,
  mode,
  selected,
  question,
  onToggleAnswer,
  rowIndex
}) => {
  const { table_column_count: tableColumnCount } = question.attributes;
  const columns = times(tableColumnCount + 1);
  const className = classnames({
    'c-answer-table__bordered': rowIndex === 1
  });
  const cellProps = {
    answers,
    mode,
    selected,
    onToggleAnswer,
    question,
    rowIndex
  };

  return (
    <tr className={className}>
      {columns.map(columnIndex => (
        <Cell key={columnIndex} columnIndex={columnIndex} {...cellProps} />
      ))}
    </tr>
  );
};

Row.propTypes = {
  answers: PropTypes.arrayOf(answerShape).isRequired,
  mode: modeType.isRequired, // eslint-disable-line react/no-typos
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  question: questionShape.isRequired,
  onToggleAnswer: PropTypes.func.isRequired,
  rowIndex: PropTypes.number.isRequired
};

export default Row;
